import React from 'react';
import { Routes, Route } from 'react-router-dom';

import Item from './Item';
import Formulario from './Formulario';
import Lista from './Lista';

import PermissaoRoute from 'components/ui/PermissaoRoute';

const Itens = () => {

  return (
    <Routes>
      <Route path={`:id`} element={<PermissaoRoute permissao="item.view_item"><Item/></PermissaoRoute>} />
      <Route path={`form`} element={<PermissaoRoute permissao="item.add_item"><Formulario/></PermissaoRoute>} />
      <Route path={`:id/form`} element={<PermissaoRoute permissao="item.change_item"><Formulario/></PermissaoRoute>} />
      <Route path={`anunciante/:idAnunciante/form`} element={<PermissaoRoute permissao="item.change_item"><Formulario/></PermissaoRoute>} />
      <Route index element={<PermissaoRoute permissao="item.view_item"><Lista/></PermissaoRoute>} />

    </Routes>
  );
};

export default Itens;
