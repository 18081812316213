import find from 'lodash/find';

import { useGetTipoPagamentoAnunciante, useGetTipoPagamentoClientes } from 'actions/enums';

export const useTipoPagamentoAnunciante = value => {
  const { data: tipos, status } = useGetTipoPagamentoAnunciante();
  if (status === 'loading') return '';
  return find(tipos.data, ['value', value]) || '';
};

export const useTipoPagamentoCliente = value => {
  const { data: tipos, status } = useGetTipoPagamentoClientes();
  if (status === 'loading') return '';
  return find(tipos.data, ['value', value]) || '';
};

