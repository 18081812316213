import React from 'react';
import {
  Link as RouterLink,
  useNavigate
} from 'react-router-dom';
import { Stack, Flex, Input, IconButton, Button, Select } from '@chakra-ui/react';
import get from 'lodash/get';
import { FaSearch, FaPlus, FaBriefcase } from 'react-icons/fa';
import { stringify } from 'qs';

import { useGetLavanderias } from 'actions/lavanderias';
import { useGetOptionsStatusLavanderia } from 'actions/options';
import {
  DisplayText,
  Paginator,
  Table,
  TableHead,
  TableBodyZebra,
  TableRow,
  TableHeader,
  TableData,
  StatusLavanderia,
  RadioButtonGroup
} from 'components/ui';
import { useDocumentTitle, useSearchParams, usePermissao } from 'customHooks';
import { formatDate } from 'utils/ui';

const initialFiltros = {
  status: '',
  page: 1
};

const TableRowLavanderia = ({ value }) => {
  const history = useNavigate();

  const { id, item_referencia, empresa_nome, data_inicio, data_fim, status } =
    value;

  return (
    <TableRow key={id} onClick={() => history(`${id}`)}>
      <TableData>{id}</TableData>
      <TableData>
        {item_referencia || <DisplayText>nulo</DisplayText>}
      </TableData>
      <TableData>{empresa_nome || <DisplayText>nulo</DisplayText>}</TableData>
      <TableData>
        {data_inicio ? (
          formatDate(data_inicio)
        ) : (
          <DisplayText>nulo</DisplayText>
        )}
      </TableData>
      <TableData>
        {data_fim ? formatDate(data_fim) : <DisplayText>nulo</DisplayText>}
      </TableData>
      <TableData>
        <StatusLavanderia value={status} />
      </TableData>
    </TableRow>
  );
};

const TableLavanderias = ({ filtros, onPaginate }) => {
  const { page } = filtros;
  const {
    status,
    data: lavanderias,
    error,
    hasNext
  } = useGetLavanderias(filtros);

  if (status === 'loading') return <DisplayText>Carregando...</DisplayText>;

  if (status === 'error') {
    return <DisplayText>{get(error, 'response.data.detail')}</DisplayText>;
  }

  const { results } = lavanderias.data;

  if (results.length === 0) {
    return <DisplayText>Nenhuma informação disponível</DisplayText>;
  }

  return (
    <Stack spacing={4}>
      <Table>
        <TableHead>
          <TableRow>
            <TableHeader>#</TableHeader>
            <TableHeader>Item</TableHeader>
            <TableHeader>Empresa</TableHeader>
            <TableHeader>Data de Ida</TableHeader>
            <TableHeader>Data de Volta</TableHeader>
            <TableHeader>Status</TableHeader>
          </TableRow>
        </TableHead>
        <TableBodyZebra>
          {lavanderias.data.results.map(lavanderia => (
            <TableRowLavanderia key={lavanderia.id} value={lavanderia} />
          ))}
        </TableBodyZebra>
      </Table>
      <Paginator page={page} onPaginate={onPaginate} hasNext={hasNext} />
    </Stack>
  );
};

const Filtros = ({ value, onChange, permission, ...rest }) => {
  const { data: statusLavanderia } = useGetOptionsStatusLavanderia();
  const [termoPesquisa, setTermoPesquisa] = React.useState(value.search);

  React.useEffect(() => {
    setTermoPesquisa(value.search || '');
  }, [value]);

  return (
    <Flex {...rest}>
      <RadioButtonGroup
        name="status"
        onChange={onch => (onChange({ ...onch(), page: 1 }))}
        value={value.status}
        options={(statusLavanderia && statusLavanderia.data) || []}
        display={{base: 'none', md: 'flex'}}
      />

      <Select
        data-testid="filtro-status"
        size="sm"
        width="150px"
        value={value.status}
        onChange={e => (onChange({ status: e.target.value, page: 1 }))}
        display={{ base: 'inline-block', md: 'none' }}
      >
        {((statusLavanderia && statusLavanderia.data) || []).map(status => (<option key={status.value} value={status.value}>{status.label}</option>))}
      </Select>

      {permission && <Button
        as={RouterLink}
        to={`empresa`}
        ml={2}
        my={0}
        h="34px"
        fontWeight="normal"
        borderWidth="1px"
        bg="gray.100"
        px={3}
        py={1}
        display={{base: 'none', md: 'flex'}}
        leftIcon={<FaBriefcase />}
      >Empresas</Button>}
      <Flex
        as="form"
        ml="auto"
        noValidate
        onSubmit={e => {
          e.preventDefault();
          onChange({ page: 1, search: termoPesquisa });
        }}
      >
        <Input
          size="sm"
          placeholder="Pesquisar"
          roundedRight={0}
          value={termoPesquisa}
          onChange={e => setTermoPesquisa(e.target.value)}
        />
        <IconButton type="submit" size="sm" rounded={0} icon={<FaSearch />} />
        <IconButton
          as={RouterLink}
          to={`form`}
          size="sm"
          roundedLeft={0}
          icon={<FaPlus />}
        />
      </Flex>
    </Flex>
  );
};

const Lista = () => {
  const searchParams = useSearchParams(initialFiltros);
  useDocumentTitle('Lavanderia');
  const {isLoading, value} = usePermissao('lavanderia.change_empresalavanderia');
  const [filtros, setFiltrosState] = React.useState(searchParams);
  const history = useNavigate();
  

  const setFiltros = React.useCallback(
    newFiltros => {
      const filtros = { ...searchParams, ...newFiltros };
      history(`?${stringify(filtros)}`, { replace: true });
    },
    [setFiltrosState, searchParams]
  );

  React.useEffect(() => {
    setFiltrosState(searchParams);
  }, [searchParams]);

  if (isLoading) return <DisplayText>Carregando...</DisplayText>;

  return (
    <Stack spacing={4} p={4}>
      <Filtros permission={value} value={filtros} onChange={setFiltros} />
      <TableLavanderias
        filtros={filtros}
        onPaginate={page => setFiltros({page })}
      />
    </Stack>
  );
};

export default Lista;
