import React from 'react';
import { Stack } from '@chakra-ui/react';

import { Control, ControlText, ControlNull } from 'components/ui';
import { formatDate } from 'utils/ui';

const ControlLarger = props => {
  return (
    <Control gridTemplateColumns={['1fr', '1fr', '200px 1fr']} {...props} />
  );
};

const ControlEndereco = ({ vendedora }) => {
  const { logradouro, bairro, cidade, uf, cep } = vendedora;

  const hasLocalizacao = bairro || cidade || uf;
  const hasEndereco = logradouro || hasLocalizacao || cep;

  if (!hasEndereco) return <ControlNull />;

  return (
    <Stack spacing={0}>
      {logradouro && <ControlText>{logradouro}</ControlText>}
      {hasLocalizacao && (
        <ControlText>{`${bairro || ''}${
          cidade ? `${bairro ? ', ' : ''}${cidade}` : ''
        }${uf ? `${bairro || cidade ? ' - ' : ''}${uf}` : ''}`}</ControlText>
      )}
      {cep && <ControlText>{cep}</ControlText>}
    </Stack>
  );
};

const TabContentSobre = ({ vendedora }) => {
  return (
    <Stack>
      <ControlLarger label="RG">
        <ControlText>{vendedora.rg}</ControlText>
      </ControlLarger>
      <ControlLarger label="CPF">
        <ControlText>{vendedora.cpf}</ControlText>
      </ControlLarger>
      <ControlLarger label="Endereço" alignItems="start">
        <ControlEndereco vendedora={vendedora} />
      </ControlLarger>
      <ControlLarger label="Data Nascimento">
        {vendedora.data_nascimento ? (
          <ControlText>{formatDate(vendedora.data_nascimento)}</ControlText>
        ) : (
          <ControlNull />
        )}
      </ControlLarger>
    </Stack>
  );
};

export default TabContentSobre;
