import React from "react";
import { useRadio, useRadioGroup, Box, HStack } from "@chakra-ui/react";


  const ButtonRadio = 
  // eslint-disable-next-line no-unused-vars
  (props) => {
    const { getInputProps, getCheckboxProps } = useRadio(props);
    const checkbox = getCheckboxProps();
    const input = getInputProps();
    //console.log(props, checkbox, input)
    //console.log(props.onChange)
    
    return (
      <Box 
      as="label"
      zIndex={props.isChecked && 1}
      >
      <input {...input} />
      <Box
        {...checkbox}
        
        cursor="pointer"
        borderWidth="1px"
        bg="gray.100"
        //borderRadius="md"
        //boxShadow="md"
        mr="-1px"
        _hover={{
          bg:"gray.200"
        }}
        _active={{
          bg:"gray.300"
        }}
        _checked={{
          bg: "blue.600",
          color: "white",
          borderColor: "teal.600",
        }}
        borderLeftRadius={props.isFirst && "md"}
        borderRightRadius={props.isLast && "md"}
        _focus={{
          boxShadow: "outline",
        }}
        px={3}
        py={1}
        
        
      >{props.children}</Box>
      </Box>
    );
  }

const RadioButtonGroup = ({name, value, options, onChange, ...rest}) => {

  const { getRootProps, getRadioProps } = useRadioGroup({
    name, 
    value: value,
    onChange: value => onChange(prev => {
      const data = { ...prev};
      data[name] = value;
      return data
    }),
  })

  const group = getRootProps()

  return (<HStack {...group} spacing={0} mr="1px" {...rest}>
        {options && options.map((option, index) => {
          const radio = getRadioProps({ value: option.value })
          return (
            <ButtonRadio
              key={option.value}
              isFirst={index === 0}
              isLast={index === options.length - 1}
              {...radio}
              >
              {option.label}
            </ButtonRadio>
          )
        })}
      </HStack>);

}
  
export default RadioButtonGroup;