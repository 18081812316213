import { isValid, parseISO, format } from 'date-fns';

const currencyFormatter = new Intl.NumberFormat('pt-BR', {
  style: 'currency',
  currency: 'BRL'
});

const percentFormatter = new Intl.NumberFormat('pt-BR', {
  style: 'percent',
  minimumSignificantDigits: 2
});

export const formatCurrency = value => currencyFormatter.format(value);

export const formatDate = value => {
  const data = parseISO(value);
  return isValid(data) ? format(data, 'dd/MM/yyyy') : '';
}

export const formatPercent = value => percentFormatter.format(value);
