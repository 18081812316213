import React from 'react';
import { Routes, Route, useParams } from 'react-router-dom';
import {
  Flex,
  SimpleGrid,
  Stack,
  Heading,
  Divider,
  ButtonGroup,
  Button
} from '@chakra-ui/react';
import get from 'lodash/get';

import { useGetConfiguracoes } from 'actions/configuracoes';
import { Card, Control, ControlText, DisplayText } from 'components/ui';
import { useDocumentTitle } from 'customHooks';
import { formatPercent, formatCurrency } from 'utils/ui';

import {
  useDownloadRelatorio
} from 'actions/configuracoes';

import Formulario from './Formulario';
import AcoesButton from './AcoesButton';
import { FaDownload } from 'react-icons/fa';

const ControlLarger = props => (
  <Control gridTemplateColumns={['1fr', '1fr', '200px 1fr']} {...props} />
);

const CardConfiguracoes = ({ value }) => {
  return (
    <Card>
      <Flex justify="space-between" w="100%">
        <Stack isInline align="center">
          <Heading as="h3" size="lg">
            Configurações
          </Heading>
        </Stack>
        <AcoesButton />
      </Flex>
      <Divider w="100%" />
      <ControlLarger label="Percentual Reserva">
        <ControlText>{formatPercent(value.percentual_reserva)}</ControlText>
      </ControlLarger>
      <ControlLarger label="Percentual Anunciante">
        <ControlText>{formatPercent(value.percentual_anunciante)}</ControlText>
      </ControlLarger>
      <ControlLarger label="Percentual Vendedora">
        <ControlText>{formatPercent(value.percentual_vendedora)}</ControlText>
      </ControlLarger>
      <ControlLarger label="Lavagem Vestido Curto" mt={4}>
        <ControlText>{formatCurrency(value.lavagem_vc)}</ControlText>
      </ControlLarger>
      <ControlLarger label="Lavagem Vestido Longo">
        <ControlText>{formatCurrency(value.lavagem_vl)}</ControlText>
      </ControlLarger>
    </Card>
  );
};

const Index = () => {
  const { id } = useParams();
  const { data: lavanderia, status, error } = useGetConfiguracoes(id);
  useDocumentTitle('Admin');
  const [
    baixarRelatorio,
    {isFetching: statusrRelatorio}] = useDownloadRelatorio();

  if (status === 'loading') return <DisplayText>Carregando...</DisplayText>;

  if (status === 'error')
    return <DisplayText>{get(error, 'response.data.detail')}</DisplayText>;

  // TODO: Implementar ações de baixar fotos e dados

  

  return (
    <Stack p={4} spacing={6}>
      <SimpleGrid columns={[1, 1, 2, 2]} spacing={6}>
        <CardConfiguracoes value={lavanderia.data} />
      </SimpleGrid>
      <ButtonGroup display="flex" w="100%" size="sm" justifyContent="flex-end">
        {/*
        <Button
          // isLoading={statusIr === 'loading'}
          loadingText="Baixando fotos"
          leftIcon={<FaDownload/>}
          colorScheme="green"
          onClick={() => {}}
        >
          Baixar fotos
        </Button>
        <Button
          // isLoading={statusVoltar === 'loading'}
          loadingText="Baixando dados"
          leftIcon={<FaDownload/>}
          colorScheme="green"
          onClick={() => {}}
        >
          Baixar dados
        </Button>
        */}
        <Button
          isLoading={statusrRelatorio}
          loadingText="Baixando relatório"
          leftIcon={<FaDownload/>}
          colorScheme="green"
          onClick={() => {baixarRelatorio()}}
        >
          Baixar relatório
        </Button>
      </ButtonGroup>
    </Stack>
  );
};

const Configuracoes = () => {

  return (
    <Routes>
      <Route path={`form`} element={<Formulario/>} />
      <Route index element={<Index/>} />
    </Routes>
  );
};

export default Configuracoes;
