import React from 'react';
import { Routes, Route } from 'react-router-dom';

import PermissaoRoute from 'components/ui/PermissaoRoute';

import Cliente from './Cliente';
import Formulario from './Formulario';
import Lista from './Lista';

const Anunciantes = () => {

  return (
    <Routes>
      <Route path={`:id`} element={<PermissaoRoute permissao="cliente.view_cliente"><Cliente/></PermissaoRoute>} />
      <Route path={`form`} element={<PermissaoRoute permissao="cliente.add_cliente"><Formulario/></PermissaoRoute>} />
      <Route path={`:id/form`} element={<PermissaoRoute permissao="cliente.change_cliente"><Formulario/></PermissaoRoute>} />
      <Route index element={<PermissaoRoute permissao="cliente.view_cliente"><Lista/></PermissaoRoute>} />
    </Routes>
  );
};

export default Anunciantes;
