import { useQuery } from '@tanstack/react-query';

const useConditionalQuery = (
    queryKey,
    queryFn,
    options
) => {
    const defaultOptions = options || {};
    const enabled = queryKey?true:false;
    const newKey = queryKey || "-";
    return useQuery(newKey, queryFn, {enabled, ...defaultOptions});
}

export default useConditionalQuery;