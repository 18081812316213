import React from 'react';

import {
  ButtonGroup,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  Stack
} from '@chakra-ui/react';
import * as yup from 'yup';

import { usePostToken } from 'actions/token';
//import { treatErrorResponse } from 'customHooks/api/instance';
//import { useToastList } from 'customHooks';
import { Form, FormInput } from 'components/form';

import { useGlobalContext } from 'globalContext';

const validationSchema = yup.object().shape({
  username: yup.string().required().label('Usuário'),
  password: yup.string().required().label('Senha')
});

const ModalLogin = () => {
  const initRef = React.useRef();
  const { mutateAsync:logar, status } = usePostToken();
  const { setToken } = useGlobalContext();
  //const toastList = useToastList();

  const isSubmitting = status === 'loading';

  const onSubmit = async data => {
    try {
      const token = await logar(data);
      setToken(token);
    } catch (er) {
      throw {errors:{detail: "Erro ao efetuar login. Verifique o usuário e senha ou a conexão com a internet."}};
    }
  };

  return (
    <Modal
      closeOnOverlayClick={false}
      size="xs"
      isOpen
      initialFocusRef={initRef}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Login</ModalHeader>
        <Form
          onSubmit={onSubmit}
          validationSchema={validationSchema}
          align="flex-end"
        >
          <ModalBody as={Stack} spacing={4} width="100%">
            <FormInput ref={initRef} placeholder="Usuário" name="username" />
            <FormInput placeholder="Senha" name="password" type="password" />
          </ModalBody>
          <ModalFooter>
            <ButtonGroup size="sm">
              <Button
                type="submit"
                loadingText="Entrando"
                colorScheme="blue"
                variant="solid"
                isLoading={isSubmitting}
              >
                Entrar
              </Button>
            </ButtonGroup>
          </ModalFooter>
        </Form>
      </ModalContent>
    </Modal>
  );
};

export default ModalLogin;
