import React from 'react';
import { Routes, Route} from 'react-router-dom';

import PermissaoRoute from 'components/ui/PermissaoRoute';

import Anunciante from './Anunciante';
import Formulario from './Formulario';
import Lista from './Lista';

const Anunciantes = () => {

  return (
    <Routes>
      <Route path={`:id`} element={<PermissaoRoute permissao="anunciante.view_anunciante"><Anunciante/></PermissaoRoute>} />
      <Route path={`form`} element={<PermissaoRoute permissao="anunciante.add_anunciante"><Formulario/></PermissaoRoute>} />
      <Route path={`:id/form`} element={<PermissaoRoute permissao="anunciante.change_anunciante"><Formulario/></PermissaoRoute>} />
      <Route index element={<PermissaoRoute permissao="anunciante.view_anunciante"><Lista/></PermissaoRoute>} />
      
    </Routes>
  );
};

export default Anunciantes;
