import { useQuery } from 'customHooks';

import { useGet } from 'customHooks/api';

export const QueryKeys = {
  ME: 'me'
};

export const useGetMe = () => {
  const getMe = useGet('me');
  return useQuery([QueryKeys.ME], getMe, { staleTime: Infinity });
};
