import find from 'lodash/find';

import { useGetPapelEvento } from 'actions/enums';

const usePapelEvento = value => {
  const { data: papeis, status } = useGetPapelEvento();
  if (status === 'loading') return '';
  return find(papeis.data, ['value', value]) || '';
};

export default usePapelEvento;
