import React from 'react';
import { Stack, Flex, IconButton } from '@chakra-ui/react';
import { FaArrowLeft, FaArrowRight } from 'react-icons/fa';

const Paginator = ({ page, hasNext, onPaginate, ...rest }) => {
  const numberPage = parseInt(page);

  return (
    <Stack as={Flex} isInline justify="center" {...rest}>
      <IconButton
        aria-label="Ir para página anterior"
        variant="outline"
        size="sm"
        isRound
        onClick={() => onPaginate(Math.max(page - 1, 0))}
        isDisabled={numberPage === 1}
        icon={<FaArrowLeft/>}
      />
      <Flex
        align="center"
        justify="center"
        variant="outline"
        border="1px"
        color="gray.400"
        borderColor="gray.200"
        borderRadius="full"
        minW={8}
      >
        {page}
      </Flex>
      <IconButton
        aria-label="Ir para página posterior"
        variant="outline"
        size="sm"
        isRound
        onClick={() => onPaginate(!hasNext ? numberPage : numberPage + 1)}
        isDisabled={!hasNext}
        icon={<FaArrowRight/>}
      />
    </Stack>
  );
};

export default Paginator;
