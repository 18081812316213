import React from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Stack } from '@chakra-ui/react';

import { useGetItensHistorico } from 'actions/itens';
import {
  DisplayText,
  DisplayErrors,
  Paginator,
  Table,
  TableHead,
  TableBodyZebra,
  TableRow,
  TableHeader,
  TableData
} from 'components/ui';
import { formatCurrency, formatDate } from 'utils/ui';

const TableRowHistorico = ({ value }) => {
  const history = useNavigate();
  const {
    id,
    aluguel,
    aluguel_cliente_none,
    aluguel_data_devolucao,
    valor_previsto_cliente,
    total_pagamento_anunciante,
    anunciante_paga
  } = value;

  return (
    <TableRow key={id} onClick={() => history(`/aluguel/${aluguel}`)}>
      <TableData>{aluguel_cliente_none}</TableData>
      <TableData>
        {aluguel_data_devolucao ? formatDate(aluguel_data_devolucao) : ''}
      </TableData>
      <TableData>
        {valor_previsto_cliente ? formatCurrency(valor_previsto_cliente) : '—'}
      </TableData>
      <TableData>
        {total_pagamento_anunciante
          ? formatCurrency(total_pagamento_anunciante)
          : '—'}
      </TableData>
      <TableData>{anunciante_paga ? 'Sim' : 'Não'}</TableData>
    </TableRow>
  );
};

const TabContentHistorico = () => {
  const { id } = useParams();
  const [page, setPage] = React.useState(1);
  const {
    data: historico,
    hasNext,
    status,
    error
  } = useGetItensHistorico({ id, page });

  if (status === 'loading') return <DisplayText>Carregando...</DisplayText>;

  if (status === 'error') return <DisplayErrors errors={error.errors} />;

  return (
    <Stack>
      <Table>
        <TableHead>
          <TableRow>
            <TableHeader>Cliente</TableHeader>
            <TableHeader>Data Devolução</TableHeader>
            <TableHeader>Valor Aluguel</TableHeader>
            <TableHeader>Valor Anunciante</TableHeader>
            <TableHeader>Anunciante Paga</TableHeader>
          </TableRow>
        </TableHead>
        <TableBodyZebra>
          {historico.data.results.length === 0 ? (
            <TableRow>
              <TableData colSpan="5">Nenhuma informação disponível</TableData>
            </TableRow>
          ) : (
            historico.data.results.map(_historico => (
              <TableRowHistorico key={_historico.id} value={_historico} />
            ))
          )}
        </TableBodyZebra>
      </Table>
      <Paginator page={page} onPaginate={setPage} hasNext={hasNext} />
    </Stack>
  );
};

export default TabContentHistorico;
