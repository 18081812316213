import React from 'react';
import { Text } from '@chakra-ui/react';

const currencyFormatter = new Intl.NumberFormat('pt-BR', {
  style: 'currency',
  currency: 'BRL'
});

const formatCurrency = value => currencyFormatter.format(value);


const CurrencyText = ({ value, ...rest }) => {
  let color = "green.500";
  if (value < 0){
    value = -value;
    color = 'red.500'; 
  }
  return (  
    <Text color={color} as="span" {...rest}>
      {formatCurrency(value)}
    </Text>
  );
};

export default CurrencyText;
