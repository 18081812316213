import React from 'react';
import {
  Text,
  ButtonGroup,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalFooter,
  ModalBody,
  Divider
} from '@chakra-ui/react';

import { FormTableRow, FormTableCell } from 'components/form';
import { useTipoPagamentoAnunciante } from 'customHooks/ui';
import { formatCurrency, formatDate } from 'utils/ui';

const Pagamento = ({ pagamento }) => {
  const tipo = useTipoPagamentoAnunciante(pagamento.tipo);

  return (
    <FormTableRow px={4} py={2}>
      <Text fontSize="sm">{tipo.label}</Text>
      <Text fontSize="sm">{formatCurrency(pagamento.valor)}</Text>
      <Text fontSize="sm">{formatDate(pagamento.data)}</Text>
    </FormTableRow>
  );
};

const ModalVerPagamentos = ({ isOpen, onClose, pagamentos }) => {
  const initRef = React.useRef();

  const totalPago = pagamentos.reduce((result, pagamento) => {
    const valorNumero = parseFloat(pagamento.valor);
    return valorNumero ? (result += valorNumero) : result;
  }, 0);

  return (
    <Modal
      closeOnOverlayClick={false}
      size="lg"
      initialFocusRef={initRef}
      isOpen={isOpen}
      onClose={onClose}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Pagamento</ModalHeader>
        <ModalCloseButton />
        <ModalBody width="100%">
          <FormTableRow px={4} py={2}>
            <FormTableCell>Tipo</FormTableCell>
            <FormTableCell>Valor</FormTableCell>
            <FormTableCell>Data</FormTableCell>
          </FormTableRow>
          <Divider m={0} />
          {pagamentos.map(pagamento => (
            <Pagamento key={pagamento.id} pagamento={pagamento} />
          ))}
          <FormTableRow bg="yellow.100" px={4} py={2}>
            <FormTableCell>Total Pago</FormTableCell>
            <FormTableCell>{formatCurrency(totalPago)}</FormTableCell>
          </FormTableRow>
        </ModalBody>
        <ModalFooter>
          <ButtonGroup size="sm">
            <Button
              ref={initRef}
              colorScheme="blue"
              variant="solid"
              onClick={onClose}
            >
              OK
            </Button>
          </ButtonGroup>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default ModalVerPagamentos;
