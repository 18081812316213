import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Stack, Image, SimpleGrid } from '@chakra-ui/react';

import { useGetAnuncianteItens } from 'actions/anunciantes';
import { DisplayText, MasonryItem, Paginator } from 'components/ui';

const TabContentItens = ({ id }) => {
  const [page, setPage] = React.useState(1);
  const { data: itens, status, hasNext } = useGetAnuncianteItens({
    id,
    page
  });

  if (status === 'loading') return <DisplayText>Carregando...</DisplayText>;

  const { results } = itens.data;

  if (results.length === 0)
    return <DisplayText>Nenhuma informação disponível</DisplayText>;

  return (
    <Stack>
      <SimpleGrid columns={4} spacing={10}>
      {results.map(item => (
          <MasonryItem
            key={item.id}
            as={RouterLink}
            to={`/itens/${item.id}`}
            p={2}
            h={"300px"}
          >
            <Image
              htmlWidth={item.size.width}
              htmlHeight={item.size.height}
              ignoreFallback
              src={item.url_thumbnail}
              objectFit='contain'
            />
          </MasonryItem>))}
      </SimpleGrid>
      <Paginator page={page} onPaginate={setPage} hasNext={hasNext} />
    </Stack>
  );
};

export default TabContentItens;
