import React from 'react';
import {
  Link as RouterLink,
  useNavigate
} from 'react-router-dom';
import { Grid, Stack, Text, Flex, Image, IconButton } from '@chakra-ui/react';
import get from 'lodash/get';
import { FaPlus } from 'react-icons/fa';
import { stringify } from 'qs';

import { useGetVendedoras } from 'actions/vendedoras';
import { Paginator } from 'components/ui';
import { useDocumentTitle, useSearchParams } from 'customHooks';

const initialFiltros = {
  page: 1
};

const DisplayText = ({ children }) => {
  return (
    <Text color="gray.400" as="em">
      {children}
    </Text>
  );
};

const Card = ({ value }) => {
  return (
    <Flex
      as={RouterLink}
      to={`/vendedoras/${value.id}`}
      min-width="200px"
      border="1px"
      borderColor="#DDD"
      borderRadius="md"
      boxShadow="sm"
      align="center"
      p={1}
    >
      <Image
        src={value.foto_url}
        alt={value.nome}
        boxSize="100px"
        fallbackSrc="/static/img/pessoa/default.jpg"
      />
      <Stack spacing={1} px={4}>
        <Text color="blue.500" fontWeight="semibold" fontSize="sm">
          {value.nome}
        </Text>
        <Text color="gray.400" fontSize="sm">
          Vendedora
        </Text>
      </Stack>
    </Flex>
  );
};

const GridVendedoras = ({ filtros, onPaginate }) => {
  const { page } = filtros;
  const { status, data: vendedoras, error, hasNext } = useGetVendedoras(
    filtros
  );

  if (status === 'loading') return <DisplayText>Carregando...</DisplayText>;

  if (status === 'error')
    return <DisplayText>{get(error, 'response.data.detail')}</DisplayText>;

  const { results } = vendedoras.data;

  if (results.length === 0)
    return <DisplayText>Nenhuma informação disponível</DisplayText>;

  return (
    <Stack spacing={4}>
      <Grid
        templateColumns={[
          'repeat(1, 1fr)',
          'repeat(2, 1fr)',
          'repeat(3, 1fr)',
          'repeat(3, 1fr)'
        ]}
        gap={4}
      >
        {results.map(vendedora => (
          <Card key={vendedora.id} value={vendedora} />
        ))}
      </Grid>
      <Paginator page={page} onPaginate={onPaginate} hasNext={hasNext} />
    </Stack>
  );
};

const Lista = () => {
  const searchParams = useSearchParams(initialFiltros);
  const [filtros, setFiltrosState] = React.useState(searchParams);
  const history = useNavigate();
  useDocumentTitle('Vendedoras');

  const setFiltros = React.useCallback(
    newFiltros => {
      const filtros = { ...searchParams, ...newFiltros };
      history(`?${stringify(filtros)}`, { replace: true });
    },
    [setFiltrosState, searchParams]
  );

  //React.useEffect(() => {
  //  setTermoPesquisa(filtros.search || '');
  //}, [filtros]);

  React.useEffect(() => {
    setFiltrosState(searchParams);
  }, [searchParams]);

  return (
    <Stack spacing={4} p={4}>
      <Flex ml="auto">
        <IconButton
          as={RouterLink}
          to={`form`}
          size="sm"
          icon={<FaPlus/>}
        />
      </Flex>
      <GridVendedoras
        filtros={filtros}
        onPaginate={page => setFiltros({ page })}
      />
    </Stack>
  );
};

export default Lista;
