import React from 'react';
import { useLocation } from 'react-router-dom';
import { parse } from 'qs';
import isEmpty from 'lodash/isEmpty';

const useSearchParams = (initialSearchParams = {}) => {
  const location = useLocation();
  return React.useMemo(() => {
    const searchParams = parse(location.search, { ignoreQueryPrefix: true });
    return isEmpty(searchParams) ? initialSearchParams : searchParams;
  }, [initialSearchParams, location.search]);
};

export default useSearchParams;
