import * as yup from 'yup';

export default yup.object().shape({
  cliente: yup
    .object()
    .nullable()
    .when('id', (id, schema) => (id ? schema : schema.required()))
    .label('Cliente'),
  vendedora: yup
    .object()
    .nullable()
    .when('id', (id, schema) => (id ? schema : schema.required()))
    .label('Vendedora'),
  data_retirada: yup
    .string()
    .required()
    .matches(/^\d{4}-\d{2}-\d{2}$/, {
      message: 'Data da Retirada de deve corresponder ao formato DD/MM/YYYY',
      excludeEmptyString: true
    })
    .label('Data da Retirada'),
  data_devolucao: yup
    .string()
    .required()
    .matches(/^\d{4}-\d{2}-\d{2}$/, {
      message: 'Data da Devolução deve corresponder ao formato DD/MM/YYYY',
      excludeEmptyString: true
    })
    .label('Data da Devolução'),
  aluguel_itens: yup
    .array()
    .of(
      yup.object().shape({
        item: yup.number().nullable().required().label('Item'),
        valor_negociado: yup.string().required().label('Valor'),
        // data: yup.string().required().label('Data')
      })
    )
    .required('Deve haver ao menos um item'),
  pagamentos_cliente: yup.array().of(
    yup.object().shape({
      tipo: yup.object().nullable().required().label('Tipo'),
      valor: yup.string().required().label('Valor'),
      data: yup
        .string()
        .required()
        .matches(/^\d{4}-\d{2}-\d{2}$/, {
          message: 'Data deve corresponder ao formato DD/MM/YYYY',
          excludeEmptyString: true
        })
        .label('Data')
    })
  )
});
