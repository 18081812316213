import React from 'react';
import { Tag } from '@chakra-ui/react';

const StatusColor = {
  RESERVA: 'blue',
  ANDAMENTO: 'orange',
  FINALIZADO: 'green',
  CANCELADO: 'red'
};

const StatusLabel = {
  RESERVA: 'Reserva',
  ANDAMENTO: 'Andamento',
  FINALIZADO: 'Finalizado',
  CANCELADO: 'Cancelado'
};

const StatusAluguel = ({ value, ...rest }) => {
  return <Tag colorScheme={StatusColor[value]} {...rest}>{StatusLabel[value] || value}</Tag>;
};

export default StatusAluguel;
