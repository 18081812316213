import React from 'react';

import InputMask from './InputMask';

const InputPhone = React.forwardRef(
    (props, ref) => {

        const mask = [
            {
                mask: '(00) 0000-0000'
            },
            {
                mask: '(00) 00000-0000'
            }
        ];

        const dispatch = (appended, dynamicMasked) => {
            var number = (dynamicMasked.value + appended).replace(/\D/g, '');
            if (number.length > 10) {
                return dynamicMasked.compiledMasks[1]
            }
            return dynamicMasked.compiledMasks[0]
        }

        return (
            <InputMask
                mask={mask}
                dispatch={dispatch}
                {...props}
                ref={ref}
            />
        );
    });
export default InputPhone;
