import isEmpty from 'lodash/isEmpty';
import get from 'lodash/get';
import { prepareInputCurrencyValue } from 'utils/form';

export default itens => {
  return !isEmpty(itens)
    ? itens.map(item => {
        const valorItemLavanderia = get(item, 'aluguel_item_lavanderia.valor');
        const idItemLavanderia = get(item, 'aluguel_item_lavanderia.id');
        return {
          id: item.id,
          item: item.item,
          item_referencia: item.item_referencia,
          valor_negociado: prepareInputCurrencyValue(item.valor_negociado),
          aluguel_item_lavanderia: {
            id: idItemLavanderia,
            valor: valorItemLavanderia
              ? prepareInputCurrencyValue(valorItemLavanderia)
              : ''
          }
        };
      })
    : [
        {
          item: null,
          valor_negociado: '',
          aluguel_item_lavanderia: { valor: '' }
        }
      ];
};
