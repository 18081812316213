import React from 'react';
import { useDropzone } from 'react-dropzone';
import { Box, Image, IconButton } from '@chakra-ui/react';
import { FaTrash, FaPlus } from 'react-icons/fa';

const Dropzone = React.forwardRef(
  ({ onDrop, value, ...rest }, ref) => {
  const [preview, setPreview] = React.useState(null);

  

  const updateFile = React.useCallback(
    newFile => {
      if (newFile instanceof File) {
        setPreview({preview:URL.createObjectURL(newFile)});
        if (onDrop) onDrop(newFile);
      }
      if (newFile && typeof(newFile) === 'object' && Object.prototype.hasOwnProperty.call(newFile, "preview")) {
        setPreview(newFile.preview);
      }
    },
    [onDrop]
  );

  const handleOnDrop = React.useCallback(
    acceptedFiles => {
      updateFile(acceptedFiles[0]);
    },
    [updateFile]
  );

  const { getRootProps, getInputProps, open } = useDropzone({
    accept: 'image/*',
    onDrop: handleOnDrop,
    ref: ref
  });

  React.useEffect(
    // FIXME () =>
    () => () => {
      if (preview) URL.revokeObjectURL(preview.preview);
    },
    [preview]
  );

  React.useEffect(() => {
    updateFile(value);
  }, [updateFile, value]);

  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      position="relative"
      p={6}
      border="2px"
      borderColor="gray.200"
      borderStyle="dashed"
      bg="gray.50"
      fontSize="sm"
      color="gray.400"
      outline="none"
      transition="border .24s ease-in-out"
      _hover={{ borderColor: 'gray.300' }}
      _focus={{ borderColor: 'blue.500' }}
      {...getRootProps()}
      {...rest}
    >
      <input {...getInputProps()}/>
      {!preview ? (
        <>
        <Box h="100px"
        w="120px" bg="white"
        boxShadow="sm"
        border="1px"
        borderColor="gray.300"
        borderRadius={5}
        p={3}
        >
        <Image
          src="/static/img/item/default.png"
          margin="auto"
          maxH="80px"
          ignoreFallback
        />
        </Box>
        <IconButton
          size="sm"
          colorScheme="green"
          aria-label="Remover imagem"
          icon={<FaPlus/>}
          mt={2}
          onClick={e => {
            e.stopPropagation();
            if (open) open()
          }}
        />
      </>
      ) : (
        <>
        <Box h="100px"
        w="120px" bg="white"
        boxShadow="sm"
        border="1px"
        borderColor="gray.300"
        borderRadius={5}
        p={3}
        textAlign="center"
        >
          <Image
            src={preview.preview}
            margin="auto"
            maxH="80px"
            ignoreFallback
          /> </Box>
          <IconButton
            size="sm"
            colorScheme="red"
            aria-label="Remover imagem"
            icon={<FaTrash/>}
            mt={2}
            onClick={e => {
              e.stopPropagation();
              setPreview();
              if (onDrop) onDrop();
            }}
          />
        </>
      )}
    </Box>
  );
});

export default Dropzone;
