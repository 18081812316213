import React from 'react';
import { useFormContext } from 'react-hook-form';
import {
  ButtonGroup,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  FormControl,
  FormLabel,
  Stack
} from '@chakra-ui/react';
import * as yup from 'yup';
import { format } from 'date-fns';

import { useGetEstadoCivil } from 'actions/enums';
import { Form, FormInput, FormInputDate, FormSelect } from 'components/form';
import { prepareInputDateValue } from 'utils/form';
import isEmpty from 'lodash/isEmpty';

const validationSchema = yup.object().shape({
  nacionalidade: yup.string().required().label('Nacionalidade'),
  data_nascimento: yup.string().matches(/^\d{4}-\d{2}-\d{2}$/, {
    message: 'Data do Contrato deve corresponder ao formato DD/MM/YYYY',
    excludeEmptyString: true
  })
});

const FormContrato = () => {
  const { formState: { errors } } = useFormContext();
  const {
    data: estadosCivis,
    status: statusEstadosCivis
  } = useGetEstadoCivil();

  const isLoadingEstadosCivis = statusEstadosCivis === 'loading';

  return <ModalBody as={Stack} spacing={4} width="100%">
    <FormControl isInvalid={!isEmpty(errors['nacionalidade'])}>
      <FormLabel fontSize="sm" htmlFor="nacionalidade">
        Nacionalidade
      </FormLabel>
      <FormInput name="nacionalidade" />
    </FormControl>
    <FormControl isInvalid={!isEmpty(errors['estado_civil'])}>
      <FormLabel fontSize="sm" htmlFor="estado_civil">
        Estado Civil
      </FormLabel>
      <FormSelect
        name="estado_civil"
        isLoading={isLoadingEstadosCivis}
        options={!isLoadingEstadosCivis && estadosCivis.data}
      />
    </FormControl>
    <FormControl isInvalid={!isEmpty(errors['data'])}>
      <FormLabel fontSize="sm" htmlFor="data">
        Data do Contrato
      </FormLabel>
      <FormInputDate name="data" />
    </FormControl>
  </ModalBody>
};

const ModalContrato = ({ isOpen, onClose, onSubmit, isSubmitting }) => {
  const initRef = React.useRef();
  

  const defaultValues = {
    nacionalidade: 'brasileira',
    data: prepareInputDateValue(format(new Date(), 'yyyy-MM-dd'))
  };



  return (
    <Modal
      closeOnOverlayClick={false}
      size="md"
      initialFocusRef={initRef}
      isOpen={isOpen}
      onClose={onClose}
    >
      <ModalOverlay />

      <ModalContent>
        <ModalHeader>Contrato</ModalHeader>
        <ModalCloseButton />
        <Form
          defaultValues={defaultValues}
          validationSchema={validationSchema}
          onSubmit={onSubmit}
          align="flex-end"
          spacing={0}
        >
          <FormContrato />
          <ModalFooter>
            <ButtonGroup size="sm">
              <Button
                type="submit"
                loadingText="Gerando"
                colorScheme="blue"
                variant="solid"
                ref={initRef}
                isLoading={isSubmitting}
              >
                Gerar Contrato
              </Button>
            </ButtonGroup>
          </ModalFooter>
        </Form>
      </ModalContent>
    </Modal>
  );
};

export default ModalContrato;
