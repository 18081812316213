import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Box, Heading, Button } from '@chakra-ui/react';
import * as yup from 'yup';
import find from 'lodash/find';

import {
  useGetLavanderia,
  usePostLavanderia,
  usePutLavanderia
} from 'actions/lavanderias';
import { useGetStatusLavanderia } from 'actions/enums';
import { useGetOptionsItensLavanderia, useGetOptionsEmpresas } from 'actions/options';
import {
  Form,
  FormGroupButton,
  FormFieldset,
  FormControlInput,
  FormControlInputDate,
  FormControlSelect,
  FormInputCurrency
} from 'components/form';
import { DisplayText, SelectOptionItem } from 'components/ui';
import { useDocumentTitle } from 'customHooks';
import {
  prepareInputValues,
  prepareInputDateValue,
  prepareOutputValues,
  prepareOutputDateValue,
  prepareInputCurrencyValue,
  prepareOutputCurrencyValue
} from 'utils/form';

const validationSchema = yup.object().shape({
  item: yup.object().nullable().required().label('Item'),
  data_inicio: yup.string().matches(/^\d{4}-\d{2}-\d{2}$/, {
    message: 'Data de Ida deve corresponder ao formato DD/MM/YYYY',
    excludeEmptyString: true
  }),
  data_fim: yup.string().matches(/^\d{4}-\d{2}-\d{2}$/, {
    message: 'Data de Volta deve corresponder ao formato DD/MM/YYYY',
    excludeEmptyString: true
  }),
  valor: yup.string().required().label('Valor Lavanderia')
});

const Formulario = () => {
  const history = useNavigate();
  const { id } = useParams();
  const { data: lavanderia, status: statusLavanderia } = useGetLavanderia(id);
  const { data: itens, status: statusItens } = useGetOptionsItensLavanderia();
  const { data: status, status: statusStatus } = useGetStatusLavanderia();
  const { data: empresas, status: statusEmpresas } = useGetOptionsEmpresas();

  const { mutateAsync: criarLavanderia, status: statusCriar } =
    usePostLavanderia();
  const { mutateAsync: editarLavanderia, status: statusEditar } =
    usePutLavanderia(id);

  const isEditing = Boolean(id);
  useDocumentTitle(isEditing ? `Editar Lavanderia ${id}` : 'Nova Lavanderia');
  const isSubmitting = statusCriar === 'loading' || statusEditar === 'loading';
  const isLoadingLavanderia = statusLavanderia === 'loading';
  const isLoadingItens = statusItens === 'loading';
  const isLoadingStatus = statusStatus === 'loading';
  const isLoadingEmpresas = statusEmpresas === 'loading';

  const optionsItens = itens
    ? itens.data.map(item => ({
      value: item.id,
      label: item.referencia,
      thumbnail: item.url_thumbnail,
      valorAluguel: item.valor_aluguel,
      valorLavanderia: item.valor_lavanderia
    }))
    : [];
  const optionsStatus = status && status.data;
  const optionsEmpresas = empresas
    ? empresas.data.map(({ id, nome }) => ({
      value: id,
      label: nome
    }))
    : [];

  const onSubmit = async data => {

    const outputValues = prepareOutputValues(data);

    outputValues.item = outputValues.item ? outputValues.item.value : null;

    outputValues.status = outputValues.status
      ? outputValues.status.value
      : null;

    outputValues.empresa = outputValues.empresa
      ? outputValues.empresa.value
      : null;

    outputValues.data_inicio = outputValues.data_inicio
      ? prepareOutputDateValue(outputValues.data_inicio)
      : null;

    outputValues.data_fim = outputValues.data_fim
      ? prepareOutputDateValue(outputValues.data_fim)
      : null;

    outputValues.valor = outputValues.valor
      ? prepareOutputCurrencyValue(outputValues.valor)
      : null;

    const criarEditar = isEditing ? editarLavanderia : criarLavanderia;

    const { data: newData } = await criarEditar(outputValues);

    history(`/lavanderia/${newData.id}`);
  };

  if (isEditing && isLoadingLavanderia) return <DisplayText>Carregando...</DisplayText>;

  const inputValues = prepareInputValues(lavanderia ? {
    item: find(optionsItens, ['value', lavanderia.data.item]),
    status: find(optionsStatus, ['value', lavanderia.data.status]),
    empresa: find(optionsEmpresas, ['value', lavanderia.data.empresa]),
    data_inicio: prepareInputDateValue(lavanderia.data.data_inicio),
    data_fim: prepareInputDateValue(lavanderia.data.data_fim),
    valor: prepareInputCurrencyValue(lavanderia.data.valor)
  }
    : {});

  return (
    <Box p={4}>
      <Heading size="lg">
        {isEditing ? `Lavanderia ${id}` : 'Cadastro de Lavanderia'}
      </Heading>
      <Form onSubmit={onSubmit} validationSchema={validationSchema} defaultValues={inputValues}>
        <FormFieldset>
          <FormControlSelect
            label="Vestido"
            name="item"
            isDisabled={isEditing}
            isLoading={isLoadingItens}
            options={optionsItens}
            components={{ Option: SelectOptionItem }}
            selectFirst={false}
            maxW={300}
            initialValue={find(optionsItens, ['value', inputValues.item])}
          />
          {isEditing && (
            <FormControlSelect
              label="Status"
              name="status"
              isLoading={isLoadingStatus}
              options={optionsStatus}
              maxW={300}
              selectFirst={false}
              initialValue={find(optionsStatus, ['value', inputValues.status])}
            />
          )}
          <FormControlSelect
            label="Empresa"
            name="empresa"
            isLoading={isLoadingEmpresas}
            options={optionsEmpresas}
            maxW={300}
            selectFirst={false}
            initialValue={find(optionsEmpresas, ['value', inputValues.empresa])}
          />
          <FormControlInputDate
            label="Data de Ida"
            name="data_inicio"
            maxW={200}
            defaultValue={prepareInputDateValue(inputValues.data_inicio)}
          />
          <FormControlInputDate
            label="Data de Volta"
            name="data_fim"
            maxW={200}
            defaultValue={prepareInputDateValue(inputValues.data_fim)}
          />
          <FormControlInput
            label="Valor Lavanderia"
            name="valor"
            inputComponent={FormInputCurrency}
            leftAddon="R$"
            maxW={100}
            initialValue={prepareInputCurrencyValue(inputValues.valor)}
          />
        </FormFieldset>
        <FormGroupButton>
          <Button
            size="sm"
            colorScheme="blue"
            type="submit"
            loadingText="Salvando"
            isLoading={isSubmitting}
          >
            Salvar
          </Button>
        </FormGroupButton>
      </Form>
    </Box>
  );
};

export default Formulario;
