import React from 'react';
import { Stack, Grid, Link } from '@chakra-ui/react';
import { FaExternalLinkAlt } from "react-icons/fa";

import { Control, ControlText, ControlNull } from 'components/ui';
import { FormFieldset } from 'components/form';
import { formatDate } from 'utils/ui';

const ControlLarger = props => (
  <Control gridTemplateColumns={['1fr', '1fr', '200px 1fr']} {...props} />
);

const ControlEndereco = ({ cliente }) => {
  const { logradouro, bairro, cidade, uf, cep } = cliente;

  const hasLocalizacao = bairro || cidade || uf;
  const hasEndereco = logradouro || hasLocalizacao || cep;

  if (!hasEndereco) return <ControlNull />;

  return (
    <Stack spacing={0}>
      {logradouro && <ControlText>{logradouro}</ControlText>}
      {hasLocalizacao && (
        <ControlText>{`${bairro || ''}${
          cidade ? `${bairro ? ', ' : ''}${cidade}` : ''
        }${uf ? `${bairro || cidade ? ' - ' : ''}${uf}` : ''}`}</ControlText>
      )}
      {cep && <ControlText>{cep}</ControlText>}
    </Stack>
  );
};


const TabContentSobre = ({ cliente }) => {
  return (
    <Stack spacing={12}>
      <Grid gap={12} templateColumns={['1fr', '1fr', '1fr 1fr']}>
        <FormFieldset label="Informações">
          <ControlLarger label="RG">
            <ControlText>{cliente.rg}</ControlText>
          </ControlLarger>
          <ControlLarger label="CPF">
            <ControlText>{cliente.cpf}</ControlText>
          </ControlLarger>
          <ControlLarger label="Endereço" alignItems="start">
            <ControlEndereco cliente={cliente} />
          </ControlLarger>
          <ControlLarger label="Data Nascimento">
            {cliente.data_nascimento ? (
              <ControlText>{formatDate(cliente.data_nascimento)}</ControlText>
            ) : (
              <ControlNull />
            )}
          </ControlLarger>
          <ControlLarger label="Profissão">
            <ControlText>{cliente.profissao}</ControlText>
          </ControlLarger>
          <ControlLarger label="Empresa">
            <ControlText>{cliente.empresa}</ControlText>
          </ControlLarger>
        </FormFieldset>
        <FormFieldset label="Medidas">
          <ControlLarger label="Data">
            {cliente.data_nascimento ? (
              <ControlText>{formatDate(cliente.data_medida)}</ControlText>
            ) : (
              <ControlNull />
            )}
          </ControlLarger>
          <ControlLarger label="Altura total">
            <ControlText>{cliente.altura}</ControlText>
          </ControlLarger>
          <ControlLarger label="Busto">
            <ControlText>{cliente.busto}</ControlText>
          </ControlLarger>
          <ControlLarger label="Cintura">
            <ControlText>{cliente.cintura}</ControlText>
          </ControlLarger>
          <ControlLarger label="Quadril">
            <ControlText>{cliente.quadril}</ControlText>
          </ControlLarger>
          <ControlLarger label="Ombro ao Chão">
            <ControlText>{cliente.ombro_chao}</ControlText>
          </ControlLarger>
          <ControlLarger label="Altura do Salto">
            <ControlText>{cliente.altura_salto}</ControlText>
          </ControlLarger>
          <ControlLarger label="Foto/Vídeo de Corpo Inteiro">
            {cliente.url_corpo_inteiro ? (
              <ControlText><Link isExternal href={cliente.url_corpo_inteiro}><FaExternalLinkAlt /></Link></ControlText>
            ) : (
              <ControlNull />
            )}
          </ControlLarger>
        </FormFieldset>
      </Grid>
    </Stack>
  );
};

export default TabContentSobre;
