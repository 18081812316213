import {useState} from 'react'
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useQuery } from 'customHooks';

import { useGet, usePost } from 'customHooks/api';

export const QueryKeys = {
  CONFIGURACOES: 'configuracoes',
  RELATORIO: 'relatorio_aluguel'
};

export const useGetConfiguracoes = () => {
  const getConfiguracoes = useGet('configuracoes');
  return useQuery([QueryKeys.CONFIGURACOES], getConfiguracoes);
};

export const usePostConfiguracoes = () => {
  const queryClient = useQueryClient();
  const postConfiguracoes = usePost('configuracoes');
  return useMutation(postConfiguracoes, {
    onSuccess: data => queryClient.setQueryData([QueryKeys.CONFIGURACOES], data)
  });
};

export const usePostRelatorio = (id, queryConfig) => {
  const post = usePost(`dados.xlsx`);
  return useMutation(post, queryConfig);
};

export const useGetRelatorio = (queryConfig) => {
  const getRelatorio = useGet(`dados.xlsx`, {
    responseType: 'blob'
  });
  return useQuery(
    [QueryKeys.RELATORIO],
    getRelatorio,
    queryConfig
  );
};

export const useDownloadRelatorio = () => {
  const [isFetching, setFetching] = useState(false);
  const { refetch: recuperarRelatorio } = useGetRelatorio({
    enabled: false,
  });

  const fetch = async () => {
    try {
      setFetching(true);
      const res = await recuperarRelatorio();

      const url = URL.createObjectURL(res.data.data);
      const element = document.createElement('a');
      setFetching(false);

      element.setAttribute('href', url);
      element.setAttribute('download', `relatorio.xlsx`);
      element.setAttribute('target', '_blank');
      element.style.display = 'none';
      document.body.appendChild(element);
      element.click();
      document.body.removeChild(element);
    } catch (er) {
      setFetching(false);
      throw er;
    }
  };

  return [fetch, { isFetching }];
};
