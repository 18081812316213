import React from 'react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useQuery } from 'customHooks';

import { useGet, usePost, usePut, useDelete } from 'customHooks/api';
import { usePaginatedPrefetchQuery } from 'customHooks';

export const QueryKeys = {
  LAVANDERIA: 'lavanderia',
  LAVANDERIAS: 'lavanderias',
  LAVANDERIAS_INDO: 'lavanderias/indo',
  LAVANDERIAS_VOLTANDO: 'lavanderias/voltando'
};

export const useGetLavanderia = id => {
  const getLavanderia = useGet('lavanderias/{id}');
  return useQuery({
    queryKey: [QueryKeys.LAVANDERIA, { id }],
    queryFn:getLavanderia,
    enabled: !!id
  });
};

export const useGetLavanderias = filtros => {
  const queryKey = React.useMemo(() => [QueryKeys.LAVANDERIAS, filtros], [
    filtros
  ]);
  const getLavanderias = useGet('lavanderias');
  return usePaginatedPrefetchQuery(queryKey, getLavanderias);
};

export const useGetLavanderiasIndo = filtros => {
  const queryKey = React.useMemo(() => [QueryKeys.LAVANDERIAS_INDO, filtros], [
    filtros
  ]);
  const getLavanderias = useGet('lavanderias/indo');
  return usePaginatedPrefetchQuery(queryKey, getLavanderias);
};

export const useGetLavanderiasVoltando = filtros => {
  const queryKey = React.useMemo(
    () => [QueryKeys.LAVANDERIAS_VOLTANDO, filtros],
    [filtros]
  );
  const getLavanderias = useGet('lavanderias/voltando');
  return usePaginatedPrefetchQuery(queryKey, getLavanderias);
};

export const usePostLavanderia = queryConfig => {
  const postLavanderia = usePost('lavanderias');
  return useMutation(postLavanderia, queryConfig);
};

export const usePostLavanderiaIr = id => {
  const queryClient = useQueryClient();
  const postIr = usePost(`lavanderias/${id}/ir`);
  return useMutation(postIr, {
    onSuccess: data =>
    queryClient.setQueryData([QueryKeys.LAVANDERIA, { id: `${id}` }], data)
  });
};

export const usePostLavanderiaVoltar = id => {
  const queryClient = useQueryClient();
  const postIr = usePost(`lavanderias/${id}/voltar`);
  return useMutation(postIr, {
    onSuccess: data =>
    queryClient.setQueryData([QueryKeys.LAVANDERIA, { id: `${id}` }], data)
  });
};

export const usePutLavanderia = id => {
  const queryClient = useQueryClient();
  const putLavanderia = usePut(`lavanderias/${id}`);
  return useMutation(putLavanderia, {
    onSuccess: data =>
    queryClient.setQueryData([QueryKeys.LAVANDERIA, { id: `${id}` }], data)
  });
};

export const useDeleteLavanderia = id => {
  const deleteLavanderia = useDelete(`lavanderias/${id}`);
  return useMutation(deleteLavanderia);
};
