import React from 'react';
import { Stack } from '@chakra-ui/react';

import { useDownloadAnuncianteContrato } from 'actions/anunciantes';
import { Control, ControlText, ControlLink, ControlNull } from 'components/ui';
import { useToastList } from 'customHooks';
import { formatDate } from 'utils/ui';
import isEmpty from 'lodash/isEmpty';
import join from 'lodash/join';

const TabContentSobre = ({ anunciante }) => {
  const {
    id,
    rg,
    cpf,
    logradouro,
    bairro,
    cidade,
    uf,
    cep,
    data_nascimento,
    has_contrato
  } = anunciante;

  const [downloadContrato] = useDownloadAnuncianteContrato(id);
  const toastList = useToastList();

  const hasLocalizacao = bairro || cidade || uf;
  const hasEndereco = logradouro || hasLocalizacao || cep;
  const localizacao = [];
  if (bairro) localizacao.push(bairro);
  if (cidade) localizacao.push(cidade);

  const baixarContrato = async () => {
    try {
      await downloadContrato();
    } catch (er) {
      toastList({ messages: er.errors, status: 'error' });
    }
  };

  return (
    <Stack>
      <Control label="RG">
        <ControlText>{rg}</ControlText>
      </Control>
      <Control label="CPF">
        <ControlText>{cpf}</ControlText>
      </Control>
      <Control label="Endereço" alignItems="start">
        {hasEndereco ? (
          <Stack spacing={0}>
            {logradouro && <ControlText>{logradouro}</ControlText>}
            {!isEmpty(localizacao) && (
              <ControlText>{`${join(localizacao, ', ')}${
                uf ? ` - ${uf}` : ''
              }`}</ControlText>
            )}

            {cep && <ControlText>{cep}</ControlText>}
          </Stack>
        ) : (
          <ControlNull />
        )}
      </Control>
      <Control label="Data de Nascimento">
        {data_nascimento ? (
          <ControlText>{formatDate(data_nascimento)}</ControlText>
        ) : (
          <ControlNull />
        )}
      </Control>
      <Control label="Contrato">
        {has_contrato ? (
          <ControlLink onClick={baixarContrato}>Visualizar</ControlLink>
        ) : (
          <ControlText>—</ControlText>
        )}
      </Control>
    </Stack>
  );
};

export default TabContentSobre;
