import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Menu, IconButton, MenuList, MenuButton } from '@chakra-ui/react';
import { FaChevronDown, FaPen } from 'react-icons/fa';

import { IconMenuItem } from 'components/ui';

const AcoesButton = () => {

  return (
    <Menu placement="bottom-end">
      <MenuButton
        as={IconButton}
        size="sm"
        variant="outline"
        icon={<FaChevronDown/>}
      />
      <MenuList minWidth="150px">
        <IconMenuItem icon={FaPen} as={RouterLink} to={`form`}>
          Editar
        </IconMenuItem>
      </MenuList>
    </Menu>
  );
};

export default AcoesButton;
