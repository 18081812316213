import React from 'react';
import { Box } from '@chakra-ui/react';
import styled from '@emotion/styled';

export {
  //Table,
  //Thead as TableHead,
  Tbody as TableBody,
  //Tfoot,
  //Tr as TableRow,
  //Th as TableHeader,
  //Td as TableData,
  //TableCaption,
  //TableContainer,
} from '@chakra-ui/react'

import {
  TableContainer,
  Table as CHTable,
  Thead,
  Tbody,
  //Tfoot,
  Tr,
  Th,
  Td,
  //TableCaption,
  //TableContainer,
} from '@chakra-ui/react'

export const Table = ({ children, style, ...rest }) => {
  return (
    <TableContainer>
    <CHTable
      //as="table"
      //display="table"
      //width="100%"
      //border="1px"
      borderColor="gray.200"
      fontSize="sm"
      style={{
        borderCollapse: 'collapse',
        borderSpacing: '2px',
        ...style
      }}
      {...rest}
    >
      {children}
    </CHTable>
    </TableContainer>
  );
};

export const TableHead = ({ children, ...rest }) => {
  return (
    <Thead fontWeight="bold" {...rest}>
      {children}
    </Thead>
  );
};

export const TableBodyx = styled(Box)``;

TableBodyx.defaultProps = {
  as: 'tbody'
};
/*
& > tr:nth-of-type(even) {
    background-color: ${props => props.theme.colors.gray[50]};
  }
*/
export const TableBodyZebra = styled(Tbody)``;

//TableBodyZebra.defaultProps = {
//  as: 'tbody'
//};

export const TableRow = ({ children, onClick, ...rest }) => {
  return (
    <Tr
      //as="tr"
      //role="row"
      onClick={onClick}
      cursor={onClick && 'pointer'}
      _hover={onClick && { backgroundColor: 'gray.100' }}
      {...rest}
    >
      {children}
    </Tr>
  );
};

export const TableHeader = ({ children, ...rest }) => {
  return (
    <Th
      //as="th"
      lineHeight="tall"
      textAlign="left"
      //border="1px"
      borderColor="gray.200"
      px={2}
      py={1}
      fontFamily={''}
      fontWeight={''}
      textTransform={''}
      letterSpacing={''}
      fontSize={''}
      color={''}
      {...rest}
    >
      {children}
    </Th>
  );
};

export const TableData = ({ children, ...rest }) => {
  return (
    <Td
      //as="td"
      lineHeight="tall"
      textAlign="left"
      //border="1px"
      borderTop="1px"
      borderColor="gray.200"
      px={2}
      py={1}
      {...rest}
    >
      {children}
    </Td>
  );
};

export const TableHeaderIcon = ({ icon, ...props }) => (
  <Box
    display="inline-block"
    verticalAlign="text-top"
    my={1}
    mx={2}
    boxSize={2}
    as={icon}
    {...props}
  />
);
