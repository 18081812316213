import find from 'lodash/find';

import { useGetTipoEvento } from 'actions/enums';

const useTipoEvento = value => {
  const { data: tipos, status } = useGetTipoEvento();
  if (status === 'loading') return null;
  return find(tipos.data, ['value', value]) || null;
};

export default useTipoEvento;
