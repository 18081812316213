import React from 'react';
import {
  useNavigate,
  Link as RouterLink
} from 'react-router-dom';
import {
  Button,
  Stack,
  Menu,
  IconButton,
  MenuList,
  MenuButton,
  MenuDivider,
  useDisclosure
} from '@chakra-ui/react';
import {
  FaPlus,
  FaChevronDown,
  FaPen,
  FaFile,
  FaWrench,
  FaTrash,
  FaUser
} from 'react-icons/fa';

import {
  usePostAnuncianteAtivar,
  usePostAnuncianteDesativar,
  usePostAnuncianteCliente,
  useDeleteAnunciante,
  useDownloadAnuncianteContrato
} from 'actions/anunciantes';
import { ConfirmDialog, IconMenuItem } from 'components/ui';
import { useToastList } from 'customHooks';
import { prepareOutputDateValue } from 'utils/form';

import ModalContrato from './ModalContrato';

const AcoesButton = ({ anunciante }) => {
  const { id, nome, ativo, is_cliente } = anunciante;
  const history = useNavigate();
  const toastList = useToastList();

  const {
    isOpen: isOpenContrato,
    onClose: onCloseContrato,
    onOpen: onOpenContrato
  } = useDisclosure();
  const {
    isOpen: isOpenConfirmacaoVirarCliente,
    onClose: onCloseConfirmacaoVirarCliente,
    onOpen: onOpenConfirmacaoVirarCliente
  } = useDisclosure();
  const {
    isOpen: isOpenConfirmacaoRemover,
    onClose: onCloseConfirmacaoRemover,
    onOpen: onOpenConfirmacaoRemover
  } = useDisclosure();

  const [
    downloadContrato,
    { isFetching: isFetchingDownloadContrato }
  ] = useDownloadAnuncianteContrato(id);
  const {mutateAsync: ativar} = usePostAnuncianteAtivar(id);
  const {mutateAsync: desativar} = usePostAnuncianteDesativar(id);
  const {
    mutateAsync: virarCliente,
    status: statusVirarCliente 
  } = usePostAnuncianteCliente(id);
  const {mutateAsync: apagar, status: statusApagar } = useDeleteAnunciante(id);

  const criarContratoEAbrir = async values => {
    try {
      values.data = prepareOutputDateValue(values.data);
      await downloadContrato(values);
      onCloseContrato();
    } catch (er) {
      toastList({ messages: er.errors, status: 'error' });
    }
  };

  const ativarAnunciante = async () => {
    try {
      await ativar();
      toastList({ messages: ['Anunciante ativada'] });
    } catch (er) {
      toastList({ messages: er.errors, status: 'error' });
    }
  };

  const desativarAnunciante = async () => {
    try {
      await desativar();
      toastList({ messages: ['Anunciante desativada'] });
    } catch (er) {
      toastList({ messages: er.errors, status: 'error' });
    }
  };

  const tornarAnuncianteUmaCliente = async () => {
    try {
      await virarCliente();
      toastList({ messages: [`Agora ${nome} também é cliente`] });
      onCloseConfirmacaoVirarCliente();
      history(`/clientes/${id}`);
    } catch (er) {
      toastList({ messages: er.errors, status: 'error' });
    }
  };

  const apagarAnunciante = async () => {
    try {
      await apagar();
      history(`/anunciantes`);
    } catch (er) {
      toastList({ messages: er.errors, status: 'error' });
    }
  };

  return (
    <Stack isInline spacing={0} ml="auto">
      <Button
        size="sm"
        variant="outline"
        leftIcon={<FaPlus/>}
        roundedRight={0}
        as={RouterLink}
        to={`/itens/anunciante/${id}/form`}
      >
        Item
      </Button>
      <Menu placement="bottom-end">
        <MenuButton
          as={IconButton}
          size="sm"
          variant="outline"
          icon={<FaChevronDown/>}
          roundedLeft={0}
        />
        <MenuList minWidth="150px" >
          <IconMenuItem icon={FaPen} as={RouterLink} to={'form'}>
            Editar
          </IconMenuItem>
          <IconMenuItem icon={FaFile} onClick={onOpenContrato}>
            Contrato
          </IconMenuItem>
          {ativo ? (
            <IconMenuItem icon={FaWrench} onClick={desativarAnunciante}>
              Desativar
            </IconMenuItem>
          ) : (
            <IconMenuItem icon={FaWrench} onClick={ativarAnunciante}>
              Ativar
            </IconMenuItem>
          )}
          {is_cliente ? (
            <IconMenuItem icon={FaUser} as={RouterLink} to={`/clientes/${id}`}>
              Cliente
            </IconMenuItem>
          ) : (
            <IconMenuItem icon={FaPlus} onClick={onOpenConfirmacaoVirarCliente}>
              Cliente
            </IconMenuItem>
          )}
          <MenuDivider />
          <IconMenuItem icon={FaTrash} onClick={onOpenConfirmacaoRemover}>
            Remover
          </IconMenuItem>
        </MenuList>
      </Menu>
      <ModalContrato
        isOpen={isOpenContrato}
        onClose={onCloseContrato}
        isSubmitting={isFetchingDownloadContrato}
        onSubmit={criarContratoEAbrir}
      />
      <ConfirmDialog
        isLoading={statusVirarCliente === 'loading'}
        isOpen={isOpenConfirmacaoVirarCliente}
        onClose={onCloseConfirmacaoVirarCliente}
        onConfirm={tornarAnuncianteUmaCliente}
        zIndexOverlay="modal"
        title="Virar Cliente"
        confirmButtonText="Sim"
        cancelButtonText="Não"
      >
        Deseja que a anunciante <strong>{nome}</strong> se torne também uma
        cliente?
      </ConfirmDialog>
      <ConfirmDialog
        isLoading={statusApagar === 'loading'}
        isOpen={isOpenConfirmacaoRemover}
        onClose={onCloseConfirmacaoRemover}
        onConfirm={apagarAnunciante}
        zIndexOverlay="modal"
        title="Remover Anunciante"
        confirmButtonText="Sim"
        cancelButtonText="Não"
      >
        Deseja remover o anunciante <strong>{nome}</strong>?
      </ConfirmDialog>
    </Stack>
  );
};

export default AcoesButton;
