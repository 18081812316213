import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Box, Stack, Heading, Button } from '@chakra-ui/react';
import * as yup from 'yup';
import isEmpty from 'lodash/isEmpty';

import { useGetItem, usePostItem, usePutItem } from 'actions/itens';
import { useGetAnunciante } from 'actions/anunciantes';
import { useGetOptionsAnunciantes } from 'actions/options';
import {
  Form,
  FormGroupButton,
  FormFieldset,
  FormControlInput,
  FormControlInputDate,
  FormControlRadio,
  FormControlDropzone,
  FormControlSelect,
  FormControlTextarea,
  FormInputCurrency
} from 'components/form';
import { DisplayText } from 'components/ui';
import { useDocumentTitle } from 'customHooks';
import {
  prepareInputValues,
  prepareInputDateValue,
  prepareInputCurrencyValue,
  prepareOutputValues,
  prepareOutputDateValue,
  prepareOutputCurrencyValue
} from 'utils/form';

const defaultValues = {
  tipo_item: '',
  referencia: '',
  tamanho: '',
  marca: '',
  caracteristicas: '',
  url: null,
  anunciante: null,
  aluguel: '',
  caucao: '',
  data_entrada: ''
};

const validationSchema = yup.object().shape({
  tipo_item: yup
    .string()
    .required()
    .notOneOf(['X'], 'Tipo é um campo obrigatório')
    .label('Tipo'),
  anunciante: yup.object().nullable().required().label('Anunciante'),
  valor_aluguel: yup.string().required().label('Aluguel'),
  valor_caucao: yup.string().required().label('Caução'),
  data_entrada: yup
    .string()
    .label('Data de Entrada')
    .matches(/^\d{4}-\d{2}-\d{2}$/, {
      message: 'Data de Entrada deve corresponder ao formato DD/MM/YYYY',
      excludeEmptyString: true
    })
});

const Formulario = () => {
  const history = useNavigate();
  const { id, idAnunciante } = useParams();
  const { mutateAsync: criarItem, status: statusCriar } = usePostItem();
  const { mutateAsync: editarItem, status: statusEditar } = usePutItem(id);
  const { data: item, status } = useGetItem(id, false);
  const { data: anunciante } = useGetAnunciante(idAnunciante);
  const { data: anunciantes, status: statusAnunciantes } =
    useGetOptionsAnunciantes();

  const isLoadingAnunciantes = statusAnunciantes === 'loading';

  const isEditing = Boolean(id);
  useDocumentTitle(isEditing ? 'Editar Item' : 'Novo Item');

  if (isEditing && status === 'loading') return <DisplayText>Carregando...</DisplayText>;
  if (statusAnunciantes === 'loading') return <DisplayText>Carregando...</DisplayText>;

  let _inputValues = {};
  if (item) {
    _inputValues = prepareInputValues(item.data);
    if (
      item.data.url_thumbnail &&
      item.data.url_thumbnail !== '/static/img/item/default.png'
    ) {
      _inputValues.url = {
        preview: {
          preview: item.data.url_thumbnail,
          ...item.data.size
        },
        url: item.data.url
      };
    }
    if (item.data.anunciante) {
      _inputValues.anunciante = {
        value: item.data.anunciante,
        label: item.data.anunciante_nome
      };
    }
    _inputValues.valor_aluguel = prepareInputCurrencyValue(
      item.data.valor_aluguel
    );
    _inputValues.valor_caucao = prepareInputCurrencyValue(
      item.data.valor_caucao
    );
    _inputValues.data_entrada = prepareInputDateValue(item.data.data_entrada);
  }

  const inicialData = !isEditing ? defaultValues : _inputValues;

  if (anunciante) {
    inicialData.anunciante = {
      value: anunciante.data.id,
      label: anunciante.data.nome
    };
  }

  const onSubmit = async data => {

    const outputValues = prepareOutputValues(data, '');

    if (!isEmpty(outputValues.anunciante)) {
      outputValues.anunciante = outputValues.anunciante.value;
    }

    outputValues.data_entrada =
      prepareOutputDateValue(outputValues.data_entrada) || '';
    outputValues.valor_aluguel = prepareOutputCurrencyValue(
      outputValues.valor_aluguel
    );
    outputValues.valor_caucao = prepareOutputCurrencyValue(
      outputValues.valor_caucao
    );

    if (!outputValues.url) {
      outputValues.url = '';
    } else if (outputValues.url.name === '') {
      outputValues.url = null;
    } else if (outputValues.url.preview) {
      outputValues.url = null;
    }

    // outputValues.url = outputValues.url?outputValues.url:''

    const formData = new FormData();
    for (const [key, value] of Object.entries(outputValues)) {
      if (value !== null) {
        formData.append(key, value);
      }
    }

    const criarEditar = isEditing ? editarItem : criarItem;

    const { data: newData } = await criarEditar({ payload: formData });

    history(`/itens/${newData.id}`);
  };

  return (
    <Box p={4}>
      <Heading size="lg">{isEditing ? item?.data?.referencia : 'Cadastro de Item'}</Heading>
      <Form
        onSubmit={onSubmit}
        defaultValues={inicialData}
        validationSchema={validationSchema}
      >
        <Stack spacing={4} align="start">
          <FormFieldset>
            <FormControlRadio
              label="Tipo"
              name="tipo_item"
              isDisabled={isEditing}
              options={[
                { value: 'VESTIDO_LONGO', label: 'Vestido Longo' },
                { value: 'VESTIDO_CURTO', label: 'Vestido Curto' },
                { value: 'ACESSORIO', label: 'Acessório' }
              ]}
            />
            <FormControlInput
              label="Referência"
              name="referencia"
              width={100}
            />
            <FormControlInput label="Tamanho" name="tamanho" width={100} />
            <FormControlInput label="Marca" name="marca" width={100} />
            <FormControlTextarea
              label="Características"
              name="descricao"
              width={200}
              rows="3"
              style={{ height: 'auto' }}
            />
            <FormControlDropzone label="Foto" name="url" width={300} />
          </FormFieldset>
          <FormFieldset label="Anunciante">
            <FormControlSelect
              label="Anunciante"
              name="anunciante"
              isLoading={isLoadingAnunciantes}
              isDisabled={anunciante}
              options={
                !isLoadingAnunciantes &&
                anunciantes.data.map(({ id, nome }) => ({
                  value: id,
                  label: nome
                }))
              }
              maxW={300}
              selectFirst={false}
            />
            <FormControlInput
              label="Aluguel"
              leftAddon="R$"
              inputComponent={FormInputCurrency}
              name="valor_aluguel"
              width={100}
            />
            <FormControlInput
              label="Caução"
              leftAddon="R$"
              inputComponent={FormInputCurrency}
              name="valor_caucao"
              width={100}
            />
            <FormControlInputDate
              label="Data de Entrada"
              name="data_entrada"
              width={165}
            />
          </FormFieldset>
          <FormGroupButton>
            <Button
              size="sm"
              colorScheme="blue"
              type="submit"
              loadingText="Salvando"
              isLoading={statusEditar === 'loading' || statusCriar === 'loading'}
            >
              Salvar
            </Button>
          </FormGroupButton>
        </Stack>
      </Form>
    </Box>
  );
};

export default Formulario;
