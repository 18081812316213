import React from 'react';
import {
  ButtonGroup,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  FormControl,
  FormLabel,
  Stack
} from '@chakra-ui/react';
import * as yup from 'yup';
import { format } from 'date-fns';

import { Form, FormInput, FormInputDate } from 'components/form';
import { prepareInputDateValue } from 'utils/form';

const validationSchema = yup.object().shape({
  nacionalidade: yup.string().required().label('Nacionalidade'),
  data_nascimento: yup.string().matches(/^\d{4}-\d{2}-\d{2}$/, {
    message: 'Data do Contrato deve corresponder ao formato DD/MM/YYYY',
    excludeEmptyString: true
  })
});

const ModalContrato = ({ isOpen, onClose, onSubmit, isSubmitting }) => {
  const initRef = React.useRef();

  const defaultValues = {
    nacionalidade: 'brasileira',
    data: prepareInputDateValue(format(new Date(), 'yyyy-MM-dd'))
  };

  // TODO campo invalido vermelho e msg de erro
  return (
    <Modal
      closeOnOverlayClick={false}
      size="md"
      initialFocusRef={initRef}
      isOpen={isOpen}
      onClose={onClose}
    >
      <ModalOverlay />

      <ModalContent>
        <ModalHeader>Contrato</ModalHeader>
        <ModalCloseButton />
        <Form
          defaultValues={defaultValues}
          validationSchema={validationSchema}
          onSubmit={onSubmit}
          align="flex-end"
          spacing={0}
        >
          <ModalBody as={Stack} spacing={4} width="100%">
            <FormControl>
              <FormLabel fontSize="sm" htmlFor="nacionalidade">
                Nacionalidade
              </FormLabel>
              <FormInput name="nacionalidade" />
            </FormControl>
            <FormControl>
              <FormLabel fontSize="sm" htmlFor="data">
                Data do Contrato
              </FormLabel>
              <FormInputDate name="data" />
            </FormControl>
          </ModalBody>
          <ModalFooter>
            <ButtonGroup size="sm">
              <Button
                type="submit"
                loadingText="Gerando"
                colorScheme="blue"
                variant="solid"
                ref={initRef}
                isLoading={isSubmitting}
              >
                Gerar Contrato
              </Button>
            </ButtonGroup>
          </ModalFooter>
        </Form>
      </ModalContent>
    </Modal>
  );
};

export default ModalContrato;
