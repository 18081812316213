import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Box, Heading, Button } from '@chakra-ui/react';
import * as yup from 'yup';

import { useGetEmpresa, usePostEmpresa, usePutEmpresa } from 'actions/empresas';
import {
  Form,
  FormGroupButton,
  FormFieldset,
  FormControlInput
} from 'components/form';
import { DisplayText } from 'components/ui';
import { useDocumentTitle } from 'customHooks';

const validationSchema = yup.object().shape({
  nome: yup.string().required().label('Nome')
});

const Formulario = () => {
  const history = useNavigate();
  const { id } = useParams();
  const { data: empresa, status: statusEmpresa } = useGetEmpresa(id);
  const { mutateAsync: criarEmpresa, status: statusCriar } = usePostEmpresa();
  const { mutateAsync: editarEmpresa, status: statusEditar } = usePutEmpresa(id);

  const isEditing = Boolean(id);
  useDocumentTitle(isEditing ? 'Editar Empresa de Lavanderia' : 'Nova Empresa de Lavanderia');
  const isLoadingEmpresa = statusEmpresa === 'loading';

  const onSubmit = async data => {
    const criarEditar = isEditing ? editarEmpresa : criarEmpresa;
    await criarEditar(data);
    history(`/lavanderia/empresa`);
  };

  if (isEditing && isLoadingEmpresa) return <DisplayText>Carregando...</DisplayText>;

  return (
    <Box p={4}>
      <Heading size="lg">
        {isEditing ? 'Editar Empresa' : 'Cadastrar Empresa'}
      </Heading>
      <Form onSubmit={onSubmit} validationSchema={validationSchema}>
        <FormFieldset>
          <FormControlInput
            label="Nome"
            name="nome"
            maxW={400}
            defaultValue={empresa && empresa.data.nome}
          />
        </FormFieldset>
        <FormGroupButton>
          <Button
            size="sm"
            colorScheme="blue"
            type="submit"
            loadingText="Salvando"
            isLoading={statusCriar === 'loading' || statusEditar === 'loading'}
          >
            Salvar
          </Button>
        </FormGroupButton>
      </Form>
    </Box>
  );
};

export default Formulario;
