import React from 'react';
import { useFormContext, useFieldArray } from 'react-hook-form';
import { FaTimes, FaPlus } from 'react-icons/fa';
import {
  ButtonGroup,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Stack,
  Divider,
  IconButton
} from '@chakra-ui/react';
import { format } from 'date-fns';
import isEmpty from 'lodash/isEmpty';
import get from 'lodash/get';
import find from 'lodash/find';
import reduce from 'lodash/reduce';

import { useGetTipoPagamentoAnunciante } from 'actions/enums';
import {
  Form,
  FormTableRow,
  FormTableCell,
  FormInputGroup,
  FormSelect,
  FormInputCurrency,
  FormInputDate
} from 'components/form';
import {
  prepareInputCurrencyValue,
  prepareInputDateValue,
  prepareOutputCurrencyValue
} from 'utils/form';
import { formatCurrency } from 'utils/ui';

const FormContent = ({ aluguel }) => {
  const name = 'pagamentos_cliente';
  const { watch, control, formState: { errors } } = useFormContext();
  const { fields, append, remove } = useFieldArray({ control, name });
  const { data: tipos, status: statusTipos } = useGetTipoPagamentoAnunciante();

  const optionsTipos = tipos ? tipos.data : [];
  const isLoadingTipos = statusTipos === 'loading';

  const pagamentosCliente = watch(name);

  const total = reduce(
    pagamentosCliente,
    (result, field) => {
      const valor = parseFloat(prepareOutputCurrencyValue(field.valor)) || 0;
      return (result += valor);
    },
    0
  );

  return (
    <Stack>
      {fields.map((field, index) => {
        const pagamentoClienteTipo = get(aluguel, `${name}.${index}.tipo`);
        const nameTipo = `${name}.${index}.tipo`;
        const nameValor = `${name}.${index}.valor`;
        const nameData = `${name}.${index}.data`;

        return (
          <FormTableRow key={field.id}>
            <FormSelect
              name={nameTipo}
              isInvalid={!isEmpty(get(errors, nameTipo))}
              isLoading={isLoadingTipos}
              options={optionsTipos}
              selectFirst={false}
              initialValue={find(optionsTipos, ['value', pagamentoClienteTipo])}
            />
            <FormInputGroup
              name={nameValor}
              isInvalid={!isEmpty(get(errors, nameValor))}
              inputComponent={FormInputCurrency}
              leftAddon="R$"
              placeholder="Valor"
              width="100%"
              defaultValue={field.valor}
            />
            <FormInputDate
              name={nameData}
              isInvalid={!isEmpty(get(errors, nameData))}
              width="100%"
              defaultValue={field.data}
            />
            <IconButton
              size="sm"
              aria-label="Sair"
              variant="ghost"
              icon={<FaTimes/>}
              onClick={() => remove(index)}
            />
          </FormTableRow>
        );
      })}
      <FormTableRow bg="yellow.100" px={4} py={2}>
        <FormTableCell>Total Pago</FormTableCell>
        <FormTableCell>{formatCurrency(total)}</FormTableCell>
      </FormTableRow>
      <Button
        size="sm"
        colorScheme="green"
        leftIcon={<FaPlus/>}
        onClick={() =>
          append({
            valor: '0',
            data: prepareInputDateValue(format(new Date(), 'yyyy-MM-dd'))
          })
        }
      >
        Adicionar pagamento
      </Button>
    </Stack>
  );
};

const ModalContrato = ({
  aluguel,
  isOpen,
  onClose,
  onSubmit,
  isSubmitting
}) => {
  const {
    pagamentos_cliente,
    total_sem_lavanderia,
    total_cancelamento
  } = aluguel;
  const initRef = React.useRef();

  const inputData = {
    pagamentos_cliente: pagamentos_cliente
      ? pagamentos_cliente.map(pagamentoCliente => ({
          id: pagamentoCliente.id,
          // tipo: pagamentoCliente.tipo,
          valor: prepareInputCurrencyValue(pagamentoCliente.valor),
          data: prepareInputDateValue(pagamentoCliente.data)
        }))
      : [
          {
            data: prepareInputDateValue(format(new Date(), 'yyyy-MM-dd'))
          }
        ]
  };

  return (
    <Modal
      closeOnOverlayClick={false}
      size="xl"
      initialFocusRef={initRef}
      isOpen={isOpen}
      onClose={onClose}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Cancelar Aluguel</ModalHeader>
        <ModalCloseButton />
        <Form
          defaultValues={inputData}
          onSubmit={onSubmit}
          align="flex-end"
          spacing={0}
        >
          <ModalBody width="100%">
            <FormTableRow p={2}>
              <FormTableCell>Valor Aluguel (sem lavanderia)</FormTableCell>
              <FormTableCell>
                {formatCurrency(total_sem_lavanderia)}
              </FormTableCell>
            </FormTableRow>
            <FormTableRow bg="gray.100" p={2}>
              <FormTableCell>Valor Multa</FormTableCell>
              <FormTableCell>
                {formatCurrency(total_cancelamento)}
              </FormTableCell>
            </FormTableRow>
            <FormTableRow px={4} py={2} pt={0} mt={4}>
              <FormTableCell>Tipo</FormTableCell>
              <FormTableCell>Valor</FormTableCell>
              <FormTableCell>Data</FormTableCell>
            </FormTableRow>
            <Divider mt={0} />
            <FormContent aluguel={aluguel} />
          </ModalBody>
          <ModalFooter>
            <ButtonGroup size="sm">
              <Button
                type="submit"
                loadingText="Efetuando Cancelamento"
                colorScheme="blue"
                variant="solid"
                ref={initRef}
                isLoading={isSubmitting}
              >
                Efetuar Cancelamento
              </Button>
            </ButtonGroup>
          </ModalFooter>
        </Form>
      </ModalContent>
    </Modal>
  );
};

export default ModalContrato;
