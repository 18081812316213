import React from 'react';
import {
  useNavigate,
  Link as RouterLink
} from 'react-router-dom';
import {
  Menu,
  IconButton,
  MenuList,
  MenuButton,
  MenuDivider,
  useDisclosure
} from '@chakra-ui/react';
import {
  FaChevronDown,
  FaPen,
  FaFile,
  FaWrench,
  FaTrash
} from 'react-icons/fa';

import { usePostAluguelCancelar, useDeleteAluguel, useDownloadAluguelContrato } from 'actions/alugueis';
import { ConfirmDialog, IconMenuItem } from 'components/ui';
import { usePermissao, useToastList } from 'customHooks';
import { prepareOutputCurrencyValue, prepareOutputDateValue } from 'utils/form';

import ModalContrato from './ModalContrato';
import ModalCancelar from './ModalCancelar';

const AcoesButton = ({ aluguel }) => {
  const { id, status, has_lavanderias } = aluguel;
  const history = useNavigate();
  const toastList = useToastList();

  const {
    isOpen: isOpenContrato,
    onClose: onCloseContrato,
    onOpen: onOpenContrato
  } = useDisclosure();

  const {
    isOpen: isOpenCancelar,
    onClose: onCloseCancelar,
    onOpen: onOpenCancelar
  } = useDisclosure();

  const {
    isOpen: isOpenConfirmacaoRemover,
    onClose: onCloseConfirmacaoRemover,
    onOpen: onOpenConfirmacaoRemover
  } = useDisclosure();

  const [
    downloadContrato,
    { isFetching: isFetchingDownloadContrato }
  ] = useDownloadAluguelContrato(id);

  const {mutateAsync: cancelar, status: statusCancelar } = usePostAluguelCancelar(id);
  const {mutateAsync: apagar, status: statusApagar } = useDeleteAluguel(id);

  const isAndamento = status === 'ANDAMENTO';
  const isReservado = status === 'RESERVA';

  const criarContratoEAbrir = async values => {
    //try {
      const postData = {
        nacionalidade:values.nacionalidade,
        data: prepareOutputDateValue(values.data),
        estado_civil:values.estado_civil?.value,
      }
      await downloadContrato(postData);
      onCloseContrato();
    //} catch (er) {
    //  toastList({ messages: er.errors, status: 'error' });
    //}
  };

  const cancelarAluguel = async values => {
    //try {
      const outputData = {
        pagamentos_cliente:
          values.pagamentos_cliente &&
          values.pagamentos_cliente.map(pagamentoCliente => ({
            id: pagamentoCliente.id,
            tipo: pagamentoCliente.tipo ? pagamentoCliente.tipo.value : null,
            valor: prepareOutputCurrencyValue(pagamentoCliente.valor),
            data: prepareOutputDateValue(pagamentoCliente.data)
          }))
      };
      await cancelar(outputData);
      onCloseCancelar();
    //} catch (er) {
    //  toastList({ messages: er.errors, status: 'error' });
    //}
  };

  const apagarAluguel = async () => {
    try {
      await apagar();
      history(`/aluguel`);
    } catch (er) {
      toastList({ messages: er.errors, status: 'error' });
    }
  };

  const { value: podeApagar } = usePermissao("aluguel.delete_aluguel");

  return (
    <>
      <Menu placement="bottom-end">
        <MenuButton
          as={IconButton}
          size="sm"
          variant="outline"
          icon={<FaChevronDown/>}
        />
        <MenuList minWidth="150px">
          <IconMenuItem icon={FaPen} as={RouterLink} to={`form`}>
            Editar
          </IconMenuItem>
          { has_lavanderias && (isAndamento || isReservado) && (
            <IconMenuItem
              icon={FaPen}
              as={RouterLink}
              to={`lavanderia/form`}
            >
              Lavanderias
            </IconMenuItem>
          )}
          <IconMenuItem icon={FaFile} onClick={onOpenContrato}>
            Contrato
          </IconMenuItem>
          <MenuDivider />
          {isReservado && (
            <IconMenuItem icon={FaWrench} onClick={onOpenCancelar}>
              Cancelar
            </IconMenuItem>
          )}
          {podeApagar && (
            <IconMenuItem icon={FaTrash} onClick={onOpenConfirmacaoRemover}>
              Remover
            </IconMenuItem>
          )}
        </MenuList>
      </Menu>
      <ModalContrato
        isOpen={isOpenContrato}
        onClose={onCloseContrato}
        isSubmitting={isFetchingDownloadContrato}
        onSubmit={criarContratoEAbrir}
      />
      <ModalCancelar
        aluguel={aluguel}
        isOpen={isOpenCancelar}
        onClose={onCloseCancelar}
        isSubmitting={statusCancelar === 'loading'}
        onSubmit={cancelarAluguel}
      />
      <ConfirmDialog
        isLoading={statusApagar === 'loading'}
        isOpen={isOpenConfirmacaoRemover}
        onClose={onCloseConfirmacaoRemover}
        onConfirm={apagarAluguel}
        zIndexOverlay="modal"
        title="Remover Anunciante"
        confirmButtonText="Sim"
        cancelButtonText="Não"
      >
        Deseja remover esse Aluguel?
      </ConfirmDialog>
    </>
  );
};

export default AcoesButton;
