import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Text, Button, Link } from '@chakra-ui/react';

import { FormControl, FormLabel } from '../form';
import DisplayText from './DisplayText';

const InnerLink = ({ to, ...props }) => {
  return (
    <Link as={RouterLink} fontSize="sm" color="blue.500" to={to} {...props} />
  );
};

export const Control = ({ label, to, children, mb, ...rest }) => {
  const renderLabel = () => {
    if (to) {
      return (
        <FormLabel fontSize="sm" fontWeight="semibold">
          <InnerLink to={to} {...rest}>
            {label}
          </InnerLink>
        </FormLabel>
      );
    }

    return (
      <FormLabel fontSize="sm" fontWeight="semibold">
        {label}
      </FormLabel>
    );
  };

  return (
    <FormControl
      mb={mb}
      gridTemplateColumns={['1fr', '1fr', '150px auto 1fr']}
      {...rest}
    >
      {renderLabel()}
      {children}
    </FormControl>
  );
};

export const ControlText = ({ children }) => {
  if (children) return <Text fontSize="sm">{children}</Text>;
  return <ControlNull />;
};

export const ControlNull = () => {
  return <DisplayText fontSize="sm">nulo</DisplayText>;
};

export const ControlLink = ({ children, to, ...rest }) => {
  if (to) {
    return (
      <InnerLink to={to} {...rest}>
        {children}
      </InnerLink>
    );
  }

  return (
    <Button
      size="sm"
      colorScheme="blue"
      variant="link"
      width="fit-content"
      {...rest}
    >
      {children}
    </Button>
  );
};
