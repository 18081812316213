import { useQuery } from 'customHooks';

import { useGet } from 'customHooks/api';

export const QueryKeys = {
  OPTIONS_ANUNCIANTES: 'options/anunciantes',
  OPTIONS_CLIENTES: 'options/clientes',
  OPTIONS_VENDEDORAS: 'options/vendedoras',
  OPTIONS_ITENS: 'options/itens',
  OPTIONS_ITENS_LAVANDERIA: 'options/itens_lavanderia',
  OPTIONS_EMPRESAS: 'options/empresas',
  OPTIONS_TIPOS_ITEM: 'options/tipos_item',
  OPTIONS_TAMANHOS_ITEM: 'options/tamanhos_item',
  OPTIONS_VALORES_LIMITE_ITEM: 'options/valores_limite_item',
  OPTIONS_STATUS_ALUGUEL: 'options/status_aluguel',
  OPTIONS_STATUS_LAVANDERIA: 'options/status_lavanderia'
};

export const useGetOptionsAnunciantes = (condition = true) => {
  const getOptions = useGet('options/anunciantes');
  return useQuery({
    queryKey: [QueryKeys.OPTIONS_ANUNCIANTES],
    queryFn:getOptions,
    enabled: Boolean(condition)
  });
};

export const useGetOptionsClientes = (condition = true) => {
  const getOptions = useGet('options/clientes');
  return useQuery({
    queryKey: [QueryKeys.OPTIONS_CLIENTES],
    queryFn:getOptions,
    enabled: Boolean(condition)
  });
};

export const useGetOptionsVendedoras = (condition = true) => {
  const getOptions = useGet('options/vendedoras');
  return useQuery({
    queryKey: [QueryKeys.OPTIONS_VENDEDORAS],
    queryFn:getOptions,
    enabled: Boolean(condition)
  });
};

export const useGetOptionsItens = (condition = true) => {
  const getOptions = useGet('options/itens');
  return useQuery({
    queryKey: [QueryKeys.OPTIONS_ITENS],
    queryFn:getOptions,
    enabled: Boolean(condition)
  });
};

export const useGetOptionsItensLavanderia = (condition = true) => {
  const getOptions = useGet('options/itens_lavanderia');
  return useQuery({
    queryKey: [QueryKeys.OPTIONS_ITENS_LAVANDERIA],
    queryFn:getOptions,
    enabled: Boolean(condition)
  });
};

export const useGetOptionsEmpresas = (condition = true) => {
  const getOptions = useGet('options/empresas');
  return useQuery({
    queryKey: [QueryKeys.OPTIONS_EMPRESAS],
    queryFn:getOptions,
    enabled: Boolean(condition)
  });
};

export const useGetOptionsTiposItem = (condition = true) => {
  const getOptions = () =>
    new Promise(resolve =>
      resolve({
        data: [
          { value: '', label: 'Todos Tipos' },
          { value: 'VESTIDO_LONGO', label: 'Vestidos Longos' },
          { value: 'VESTIDO_CURTO', label: 'Vestidos Curtos' },
          { value: 'ACESSORIO', label: 'Acessórios' },
          { value: 'INATIVOS', label: 'Itens Inativos' }
        ]
      })
    );
  return useQuery({
    queryKey: [QueryKeys.OPTIONS_TIPOS_ITEM],
    queryFn:getOptions,
    enabled: Boolean(condition)
  });
};

export const useGetOptionsTamanhosItem = (condition = true) => {
  const getOptions = () =>
    new Promise(resolve =>
      resolve({
        data: [
          { value: '', label: 'Todos Tamanhos' },
          { value: '34', label: 'Tamanho 34' },
          { value: '36', label: 'Tamanho 36' },
          { value: '38', label: 'Tamanho 38' },
          { value: '40', label: 'Tamanho 40' },
          { value: '42', label: 'Tamanho 42' },
          { value: '44', label: 'Tamanho 44' },
          { value: '46', label: 'Tamanho 46' },
          { value: '48', label: 'Tamanho 48' },
          { value: '50', label: 'Tamanho 50' }
        ]
      })
    );
  return useQuery({
    queryKey: [QueryKeys.OPTIONS_TAMANHOS_ITEM],
    queryFn:getOptions,
    enabled: Boolean(condition)
  });
};

export const useGetOptionsValoresLimiteItem = (condition = true) => {
  const getOptions = () =>
    new Promise(resolve =>
      resolve({
        data: [
          { value: '', label: 'Todos Valores' },
          { value: '500', label: 'Até R$ 500,00' },
          { value: '750', label: 'Até R$ 750,00' },
          { value: '1000', label: 'Até R$ 1000,00' },
          { value: '1250', label: 'Até R$ 1250,00' },
          { value: '1500', label: 'Até R$ 1500,00' },
          { value: '2000', label: 'Até R$ 2000,00' },
          { value: '2500', label: 'Até R$ 2500,00' },
          { value: '3000', label: 'Até R$ 3000,00' },
          { value: '3500', label: 'Até R$ 3500,00' },
          { value: '4000', label: 'Até R$ 4000,00' },
          { value: '5000', label: 'Até R$ 5000,00' },
        ]
      })
    );
  return useQuery({
    queryKey: [QueryKeys.OPTIONS_VALORES_LIMITE_ITEM],
    queryFn:getOptions,
    enabled: Boolean(condition)
  });
};

export const useGetOptionsStatusAluguel = (condition = true) => {
  const getOptions = () =>
    new Promise(resolve =>
      resolve({
        data: [
          { value: '', label: 'Todos' },
          { value: 'reserva', label: 'Reserva' },
          { value: 'andamento', label: 'Andamento' },
          { value: 'finalizado', label: 'Finalizado' },
          { value: 'cancelado', label: 'Cancelado' }
        ]
      })
    );
  return useQuery({
    queryKey: [QueryKeys.OPTIONS_STATUS_ALUGUEL],
    queryFn:getOptions,
    enabled: Boolean(condition)
  });
};

export const useGetOptionsStatusLavanderia = (condition = true) => {
  const getOptions = () =>
    new Promise(resolve =>
      resolve({
        data: [
          { value: '', label: 'Todos' },
          { value: 'agendado', label: 'Agendado' },
          { value: 'andamento', label: 'Andamento' },
          { value: 'finalizado', label: 'Finalizado' }
        ]
      })
    );
  return useQuery({
    queryKey: [QueryKeys.OPTIONS_STATUS_LAVANDERIA],
    queryFn:getOptions,
    enabled: Boolean(condition)
  });
};
