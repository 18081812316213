import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Heading, Button } from '@chakra-ui/react';
import * as yup from 'yup';

import {
  useGetConfiguracoes,
  usePostConfiguracoes
} from 'actions/configuracoes';
import {
  Form,
  FormGroupButton,
  FormFieldset,
  FormControlInput,
  FormInputCurrency
} from 'components/form';
import { DisplayText } from 'components/ui';
import { useDocumentTitle } from 'customHooks';
import {
  prepareInputPercentValue,
  prepareInputCurrencyValue,
  prepareOutputPercentValue,
  prepareOutputCurrencyValue
} from 'utils/form';

const validationSchema = yup.object().shape({
  percentual_reserva: yup.string().required().label('Percentual Reserva'),
  percentual_anunciante: yup.string().required().label('Percentual Anunciante'),
  percentual_vendedora: yup.string().required().label('Percentual Vendedora'),
  lavagem_vc: yup.string().required().label('Lavagem Vestido Curto'),
  lavagem_vl: yup.string().required().label('Lavagem Vestido Longo')
});

const Formulario = () => {
  const history = useNavigate();
  const { data: configuracoes, status } = useGetConfiguracoes();
  const { mutateAsync: editar, status: statusEditar } = usePostConfiguracoes();
  useDocumentTitle('Editar Configurações');

  const isSubmitting = statusEditar === 'loading';

  const onSubmit = async data => {

    const {
      percentual_reserva,
      percentual_anunciante,
      percentual_vendedora,
      lavagem_vc,
      lavagem_vl
    } = data;
    const outputValues = {
      percentual_reserva: prepareOutputPercentValue(percentual_reserva),
      percentual_anunciante: prepareOutputPercentValue(percentual_anunciante),
      percentual_vendedora: prepareOutputPercentValue(percentual_vendedora),
      lavagem_vc: prepareOutputCurrencyValue(lavagem_vc),
      lavagem_vl: prepareOutputCurrencyValue(lavagem_vl)
    };

    await editar(outputValues);
    history('/configuracoes');

  };

  if (status === 'loading') return <DisplayText>Carregando...</DisplayText>;

  const inputValues = configuracoes ? {
    percentual_reserva: prepareInputPercentValue(configuracoes.data.percentual_reserva),
    percentual_anunciante: prepareInputPercentValue(configuracoes.data.percentual_anunciante),
    percentual_vendedora: prepareInputPercentValue(configuracoes.data.percentual_vendedora),
    lavagem_vc: prepareInputCurrencyValue(configuracoes.data.lavagem_vc),
    lavagem_vl: prepareInputCurrencyValue(configuracoes.data.lavagem_vl)
  } : {};

  return (
    <Box p={4}>
      <Heading size="lg">Configurações</Heading>
      <Form onSubmit={onSubmit} validationSchema={validationSchema} defaultValues={inputValues}>
        <FormFieldset>
          <FormControlInput
            label="Percentual Reserva"
            name="percentual_reserva"
            inputComponent={FormInputCurrency}
            rightAddon="%"
            maxW={100}
          />
          <FormControlInput
            label="Percentual Anunciante"
            name="percentual_anunciante"
            inputComponent={FormInputCurrency}
            rightAddon="%"
            maxW={100}
          />
          <FormControlInput
            label="Percentual Vendedora"
            name="percentual_vendedora"
            inputComponent={FormInputCurrency}
            rightAddon="%"
            maxW={100}
          />
          <FormControlInput
            label="Lavagem Vestido Curto"
            name="lavagem_vc"
            inputComponent={FormInputCurrency}
            leftAddon="R$"
            maxW={100}
          />
          <FormControlInput
            label="Lavagem Vestido Longo"
            name="lavagem_vl"
            inputComponent={FormInputCurrency}
            leftAddon="R$"
            maxW={100}
          />
        </FormFieldset>
        <FormGroupButton>
          <Button
            size="sm"
            colorScheme="blue"
            type="submit"
            loadingText="Salvando"
            isLoading={isSubmitting}
          >
            Salvar
          </Button>
        </FormGroupButton>
      </Form>
    </Box>
  );
};

export default Formulario;
