import { useMutation, useQueryClient } from '@tanstack/react-query';

import { usePost } from 'customHooks/api';

import { QueryKeys as AnunciantesQueryKeys } from './anunciantes';

export const usePostAluguelItensEmail = itemId => {
  const postEmail = usePost(`aluguel_itens/${itemId}/email`);
  return useMutation(postEmail);
};

export const usePostAluguelItensPagamentosAnunciante = (
  itemId,
  anuncianteId
) => {
  const queryClient = useQueryClient();
  const postPagamentos = usePost(
    `aluguel_itens/${itemId}/pagamentos_anunciante`
  );
  return useMutation(postPagamentos, {
    onSuccess: () => {
      queryClient.refetchQueries(
        anuncianteId && [
          AnunciantesQueryKeys.ANUNCIANTES_PENDENCIAS,
          { id: anuncianteId }
        ]
      );
    }
  });
};
