import React from 'react';
import { Stack } from '@chakra-ui/react';

const Card = props => (
  <Stack
    backgroundColor="#f5f5f5"
    align="start"
    boxShadow="inner"
    borderRadius="md"
    border="1px"
    px={6}
    py={4}
    minH="xs"
    style={{ borderColor: '#e3e3e3' }}
    {...props}
  />
);

export default Card;
