import React from 'react';
import { useQuery } from 'customHooks';

import { useGet } from 'customHooks/api';

export const QueryKeys = {
  CAIXA_REAL: 'caixa/real',
  CAIXA_REAL_CSV: 'caixa/real_csv',
  CAIXA_PREVISTO: 'caixa/previsto',
  CAIXA_VENDEDORAS: 'caixa/vendedoras',
  CAIXA_PENDENTE: 'caixa/pendente'
};

export const useGetCaixaReal = filtros => {
  const getCaixaReal = useGet('caixa/real');
  return useQuery([QueryKeys.CAIXA_REAL, filtros], getCaixaReal);
};

export const useGetCaixaRealCSV = (filtros, queryConfig) => {
  const getCaixaReal = useGet('caixa/real_csv', {
    responseType: 'blob',
  });
  return useQuery(
    [QueryKeys.CAIXA_REAL_CSV, filtros],
    getCaixaReal,
    queryConfig
  );
};

export const useGetCaixaPrevisto = filtros => {
  const getCaixaPrevisto = useGet('caixa/previsto');
  return useQuery([QueryKeys.CAIXA_PREVISTO, filtros], getCaixaPrevisto);
};

export const useGetCaixaVendedoras = filtros => {
  const getCaixaVendedoras = useGet('caixa/vendedoras');
  return useQuery([QueryKeys.CAIXA_VENDEDORAS, filtros], getCaixaVendedoras);
};

export const useGetCaixaPendente = filtros => {
  const getCaixaPendente = useGet('caixa/pendente');
  return useQuery([QueryKeys.CAIXA_PENDENTE, filtros], getCaixaPendente);
};

export const useDownloadCaixaRealCSV = filtros => {
  const [isFetching, setFetching] = React.useState(false);
  const { refetch: recuperarCaixaReal } = useGetCaixaRealCSV(filtros, {
    enabled: false
  });

  const fetch = async () => {
    try {
      setFetching(true);
      const res = await recuperarCaixaReal();

      const url = URL.createObjectURL(res.data.data);
      const element = document.createElement('a');
      setFetching(false);

      element.setAttribute('href', url);
      element.setAttribute('download', `caixa.csv`);
      element.style.display = 'none';
      document.body.appendChild(element);
      element.click();
      document.body.removeChild(element);
    } catch (er) {
      setFetching(false);
      throw er;
    }
  };

  return [fetch, { isFetching }];
};
