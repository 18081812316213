import React from 'react';
import { Text } from '@chakra-ui/react';

const DisplayText = ({ children, ...rest }) => {
  return (
    <Text color="gray.400" as="em" fontSize="sm" {...rest}>
      {children}
    </Text>
  );
};

export default DisplayText;
