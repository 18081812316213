import React from 'react';
import find from 'lodash/find';

import { useGetStatusAluguel } from 'actions/enums';

import { FormControlSelect } from 'components/form';

const FormControlSelectStatus = ({ isEditing, defaultValue, ...rest }) => {
  const { data, status } = useGetStatusAluguel(isEditing);
  const options = React.useMemo(() => data && data.data, [data]);
  const initialValue = React.useMemo(
    () => find(options, ['value', defaultValue]),
    [defaultValue, options]
  );

  if (!isEditing) return null;

  return (
    <FormControlSelect
      label="Status"
      name="status"
      isLoading={status === 'loading'}
      options={options}
      maxW={300}
      initialValue={initialValue}
      selectFirst={false}
      {...rest}
    />
  );
};

export default FormControlSelectStatus;
