import React from 'react';
//import ReactDOM from 'react-dom';
import { createRoot } from 'react-dom/client';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
 
 

import { ChakraProvider } from '@chakra-ui/react';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';

import 'setupYup';
import theme from 'theme';
import App from 'App';


const queryClient = new QueryClient({
   defaultOptions: {
     queries: {
       // query options
       refetchOnWindowFocus: false,
       //useErrorBoundary: true,
     },
     mutations: {
      //useErrorBoundary: true
     },
   },
 })

 const container = document.getElementById('root');

 const root = createRoot(container);

 root.render(
  // TODO: Descomentar após nova versão do react-select for lançada
  // https://github.com/JedWatson/react-select/issues/3720
  // <React.StrictMode>
  <QueryClientProvider client={queryClient}>
    <ChakraProvider theme={theme}>
      <App />
    </ChakraProvider>
    <ReactQueryDevtools initialIsOpen={false} />
  </QueryClientProvider>,
  // </React.StrictMode>
  //document.getElementById('root')
);
