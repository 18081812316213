import React from 'react';
import {
  useNavigate,
  Link as RouterLink
} from 'react-router-dom';
import {
  IconButton,
  Menu,
  MenuList,
  MenuButton,
  MenuDivider,
  useDisclosure
} from '@chakra-ui/react';
import { FaCaretDown, FaPen, FaWrench, FaTrash } from 'react-icons/fa';

import {
  usePostItemAtivar,
  usePostItemDesativar,
  useDeleteItem
} from 'actions/itens';
import { ConfirmDialog, IconMenuItem } from 'components/ui';
import { useToastList } from 'customHooks';

const AcoesButton = ({ data }) => {
  const { id, referencia, ativo } = data;
  const history = useNavigate();
  const toastList = useToastList();
  const { isOpen, onClose, onOpen } = useDisclosure();
  const { mutateAsync: ativar } = usePostItemAtivar(id);
  const { mutateAsync: desativar} = usePostItemDesativar(id);
  const { mutateAsync: apagar, status: statusApagar } = useDeleteItem(id);

  const ativarItem = async () => {
    try {
      await ativar();
      toastList({ messages: ['Anunciante ativada'] });
    } catch (er) {
      toastList({ messages: er.errors, status: 'error' });
    }
  };

  const desativarItem = async () => {
    try {
      await desativar();
      toastList({ messages: ['Item desativado'] });
    } catch (er) {
      toastList({ messages: er.errors, status: 'error' });
    }
  };

  const apagarItem = async () => {
    try {
      await apagar();
      history(`/itens`)
    } catch (er) {
      toastList({ messages: er.errors, status: 'error' });
    }
  };

  return (
    <>
      <Menu placement="bottom-end">
        <MenuButton as={IconButton} size="sm" icon={<FaCaretDown/>} />
        <MenuList minWidth="150px">
          <IconMenuItem icon={FaPen} as={RouterLink} to={`form`}>
            Editar
          </IconMenuItem>
          {ativo ? (
            <IconMenuItem icon={FaWrench} onClick={desativarItem}>
              Desativar
            </IconMenuItem>
          ) : (
            <IconMenuItem icon={FaWrench} onClick={ativarItem}>
              Ativar
            </IconMenuItem>
          )}
          <MenuDivider />
          <IconMenuItem icon={FaTrash} onClick={onOpen}>
            Remover
          </IconMenuItem>
        </MenuList>
      </Menu>
      <ConfirmDialog
        isLoading={statusApagar === 'loading'}
        isOpen={isOpen}
        onClose={onClose}
        onConfirm={apagarItem}
        zIndexOverlay="modal"
        title="Remover anunciante"
        confirmButtonText="Sim"
        cancelButtonText="Não"
      >
        Deseja remover o anunciante <strong>{referencia}</strong>
      </ConfirmDialog>
    </>
  );
};

export default AcoesButton;
