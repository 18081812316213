/* eslint-disable no-template-curly-in-string */

import { setLocale } from 'yup';

setLocale({
  mixed: {
    default: '${path} não é válido',
    required: '${path} é um campo obrigatório'
  },
  string: {
    matches: '${path} deve corresponder ao formato',
    min: '${path} deve ter no mínimo 8 caracteres'
  },
  number: {
    min: 'deve ser maior que ${min}'
  }
});