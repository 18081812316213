import { useEffect } from 'react';

const useDocumentTitle = (title, retainOnUnmount = false) => {

    useEffect(() => {
        document.title = title;
    }, [title]);

    useEffect(() => {
        return () => {
            if (!retainOnUnmount) {
                document.title = "My Closett";
            }
        };
    }, [retainOnUnmount]);
};

export default useDocumentTitle;
