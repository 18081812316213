import React from 'react';
import { Input as ChakraInput } from '@chakra-ui/react';
import { IMaskMixin } from 'react-imask';


const IMaskInput = IMaskMixin(({ inputRef, ...props }) => {
  return <ChakraInput
    ref={inputRef}
    {...props}
  />
});

const InputMask = React.forwardRef(
  ({ size = 'sm',
    maxW,
    width,
    roundedLeft,
    roundedRight,
    value,
    onChange,
    //defaultValue,
    ...rest
  }, ref) => {
    const ivalue = value?.toString() || "";
    const handleAccept = v => {
      return onChange && onChange(v);
    };
    return (
      <IMaskInput
        {...rest}
        onAccept={handleAccept}
        value={ivalue}
        inputRef={ref}
        size={size}
        maxW={maxW}
        width={width}
        roundedLeft={roundedLeft}
        roundedRight={roundedRight}
      />
    );
  });
export default InputMask;
