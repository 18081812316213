import React from 'react';
import { useParams } from 'react-router-dom';
import { Stack, Flex, Image, Box, Text, Link } from '@chakra-ui/react';
import { FaEnvelope, FaBell, FaSignal, FaFacebookF } from 'react-icons/fa';
import get from 'lodash/get';
import styled from '@emotion/styled';

import { useGetVendedora } from 'actions/vendedoras';
import { DisplayText } from 'components/ui';
import { useDocumentTitle } from 'customHooks';

import AcoesButton from './AcoesButton';
import Tabs from './Tabs';

const Banner = styled(Flex)`
  background-color: #f5f5f5;
  border-color: #e3e3e3;
`;

Banner.defaultProps = {
  direction: 'row',
  align: 'start',
  boxShadow: 'inner',
  borderRadius: 'md',
  border: '1px',
  padding: 4
};

const Avatar = styled(Image)`
  background-color: white;
  border-color: rgba(0, 0, 0, 0.2);
`;

Avatar.defaultProps = {
  p: 1,
  boxShadow: 'sm',
  border: '1px',
  boxSize: '100px',
  fallbackSrc: '/static/img/pessoa/default.jpg'
};

const IconText = ({ icon, to, children, ...rest }) => {
  if (!children) return null;

  return (
    <Flex align="center" {...rest}>
      <Box as={icon} mr={1} />
      {to ? (
        <Link color="blue.500" target="_blank" href={to}>
          {children}
        </Link>
      ) : (
        children
      )}
    </Flex>
  );
};

const Vendedora = () => {
  const { id } = useParams();
  const { data: vendedora, status, error } = useGetVendedora(id);
  useDocumentTitle(status === 'success' ? vendedora.data.nome:'Vendedora');

  if (status === 'loading') return <DisplayText>Carregando...</DisplayText>;

  if (status === 'error')
    return <DisplayText>{get(error, 'response.data.detail')}</DisplayText>;

  const { foto_url, nome, facebook, email, telefone, celular, user_username } = vendedora.data;

  return (
    <Stack spacing={4} p={4}>
      <Banner>
        <Avatar src={`${foto_url}`} alt={nome} />
        <Stack spacing={1} px={4} py={2}>
          <Text fontSize="2xl" fontWeight="bold">
            {nome}
          </Text>
          <Text color="gray.400" fontSize="sm">
            Vendedora: {user_username}
          </Text>
          <Stack spacing={4} isInline>
            <IconText
              fontSize="sm"
              icon={FaFacebookF}
              to={`https://www.facebook.com/${facebook}`}
            >
              {facebook}
            </IconText>
            <IconText fontSize="sm" icon={FaEnvelope}>
              {email}
            </IconText>
            <IconText fontSize="sm" icon={FaBell}>
              {telefone}
            </IconText>
            <IconText fontSize="sm" icon={FaSignal}>
              {celular}
            </IconText>
          </Stack>
        </Stack>
        <AcoesButton vendedora={vendedora.data} />
      </Banner>
      <Tabs vendedora={vendedora.data} />
    </Stack>
  );
};

export default Vendedora;
