import React from 'react';
import find from 'lodash/find';
import get from 'lodash/get';

import { useGetTipoEvento, useGetPapelEvento } from 'actions/enums';
import { FormLabel, FormSelect, FormControl } from 'components/form';

const FormControlEvento = ({ aluguel, ...rest }) => {
  const { data: tiposEvento, status: statusTiposEvento } = useGetTipoEvento();
  const {
    data: papeisEvento,
    status: statusPapeisEvento
  } = useGetPapelEvento();

  const isLoadingTiposEvento = statusTiposEvento === 'loading';
  const isLoadingPapeisEvento = statusPapeisEvento === 'loading';

  const optionsTiposEvento = React.useMemo(
    () => tiposEvento && tiposEvento.data,
    [tiposEvento]
  );
  const optionsPapeisEvento = React.useMemo(
    () => papeisEvento && papeisEvento.data,
    [papeisEvento]
  );

  const initialValueTipoEvento = React.useMemo(
    () => find(optionsTiposEvento, ['value', get(aluguel, 'data.tipo_evento')]),
    [aluguel, optionsTiposEvento]
  );

  const initialValuePapelEvento = React.useMemo(
    () =>
      find(optionsPapeisEvento, ['value', get(aluguel, 'data.papel_evento')]),
    [aluguel, optionsPapeisEvento]
  );

  return (
    <FormControl
      gridTemplateColumns={['1fr', '1fr', '200px 300px 300px']}
      {...rest}
    >
      <FormLabel>Evento</FormLabel>
      <FormSelect
        key="tipo_evento"
        name="tipo_evento"
        isLoading={isLoadingTiposEvento}
        options={optionsTiposEvento}
        selectFirst={false}
        initialValue={initialValueTipoEvento}
      />
      <FormSelect
        name="papel_evento"
        isLoading={isLoadingPapeisEvento}
        options={optionsPapeisEvento}
        selectFirst={false}
        initialValue={initialValuePapelEvento}
      />
    </FormControl>
  );
};

export default FormControlEvento;
