import React from 'react';
import {
  Link as RouterLink,
  useNavigate
} from 'react-router-dom';
import {
  Grid,
  Stack,
  Flex,
  Image,
  Input,
  IconButton,
  Box,
  Badge,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverHeader,
  PopoverBody,
  PopoverArrow,
  Portal,
  SimpleGrid
} from '@chakra-ui/react';
import { FaSearch, FaPlus, FaInfoCircle } from 'react-icons/fa';
import { stringify } from 'qs';
import get from 'lodash/get';
import find from 'lodash/find';

import {
  useGetOptionsTiposItem,
  useGetOptionsTamanhosItem,
  useGetOptionsValoresLimiteItem
} from 'actions/options';
import { useGetItens, useGetItensTotal } from 'actions/itens';
//import { DisplayText, Masonry, MasonryItem, Paginator } from 'components/ui';
import { DisplayText, MasonryItem, Paginator } from 'components/ui';
import { Select, InputDate } from 'components/form';
import { useSearchParams, useDocumentTitle } from 'customHooks';

const initialFiltros = {
  page: 1,
  tipo: '',
  tamanho: '',
  valor_limite: '',
  data: ''
};

const ItensTotal = () => {
  const { data } = useGetItensTotal();
  return (
    <Popover trigger="hover">
      <PopoverTrigger>
        <IconButton size="sm" rounded={0} icon={<FaInfoCircle />} />
      </PopoverTrigger>
      <Portal>
        <PopoverContent zIndex={4} maxW="200px" mx="auto">
          <PopoverArrow />
          <PopoverHeader>Total de Itens Ativos</PopoverHeader>
          <PopoverBody>
            <Grid templateColumns={['2fr 1fr']} gap={2}>
              <Box textAlign="right">My Closett</Box>
              <Box>
                <Badge>{data && data.data.myclosett}</Badge>
              </Box>
              <Box textAlign="right">Anunciantes</Box>
              <Box>
                <Badge>{data && data.data.anunciantes}</Badge>
              </Box>
            </Grid>
          </PopoverBody>
        </PopoverContent>
      </Portal>
    </Popover>
  );
};

const Filtros = ({ value, onChange, ...rest }) => {
  const { data: tipos, status: statusTipos } = useGetOptionsTiposItem();
  const { data: tamanhos, status: statusTamanho } = useGetOptionsTamanhosItem();
  const { data: valoresLimite, status: statusValoresLimite } =
    useGetOptionsValoresLimiteItem();
  const [termoPesquisa, setTermoPesquisa] = React.useState(value.search || '');

  React.useEffect(() => {
    setTermoPesquisa(value.search || '');
  }, [value]);

  const isLoadingTipos = statusTipos === 'loading';
  const isLoadingTamanhos = statusTamanho === 'loading';
  const isLoadingValoresLimite = statusValoresLimite === 'loading';

  return (
    <Grid
      gap={2}
      templateColumns={[
        '100%',
        '100%',
        '40px repeat(3, 115px) 165px 1fr',
        '40px repeat(5, 1fr)'
      ]}
      {...rest}
    >
      <ItensTotal />
      <Select
        size="sm"
        value={
          !isLoadingTipos && find(tipos.data, tipo => tipo.value === value.tipo)
        }
        isLoading={isLoadingTipos}
        options={!isLoadingTipos && tipos.data}
        onChange={tipo => {
          onChange({ tipo: tipo.value });
        }}
        isClearable={false}
      />
      <Select
        size="sm"
        value={
          !isLoadingTamanhos &&
          find(tamanhos.data, tamanho => tamanho.value === value.tamanho)
        }
        isLoading={isLoadingTamanhos}
        options={!isLoadingTamanhos && tamanhos.data}
        onChange={tamanho => {
          onChange({ tamanho: tamanho.value });
        }}
        isClearable={false}
      />
      <Select
        size="sm"
        value={
          !isLoadingValoresLimite &&
          find(
            valoresLimite.data,
            valorLimite => valorLimite.value === value.valor_limite
          )
        }
        isLoading={isLoadingValoresLimite}
        options={!isLoadingValoresLimite && valoresLimite.data}
        onChange={valorLimite => {
          onChange({ valor_limite: valorLimite.value });
        }}
        isClearable={false}
      />
      <InputDate
        value={value.data}
        onChange={e => {
          if (e==='') return
          if (e) {
            const data = e;
            if (data) onChange({ data });
          } else onChange({ data: null });
        }}
      />
      <Flex
        minW={['100%', '100%', 'initial']}
        maxW={['100%', '100%', '300px']}
        justifySelf="end"
        as="form"
        noValidate
        onSubmit={e => {
          e.preventDefault();
          onChange({ search: termoPesquisa, page:1 });
        }}
      >
        <Input
          size="sm"
          placeholder="Pesquisar"
          roundedRight={0}
          value={termoPesquisa}
          onChange={e => setTermoPesquisa(e.target.value)}
        />
        <IconButton type="submit" size="sm" rounded={0} icon={<FaSearch />} />
        <IconButton
          as={RouterLink}
          to={`form`}
          size="sm"
          roundedLeft={0}
          icon={<FaPlus />}
        />
      </Flex>
    </Grid>
  );
};

const MasonryItens = ({ filtros, onPaginate }) => {
  const { page } = filtros;
  const { status, data: clientes, error, hasNext } = useGetItens(filtros);

  if (status === 'loading') return <DisplayText>Carregando...</DisplayText>;

  if (status === 'error') {
    return <DisplayText>{get(error, 'response.data.detail')}</DisplayText>;
  }

  const { results } = clientes.data;

  if (results.length === 0) {
    return <DisplayText>Nenhuma informação disponível</DisplayText>;
  }

  return (
    <Stack spacing={4}>
      <SimpleGrid columns={4} spacing={10}>
      {results.map(item => (
          <MasonryItem
            key={item.id}
            as={RouterLink}
            to={`/itens/${item.id}`}
            p={2}
            h={"300px"}
          >
            <Image
              htmlWidth={item.size.width}
              htmlHeight={item.size.height}
              ignoreFallback
              src={item.url_thumbnail}
              objectFit='contain'
            />
          </MasonryItem>))}
      </SimpleGrid>
      {/* 
      <Masonry>
        {results.map(item => (
          <MasonryItem
            key={item.id}
            as={RouterLink}
            to={`/itens/${item.id}`}
            p={2}
          >
            <Image
              htmlWidth={item.size.width}
              htmlHeight={item.size.height}
              ignoreFallback
              alignSelf="flex-start"
              src={item.url_thumbnail}
            />
          </MasonryItem>
        ))}
      </Masonry>
      */}
      <Paginator page={page} onPaginate={onPaginate} hasNext={hasNext} />
    </Stack>
  );
};

const Lista = () => {
  const searchParams = useSearchParams(initialFiltros);
  useDocumentTitle('Itens');

  const [filtros, setFiltrosState] = React.useState(searchParams);
  const history = useNavigate();

  const setFiltros = React.useCallback(
    newFiltros => {
      const filtros = { ...searchParams, ...newFiltros };
      history(`?${stringify(filtros)}`, { replace: true });
    },
    [setFiltrosState, searchParams]
  );

  React.useEffect(() => {
    setFiltrosState(searchParams);
  }, [searchParams]);

  return (
    <Stack spacing={4} p={4}>
      <Filtros
        value={filtros}
        onChange={newFiltros =>
          //setFiltros(prev => ({ ...prev, ...newFiltros }))
          setFiltros({...newFiltros, page:1})
        }
      />
      <MasonryItens
        filtros={filtros}
        onPaginate={page =>
          //setFiltros(prev => ({ ...prev, page }))
          setFiltros({page})
        }
      />
    </Stack>
  );
};

export default Lista;
