import React from 'react';
import { Tabs, Tab, TabList, TabPanel, TabPanels } from '@chakra-ui/react';

import TabContentAlugueis from './TabContentAlugueis';
import TabContentSobre from './TabContentSobre';

const AnuncianteTabs = ({ vendedora }) => {
  const { id } = vendedora;

  return (
    <Tabs variant="enclosed" size="sm">
      <TabList>
        <Tab>Aluguéis</Tab>
        <Tab>Sobre</Tab>
      </TabList>
      <TabPanels pt={6} outline="none">
        <TabPanel outline="none" p={0}>
          <TabContentAlugueis id={id} />
        </TabPanel>
        <TabPanel outline="none">
          <TabContentSobre vendedora={vendedora} />
        </TabPanel>
      </TabPanels>
    </Tabs>
  );
};

export default AnuncianteTabs;
