import React from 'react';
import { useFormContext, useFieldArray } from 'react-hook-form';
import { Box, Stack, Button, IconButton, Divider } from '@chakra-ui/react';
import { FaTimes, FaPlus } from 'react-icons/fa';
import { format } from 'date-fns';
import isEmpty from 'lodash/isEmpty';
import get from 'lodash/get';
import find from 'lodash/find';

import { useGetTipoPagamentoClientes } from 'actions/enums';
import {
  FormTableRow,
  FormTableCell,
  FormControl,
  FormLabel,
  FormInputGroup,
  FormSelect,
  FormInputCurrency,
  FormInputDate
} from 'components/form';
import { prepareInputDateValue } from 'utils/form';
import { formatCurrency } from 'utils/ui';

const FormTableRowPagamento = ({
  name,
  field,
  index,
  onRemove,
  tipos,
  isLoadingTipos,
  defaultValueTipo,
  ...rest
}) => {
  const { formState: { errors } } = useFormContext();
  const nameTipo = `${name}.${index}.tipo`;
  const nameValor = `${name}.${index}.valor`;
  const nameData = `${name}.${index}..data`;

  const initialValueTipo = React.useMemo(
    () => find(tipos, ['value', defaultValueTipo]),
    [defaultValueTipo, tipos]
  );

  return (
    <FormTableRow {...rest}>
      <FormSelect
        name={nameTipo}
        isInvalid={!isEmpty(get(errors, nameTipo))}
        isLoading={isLoadingTipos}
        options={tipos}
        selectFirst={false}
        initialValue={initialValueTipo}
      />
      <FormInputGroup
        name={nameValor}
        isInvalid={!isEmpty(get(errors, nameValor))}
        inputComponent={FormInputCurrency}
        leftAddon="R$"
        placeholder="Valor"
        width="100%"
        defaultValue={field.valor}
      />
      <FormInputDate
        name={nameData}
        isInvalid={!isEmpty(get(errors, nameData))}
        width="100%"
        defaultValue={field.data}
      />
      <IconButton
        size="sm"
        aria-label="Sair"
        variant="ghost"
        icon={<FaTimes/>}
        onClick={() => onRemove(index)}
      />
    </FormTableRow>
  );
};

const FormControlPagamento = ({ name, aluguel, total, mb, mr }) => {
  const { data: tipos, status: statusTipos } = useGetTipoPagamentoClientes();
  const { control } = useFormContext();
  const { fields, append, remove } = useFieldArray({ control, name });

  const optionsTipos = tipos ? tipos.data : [];

  return (
    <FormControl
      mb={mb}
      mr={mr}
      gridTemplateColumns={['1fr', '1fr', '200px 600px']}
      alignItems="start"
    >
      <FormLabel>Pagamento</FormLabel>
      <Box>
        <FormTableRow px={4} py={2} pt={0}>
          <FormTableCell>Tipo</FormTableCell>
          <FormTableCell>Valor</FormTableCell>
          <FormTableCell>Data</FormTableCell>
        </FormTableRow>
        <Divider mt={0} />
        <Stack>
          {fields.map((field, index) => {
            return (
              <FormTableRowPagamento
                key={field.id}
                name={name}
                field={field}
                index={index}
                onRemove={remove}
                isLoadingTipos={statusTipos === 'loading'}
                tipos={optionsTipos}
                defaultValueTipo={get(
                  aluguel,
                  `data.pagamentos_cliente.${index}.tipo`
                )}
              />
            );
          })}
          <Button
            size="sm"
            colorScheme="blue"
            leftIcon={<FaPlus/>}
            onClick={() =>
              append({
                valor: '',
                data: prepareInputDateValue(format(new Date(), 'yyyy-MM-dd'))
              })
            }
          >
            Adicionar pagamento
          </Button>
          <FormTableRow bg="green.100" px={4} py={2}>
            <FormTableCell>Total Pago</FormTableCell>
            <FormTableCell>{formatCurrency(total)}</FormTableCell>
          </FormTableRow>
          
        </Stack>
      </Box>
    </FormControl>
  );
};

export default FormControlPagamento;
