import React from 'react';
import get from 'lodash/get';

import { useGetUfs } from 'actions/ufs';
import { FormLabel, FormInput, FormSelect, FormControl } from 'components/form';

const FormControlLocal = ({ aluguel, ...rest }) => {
  const { data: ufs, status: statusUfs } = useGetUfs();

  const initialValueUf = React.useMemo(() => {
    const uf = get(aluguel, 'data.uf');
    return uf && { value: uf, label: uf };
  }, [aluguel]);

  return (
    <FormControl
      gridTemplateColumns={['1fr', '1fr', '200px 300px 300px 150px']}
      {...rest}
    >
      <FormLabel>Local</FormLabel>
      <FormInput
        name="local_evento"
        placeholder="Local"
        defaultValue={get(aluguel, 'data.local_evento')}
      />
      <FormInput
        name="cidade"
        placeholder="Cidade"
        defaultValue={get(aluguel, 'data.cidade')}
      />
      <FormSelect
        name="uf"
        isLoading={statusUfs === 'loading'}
        options={ufs && ufs.data.map(uf => ({ value: uf, label: uf }))}
        initialValue={initialValueUf}
      />
    </FormControl>
  );
};

export default FormControlLocal;
