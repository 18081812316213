import React, { useRef } from 'react';
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  Button
} from '@chakra-ui/react';

const ConfirmDialog = ({
  isOpen,
  isLoading,
  onClose,
  onConfirm,
  title,
  children,
  closeOnOverlayClick,
  closeOnEsc,
  confirmButtonText,
  cancelButtonText,
  zIndexOverlay
}) => {
  const cancelRef = useRef();

  return (
    <AlertDialog
      isOpen={isOpen}
      leastDestructiveRef={cancelRef}
      onClose={onClose}
      closeOnOverlayClick={closeOnOverlayClick}
      closeOnEsc={closeOnEsc}
    >
      <AlertDialogOverlay zIndex={zIndexOverlay} />
      <AlertDialogContent>
        <AlertDialogHeader fontSize="lg" fontWeight="bold">
          {title}
        </AlertDialogHeader>

        <AlertDialogBody>{children}</AlertDialogBody>

        <AlertDialogFooter>
          <Button
            size="sm"
            colorScheme="blue"
            onClick={onConfirm}
            isLoading={isLoading}
          >
            {confirmButtonText}
          </Button>
          <Button
            size="sm"
            ref={cancelRef}
            variant="ghost"
            ml={3}
            onClick={onClose}
          >
            {cancelButtonText}
          </Button>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  );
};

ConfirmDialog.defaultProps = {
  closeOnOverlayClick: false,
  closeOnEsc: false,
  title: 'Atenção',
  confirmButtonText: 'Confirmar',
  cancelButtonText: 'Cancelar'
};

export default ConfirmDialog;
