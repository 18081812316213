import React from 'react';
import { useParams } from 'react-router-dom';
import {
  Flex,
  SimpleGrid,
  Stack,
  Heading,
  Divider,
  ButtonGroup,
  Button,
  useDisclosure
} from '@chakra-ui/react';
import styled from '@emotion/styled';
import get from 'lodash/get';
import join from 'lodash/join';
import isEmpty from 'lodash/isEmpty';

import { useGetAluguel, useDownloadAluguelContrato } from 'actions/alugueis';
import {
  Control,
  ControlText,
  ControlLink,
  ControlNull,
  DisplayText,
  StatusAluguel,
  StatusLavanderia
} from 'components/ui';
import {
  usePapelEvento,
  useTipoEvento,
  useTipoPagamentoCliente
} from 'customHooks/ui';
import { useDocumentTitle, usePermissao } from 'customHooks';
import { formatDate, formatCurrency } from 'utils/ui';

import AcoesButton from './AcoesButton';
import ModalEfetuarRetirada from './ModalEfetuarRetirada';
import ModalEfetuarDevolucao from './ModalEfetuarDevolucao';

const Card = styled(Stack)`
  background-color: #f5f5f5;
  border-color: #e3e3e3;
`;

Card.defaultProps = {
  align: 'start',
  boxShadow: 'inner',
  borderRadius: 'md',
  border: '1px',
  px: 6,
  py: 4,
  minH: '2xs'
};

const CardAluguel = ({ value, permission}) => {
  const [downloadContrato] =  useDownloadAluguelContrato(value.id)
  const {
    status,
    cliente,
    cliente_nome,
    vendedora,
    vendedora_nome,
    has_contrato,
    data_reserva
  } = value;
  return (
    <Card>
      <Flex justify="space-between" w="100%">
        <Stack isInline align="center">
          <Heading as="h3" size="lg">
            Aluguel
          </Heading>
          <Heading as="h4" size="md" color="gray.500">
            {value.id}
          </Heading>
        </Stack>
        {permission && <AcoesButton aluguel={value} />}
      </Flex>
      <Divider w="100%" />
      <Control label="Status">
        <StatusAluguel justifySelf="flex-start" value={status} />
      </Control>
      <Control label="Cliente">
        <ControlLink to={`/clientes/${cliente}`}>{cliente_nome}</ControlLink>
      </Control>
      <Control label="Vendedora">
        <ControlLink to={`/vendedoras/${vendedora}`}>
          {vendedora_nome}
        </ControlLink>
      </Control>
      <Control label="Contrato">
        {has_contrato ? (
          <ControlLink onClick={() => {downloadContrato()}}>Visualizar</ControlLink>
        ) : (
          <ControlText>—</ControlText>
        )}
      </Control>
      <Control label="Reserva">
        {data_reserva ? (
          <ControlText>{formatDate(data_reserva)}</ControlText>
        ) : (
          <ControlNull />
        )}
      </Control>
    </Card>
  );
};

const ControlEvento = ({ value }) => {
  const { papel_evento, tipo_evento } = value;
  const papel = usePapelEvento(papel_evento);
  const tipo = useTipoEvento(tipo_evento);

  const evento = [];
  if (papel) evento.push(papel.label);
  if (tipo) evento.push(tipo.label);

  if (isEmpty(evento)) return null;

  return <ControlText>{join(evento, ', ')}</ControlText>;
};

const CardDatas = ({ value }) => {
  const {
    data_retirada,
    data_evento,
    papel_evento,
    tipo_evento,
    local_evento,
    cidade,
    uf,
    data_devolucao,
    observacao
  } = value;

  const evento = [];
  if (papel_evento) evento.push(papel_evento);
  if (tipo_evento) evento.push(tipo_evento);

  const localizacao = [];
  if (local_evento) localizacao.push(local_evento);
  if (cidade) localizacao.push(cidade);
  if (uf) localizacao.push(uf);

  return (
    <Card>
      <Heading as="h3" size="lg">
        Datas
      </Heading>
      <Divider w="100%" />
      <Control label="Retirada">
        {data_retirada ? (
          <ControlText>{formatDate(data_retirada)}</ControlText>
        ) : (
          <ControlNull />
        )}
      </Control>
      <Control label="Evento" alignItems="start">
        <Stack spacing={0}>
          {data_evento && <ControlText>{formatDate(data_evento)}</ControlText>}
          <ControlEvento value={value} />
          {!isEmpty(localizacao) && (
            <ControlText>{join(localizacao, ', ')}</ControlText>
          )}
        </Stack>
      </Control>
      <Control label="Devolução">
        {data_devolucao ? (
          <ControlText>{formatDate(data_devolucao)}</ControlText>
        ) : (
          <ControlNull />
        )}
      </Control>
      <Control label="Observações" alignItems="start">
        <ControlText>{observacao}</ControlText>
      </Control>
    </Card>
  );
};

const CardItens = ({ value, total }) => {
  return (
    <Card>
      <Heading as="h3" size="lg">
        Itens
      </Heading>
      <Divider w="100%" />
      {value.map(item => {
        const aluguelItemReferencia = get(item, 'aluguel_item_lavanderia');

        return (
          <React.Fragment key={item.id}>
            <Control label={item.item_referencia} to={`/itens/${item.item}`}>
              <ControlText>{formatCurrency(item.valor_negociado)}</ControlText>
            </Control>
            {aluguelItemReferencia && (
              <Control
                label={`Lav. ${item.item_referencia}`}
                to={`/lavanderia/${aluguelItemReferencia.id}`}
              >
                <ControlText>
                  {formatCurrency(aluguelItemReferencia.valor)}
                </ControlText>
                <StatusLavanderia justifySelf="flex-start" value={aluguelItemReferencia.status} />
              </Control>
            )}
          </React.Fragment>
        );
      })}
      <Control label="TOTAL">
        <ControlText>{formatCurrency(total)}</ControlText>
      </Control>
    </Card>
  );
};

const ControlPagamento = ({ value, ...rest }) => {
  const tipo = useTipoPagamentoCliente(value.tipo);

  return (
    <Control label={tipo.label} {...rest}>
      <ControlText>{formatCurrency(value.valor)}</ControlText>
      <ControlText>{formatDate(value.data)}</ControlText>
    </Control>
  );
};

const CardPagamentos = ({ value, total }) => {
  return (
    <Card>
      <Heading as="h3" size="lg">
        Pagamentos
      </Heading>
      <Divider w="100%" />
      {value.map(item => (
        <ControlPagamento key={item.id} value={item} />
      ))}
      <Control label="TOTAL">
        <ControlText>{formatCurrency(total)}</ControlText>
      </Control>
    </Card>
  );
};

const Aluguel = () => {

  const { id } = useParams();
  const { data: aluguel, status, error } = useGetAluguel(id);
  const { isLoading, value } = usePermissao('aluguel.change_aluguel');

  const {
    isOpen: isOpenRetirar,
    onOpen: onOpenRetirar,
    onClose: onCloseRetirar
  } = useDisclosure();

  const {
    isOpen: isOpenDevolver,
    onOpen: onOpenDevolver,
    onClose: onCloseDevolver
  } = useDisclosure();

  useDocumentTitle(`Aluguel ${id}`);

  if (status === 'loading' || isLoading) return <DisplayText>Carregando...</DisplayText>;

  if (status === 'error')
    return <DisplayText>{get(error, 'response.data.detail')}</DisplayText>;

  const podeRetirar = aluguel.data.status === 'RESERVA';
  const podeDevolver = aluguel.data.status === 'ANDAMENTO';
  const totalItens = parseFloat(aluguel.data.total_itens);
  const totalPagamentosCliente = parseFloat(
    aluguel.data.total_pagamentos_cliente
  );

  return (
    <Stack p={4} spacing={6}>
      <SimpleGrid columns={[1, 1, 2, 2]} spacing={6}>
        <CardAluguel permission={value} value={aluguel.data} />
        <CardDatas value={aluguel.data} />
        <CardItens
          value={aluguel.data.aluguel_itens}
          total={aluguel.data.total_itens}
        />
        <CardPagamentos
          value={aluguel.data.pagamentos_cliente}
          total={aluguel.data.total_pagamentos_cliente}
        />
      </SimpleGrid>
      {(podeRetirar || podeDevolver) && value && (
        <ButtonGroup
          display="flex"
          w="100%"
          size="sm"
          justifyContent="flex-end"
        >
          {podeRetirar && (
            <Button colorScheme="green" onClick={onOpenRetirar}>
              Efetuar Retirada
            </Button>
          )}
          {podeDevolver && (
            <Button colorScheme="green" onClick={onOpenDevolver}>
              Efetuar Devolução
            </Button>
          )}
        </ButtonGroup>
      )}
      <ModalEfetuarRetirada
        isOpen={isOpenRetirar}
        onClose={onCloseRetirar}
        aluguelId={aluguel.data.id}
        totalPagamentosCliente={totalPagamentosCliente}
        valorAluguel={totalItens}
      />
      <ModalEfetuarDevolucao
        isOpen={isOpenDevolver}
        onClose={onCloseDevolver}
        aluguelId={aluguel.data.id}
      />
    </Stack>
  );
};

export default Aluguel;
