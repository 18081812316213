import { extendTheme } from '@chakra-ui/react';

const theme = extendTheme({
  components: {
    Popover: {
      baseStyle: {
        popper: {
          maxWidth: 'unset',
          width: 'unset'
        }
      }
    }
  }
});

export default theme;
