import React from 'react';
import { useFormContext } from 'react-hook-form';
import isEmpty from 'lodash/isEmpty';

import { FormLabel, FormInputDate, FormControl } from 'components/form';
import { prepareInputDateValue } from 'utils/form';

const FormControlPeriodo = ({ aluguel, ...rest }) => {
  const { formState: { errors } } = useFormContext();

  return (
    <FormControl
      gridTemplateColumns={['1fr', '1fr', '200px 150px 150px']}
      {...rest}
    >
      <FormLabel>Período</FormLabel>
      <FormInputDate
        name="data_retirada"
        placeholder="Data da Retirada"
        isInvalid={!isEmpty(errors['data_retirada'])}
        defaultValue={prepareInputDateValue(aluguel, 'data.data_retirada')}
        width={'165px'}
      />
      <FormInputDate
        name="data_devolucao"
        placeholder="Data da Devolução"
        isInvalid={!isEmpty(errors['data_devolucao'])}
        defaultValue={prepareInputDateValue(aluguel, 'data.data_devolucao')}
        width={'165px'}
      />
    </FormControl>
  );
};

export default FormControlPeriodo;
