import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Text } from '@chakra-ui/react';

import { useGetCaixaPendente } from 'actions/caixa';
import {
  DisplayText,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableHeader,
  TableHeaderIcon,
  TableData
} from 'components/ui';
import { useDocumentTitle } from 'customHooks';
import { formatCurrency, formatDate } from 'utils/ui';
import { FaCalendarAlt, FaUser, FaArrowUp } from 'react-icons/fa';

const TabContentPendente = ({ filtros }) => {
  const history = useNavigate();
  const { data, status } = useGetCaixaPendente(filtros);
  useDocumentTitle('Fluxo de Caixa (Pendente)');

  if (status === 'loading') return <DisplayText>Carregando...</DisplayText>;

  const { total, itens } = data.data;

  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableHeader>
            <TableHeaderIcon icon={FaCalendarAlt} />
            Data Retirada <Text as="small">(mais antigas)</Text>
          </TableHeader>
          <TableHeader>
            <TableHeaderIcon icon={FaUser} />
            Anunciante
          </TableHeader>
          <TableHeader>
            <TableHeaderIcon icon={FaArrowUp} />
            Total Pendente
          </TableHeader>
        </TableRow>
      </TableHead>
      <TableBody>
        <TableRow fontWeight="bold" bg="gray.100">
          <TableData>—</TableData>
          <TableData>Total</TableData>
          <TableData color="red.500">{formatCurrency(total.valor)}</TableData>
        </TableRow>
        {itens.map(item => (
          <TableRow
            key={item.id}
            onClick={() => history(`anunciantes/${item.id}`)}
          >
            <TableData>{formatDate(item.data)}</TableData>
            <TableData>{item.nome}</TableData>
            <TableData color="red.500">{formatCurrency(item.valor)}</TableData>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
};

export default TabContentPendente;
