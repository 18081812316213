import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Box, Heading, Button } from '@chakra-ui/react';
import * as yup from 'yup';

import {
  useGetVendedora,
  usePostVendedora,
  usePutVendedora
} from 'actions/vendedoras';
import { useGetUfs } from 'actions/ufs';
import {
  Form,
  FormGroupButton,
  FormFieldset,
  FormControl,
  FormLabel,
  FormInput,
  FormInputMask,
  FormInputPhone,
  FormControlInput,
  FormControlInputMask,
  FormControlInputDate,
  FormControlSelect
} from 'components/form';
import { DisplayText } from 'components/ui';
import { useDocumentTitle } from 'customHooks';
import {
  prepareInputValues,
  prepareInputDateValue,
  prepareOutputValues,
  prepareOutputDateValue
} from 'utils/form';
import isEmpty from 'lodash/isEmpty';

const validationSchema = yup.object().shape({
  nome: yup.string().required().label('Nome'),
  cpf: yup.string().matches(/^\d{3}\.\d{3}\.\d{3}-\d{2}$/, {
    message: 'CPF deve corresponder ao formato 999.999.999-99',
    excludeEmptyString: true
  }),
  data_nascimento: yup.string().matches(/^\d{4}-\d{2}-\d{2}$/, {
    message: 'Data de Nascimento deve corresponder ao formato DD/MM/YYYY',
    excludeEmptyString: true
  }),
  telefone: yup.string().matches(/^\(\d{2}\)\s\d{4}-\d{4}$/, {
    message: 'Fixo deve corresponder ao formato (99) 9999-9999',
    excludeEmptyString: true
  }),
  celular: yup.string().matches(/^\(\d{2}\)\s\d{5}-\d{4}$/, {
    message: 'Celular deve corresponder ao formato (99) 99999-9999',
    excludeEmptyString: true
  }),
  uf: yup.object().nullable().required().label('UF'),
  cep: yup.string().matches(/^\d{5}-\d{3}$/, {
    message: 'CEP deve corresponder ao formato 99999-999',
    excludeEmptyString: true
  })
});

const FormControlDocumentos = ({ mb, mr, ...rest }) => {
  return (
    <FormControl
      mb={mb}
      mr={mr}
      gridTemplateColumns={['1fr', '1fr', '200px 200px 200px']}
    >
      <FormLabel>Documentos</FormLabel>
      <FormInput name="rg" placeholder="RG" {...rest} />
      <FormInputMask
        name="cpf"
        placeholder="CPF"
        mask="000.000.000-00"
        {...rest}
      />
    </FormControl>
  );
};

const FormControlTelefones = ({ mb, mr }) => {
  return (
    <FormControl
      mb={mb}
      mr={mr}
      gridTemplateColumns={['1fr', '1fr', '200px 200px 200px']}
    >
      <FormLabel>Telefones</FormLabel>
      <FormInputPhone name="telefone" placeholder="Fixo" />
      <FormInputPhone
        name="celular"
        placeholder="Celular"
      />
    </FormControl>
  );
};

const Formulario = () => {
  const history = useNavigate();
  const { id } = useParams();
  const { data: vendedora, status: statusVendedora } = useGetVendedora(id);
  const { data: ufs, status: statusUfs } = useGetUfs();
  const { mutateAsync: criarVendedora, status: statusCriar } = usePostVendedora();
  const { mutateAsync: editarVendedora, status: statusEditar } = usePutVendedora(id);

  const isEditing = Boolean(id);
  useDocumentTitle(isEditing ? 'Editar Vendedora' : 'Nova Vendedora')
  const isLoadingVendedora = statusVendedora === 'loading';
  const isLoadingUfs = statusUfs === 'loading';

  const onSubmit = async data => {


    const outputValues = prepareOutputValues(data);

    if (!isEmpty(outputValues.data_nascimento)) {
      outputValues.data_nascimento = prepareOutputDateValue(
        outputValues.data_nascimento
      );
    }

    if (!isEmpty(outputValues.uf)) outputValues.uf = outputValues.uf.value;

    let criarEditar = criarVendedora;
    if (isEditing) criarEditar = editarVendedora;

    const { data: newData } = await criarEditar(outputValues);

    history(`/vendedoras/${newData.id}`);

  };

  if (isEditing && isLoadingVendedora) return <DisplayText>Carregando...</DisplayText>;

  let defaultValues = prepareInputValues(vendedora ? vendedora.data : {});

  if (defaultValues.data_nascimento) {
    defaultValues.data_nascimento = prepareInputDateValue(
      defaultValues.data_nascimento
    );
  }

  if (defaultValues.uf) {
    defaultValues = {
      ...defaultValues,
      uf: { label: defaultValues.uf, value: defaultValues.uf }
    };
  }

  return (
    <Box p={4}>
      <Heading size="lg">
        {isEditing ? 'Vendedora' : 'Cadastro de Vendedora'}
      </Heading>
      <Form
        defaultValues={defaultValues}
        onSubmit={onSubmit}
        validationSchema={validationSchema}
      >
        <FormFieldset>
          <FormControlInput label="Nome" name="nome" maxW={400} />
          <FormControlDocumentos />
          <FormControlInputDate
            label="Data de Nascimento"
            name="data_nascimento"
            maxW={200}
          />
          <FormControlTelefones />
          <FormControlInput label="E-mail" name="email" maxW={200} />
          <FormControlInput
            label="Facebook"
            leftAddon="facebook.com/"
            name="facebook"
            maxW={200}
          />
          <FormControlInput label="Profissão" name="profissao" maxW={400} />
          <FormControlInput label="Empresa" name="empresa" maxW={400} />
        </FormFieldset>
        <FormFieldset label="Endereço">
          <FormControlInput label="Logradouro" name="logradouro" maxW={400} />
          <FormControlInput label="Bairro" name="bairro" maxW={400} />
          <FormControlInput label="Cidade" name="cidade" maxW={400} />
          <FormControlSelect
            label="UF"
            name="uf"
            isLoading={isLoadingUfs}
            options={
              !isLoadingUfs && ufs.data.map(uf => ({ value: uf, label: uf }))
            }
            maxW={150}
          />
          <FormControlInputMask
            label="CEP"
            name="cep"
            maxW={200}
            mask="00000-000"
          />
        </FormFieldset>
        <FormGroupButton>
          <Button
            size="sm"
            colorScheme="blue"
            type="submit"
            loadingText="Salvando"
            isLoading={statusCriar === 'loading' || statusEditar === 'loading'}
          >
            Salvar
          </Button>
        </FormGroupButton>
      </Form>
    </Box>
  );
};

export default Formulario;
