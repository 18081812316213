import React from 'react';


//import { Input as ChakraInput } from '@chakra-ui/react';

import DatePicker from 'react-date-picker';
import { format, parseISO, isValid } from 'date-fns';
//https://github.com/chakra-ui/chakra-ui/issues/580
import 'react-date-picker/dist/DatePicker.css';
import 'react-calendar/dist/Calendar.css';
import './datepicker.css'

import { Box } from '@chakra-ui/react';

/*
import InputMask from './InputMask';

// https://github.com/chakra-ui/chakra-ui/issues/580

const InputDate = React.forwardRef(
    //({masked, value, onChange, ...props}, ref) => {
    ({value, onChange, ...props}, ref) => {
        

        const convertDateToIso = (date) => {
            if (!date) return null;
            if (!date.match(/^\d{2}\/\d{2}\/\d{4}$/)) return '';
            const [day, month, year] = date.split('/');
            return `${year}-${month}-${day}`;
        }

        const convertIsoToDate = (date) => {
            if (!date) return null;
            if (!date.match(/^\d{4}-\d{2}-\d{2}$/)) return null;
            const [year, month, day] = date.split('-');
            return `${day}/${month}/${year}`;
        }

        const dateOnChange = (rawValue) => {
            const dataValue = convertDateToIso(rawValue);
            if (dataValue) {onChange(dataValue)}
        }

        const format = (date) => {
            if (!date) {
                return '';
            }
            let day = date.getDate();
            let month = date.getMonth() + 1;
            let year = date.getFullYear();
            if (day < 10) day = "0" + day;
            if (month < 10) month = "0" + month;

            return `${day}/${month}/${year}`;
        };

        const parse = (str) => {
            if (str instanceof Date) {
                return str;
            }

            if (!str) {
                return null;
            }
            
            const dayMonthYear = str.split('/');
            return new Date(dayMonthYear[2], dayMonthYear[1] - 1, dayMonthYear[0]);
            
        };

        /*
        if (!masked) {
            return <ChakraInput size="sm" type="date" ref={ref} value={value} onChange={e=>{onChange(e.target.value)}} {...props} />;
        }
        * /

        const dataValue = convertIsoToDate(value);

        return (
            <InputMask
                mask={Date}
                pattern={'d{/}`m{/}`Y'}
                format={format}
                parse={parse}
                overwrite={true}
                onChange={dateOnChange}
                value={dataValue}
                {...props}
                ref={ref}
            />
        );
    });
//export default InputDate;
*/
const InputDate = React.forwardRef(
    //({masked, value, onChange, ...props}, ref) => {
    ({value, onChange, isInvalid, ...props}, ref) => {

        const dateValue = parseISO(value)

        const [date, setDate] = React.useState(isValid(dateValue) ? dateValue : null)

        const setDateValue = (value) => {
            setDate(value)
            onChange(value?format(value, 'yyy-MM-dd'):'')
        }

        return <Box w="100%" {...props}><DatePicker isValid={!isInvalid} yearPlaceholder="    " monthPlaceholder="  " dayPlaceholder="  " openCalendarOnFocus={false} locale="pt-BR" value={date} onChange={setDateValue} inputRef={ref} ></DatePicker></Box>
})
export default InputDate;


