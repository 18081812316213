import React from 'react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useQuery } from 'customHooks';

import { useGet, usePost, usePut, useDelete } from 'customHooks/api';
import { usePaginatedPrefetchQuery } from 'customHooks';

export const QueryKeys = {
  ITEM: 'item',
  ITENS: 'itens',
  ITENS_TOTAL: 'itens/totais',
  ITENS_RESERVAS: 'itens/reservas',
  ITENS_HISTORICO: 'itens/historico',
  ITENS_LAVANDERIAS: 'itens/lavanderias'
};

export const useGetItem = (id, refetch=true) => {
  const getItem = useGet('itens/{id}');
  const queryParams = {
    queryKey: [QueryKeys.ITEM, { id }],
    queryFn:getItem,
    enabled: !!id
  }
  if (!refetch) {
    queryParams.refetchOnReconnect = false
    queryParams.refetchOnWindowFocus = false
  }
  return useQuery(queryParams);
};

export const useGetItens = filtros => {
  const queryKey = React.useMemo(() => [QueryKeys.ITENS, filtros], [filtros]);
  const getClientes = useGet('itens');
  return usePaginatedPrefetchQuery(queryKey, getClientes);
};

export const useGetItensTotal = () => {
  const queryKey = React.useMemo(() => [QueryKeys.ITENS_TOTAL], []);
  const getClientes = useGet('itens/total');
  return useQuery(queryKey, getClientes);
};

export const useGetItensReservas = params => {
  const queryKey = React.useMemo(() => [QueryKeys.ITENS_RESERVAS, params], [
    params
  ]);
  const getReservas = useGet('itens/{id}/reservas');
  return usePaginatedPrefetchQuery(queryKey, getReservas);
};

export const useGetItensHistorico = params => {
  const queryKey = React.useMemo(() => [QueryKeys.ITENS_HISTORICO, params], [
    params
  ]);
  const getHistorico = useGet('itens/{id}/historico');
  return usePaginatedPrefetchQuery(queryKey, getHistorico);
};

export const useGetItensLavanderias = params => {
  const queryKey = React.useMemo(() => [QueryKeys.ITENS_LAVANDERIAS, params], [
    params
  ]);
  const getLavanderias = useGet('itens/{id}/lavanderias');
  return usePaginatedPrefetchQuery(queryKey, getLavanderias);
};

export function usePostItem() {
  const postItem = usePost('itens', {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  });
  return useMutation(postItem);
}

export function usePostItemAtivar(id) {
  const queryClient = useQueryClient();
  const postAtivar = usePost(`itens/${id}/ativar`);
  return useMutation(postAtivar, {
    onSuccess: data => {
      queryClient.setQueryData([QueryKeys.ITEM, { id: `${id}` }], data);
    }
  });
}

export function usePostItemDesativar(id) {
  const queryClient = useQueryClient();
  const postDesativar = usePost(`itens/${id}/desativar`);
  return useMutation(postDesativar, {
    onSuccess: data => {
      queryClient.setQueryData([QueryKeys.ITEM, { id: `${id}` }], data);
    }
  });
}

export const usePutItem = id => {
  const queryClient = useQueryClient();
  const putAnunciante = usePut(`itens/${id}`);
  return useMutation(putAnunciante, {
    onSuccess: data =>
    queryClient.setQueryData([QueryKeys.ITEM, { id: `${id}` }], data)
  });
};

export const useDeleteItem = id => {
  const deleteItem = useDelete(`itens/${id}`);
  return useMutation(deleteItem);
};
