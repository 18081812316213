import React from 'react';
import InputMask from './InputMask';

const InputCurrency = React.forwardRef(
  (props, ref) => {

    return (
      <InputMask
        mask={Number}
        scale={2}
        padFractionalZeros={true}
        //pattern={'d{/}`m{/}`Y'}
        //format={format}
        //parse={parse}
        //overwrite={true}
        //onChange={dateOnChange}
        //value={dataValue}
        {...props}
        ref={ref}
      />
    );
  });

export default InputCurrency;
