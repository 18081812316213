import React from 'react';
import template from 'lodash/template';
import templateSettings from 'lodash/templateSettings';
import omit from 'lodash/omit';
import get from 'lodash/get';

import { useGlobalContext } from 'globalContext';

import instance from './instance';

const extractUrlParams = url => {
  return (url.match(/{[^}]+}/g) || []).map(x=>x.replace(/{|}/g, ''));
};

const setParamsIntoURL = (url, params) => {
  templateSettings.interpolate = /{([\s\S]+?)}/g;
  const compiledURL = template(url);
  return compiledURL(params);
};

const prepareURLAndParams = (url, params) => {
  const urlReady = setParamsIntoURL(url, params);
  const urlParams = extractUrlParams(url);
  return [urlReady, omit(params, urlParams)];
};

export function useGet(url, config) {
  const { setToken } = useGlobalContext();

  return React.useCallback(
    async ({queryKey}) => {
      try {
        const [preparedUrl, preparedParams] = prepareURLAndParams(url, queryKey[1]);
        const response = await instance.get(preparedUrl, {
          params: preparedParams,
          ...config
        });
        return response;
      } catch (er) {
        if (get(er, 'response.status') === 401) setToken(null);
        throw er;
      }
    },
    [config, url, setToken]
  );
}

export function usePost(url, config) {
  const { setToken } = useGlobalContext();

  return async params => {
    try {
      const [preparedUrl, preparedParams] = prepareURLAndParams(url, params);
      const response = await instance.post(
        preparedUrl,
        preparedParams.payload || preparedParams,
        config
      );
      return response;
    } catch (er) {
      if (get(er, 'response.status') === 401) setToken(null);
      throw er;
    }
  };
}

export function usePut(url, config) {
  const { setToken } = useGlobalContext();

  return async params => {
    try {
      const [preparedUrl, preparedParams] = prepareURLAndParams(url, params);
      const response = await instance.put(
        preparedUrl,
        preparedParams.payload || preparedParams,
        config
      );
      return response;
    } catch (er) {
      if (get(er, 'response.status') === 401) setToken(null);
      throw er;
    }
  };
}

export function useDelete(url, config) {
  const { setToken } = useGlobalContext();

  return async params => {
    try {
      const [preparedUrl] = prepareURLAndParams(url, params);
      const response = await instance.delete(preparedUrl, config);
      return response;
    } catch (er) {
      if (get(er, 'response.status') === 401) setToken(null);
      throw er;
    }
  };
}
