import React from 'react';
import { Routes, Route } from 'react-router-dom';

import PermissaoRoute from 'components/ui/PermissaoRoute';

import Vendedora from './Vendedora';
import Formulario from './Formulario';
import FormularioTrocarSenha from './FormularioTrocarSenha';
import Lista from './Lista';

const Anunciantes = () => {

  // TODO: permissão form trocar senha
  return (
    <Routes>
      <Route path={`:id`} element={<PermissaoRoute permissao="vendedora.view_vendedora"><Vendedora/></PermissaoRoute>} />
      <Route path={`form`} element={<PermissaoRoute permissao="vendedora.add_vendedora"><Formulario/></PermissaoRoute>} />
      <Route path={`:id/form`} element={<PermissaoRoute permissao="vendedora.change_vendedora"><Formulario/></PermissaoRoute>} />
      <Route path={`:id/senha/form`} element={<PermissaoRoute permissao="vendedora.change_vendedora"><FormularioTrocarSenha/></PermissaoRoute>} />
      <Route index element={<PermissaoRoute permissao="vendedora.view_vendedora"><Lista/></PermissaoRoute>} />

    </Routes>
  );
};

export default Anunciantes;
