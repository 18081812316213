import React from 'react';
import { useParams } from 'react-router-dom';
import {
  Flex,
  SimpleGrid,
  Stack,
  Heading,
  Divider,
  ButtonGroup,
  Button,
  useDisclosure
} from '@chakra-ui/react';
import styled from '@emotion/styled';
import get from 'lodash/get';

import {
  useGetLavanderia,
  usePostLavanderiaIr,
  usePostLavanderiaVoltar
} from 'actions/lavanderias';
import {
  Control,
  ControlText,
  ControlLink,
  ControlNull,
  DisplayText,
  StatusLavanderia
} from 'components/ui';
import { useDocumentTitle, usePermissao, useToastList } from 'customHooks';
import { formatDate, formatCurrency } from 'utils/ui';

import AcoesButton from './AcoesButton';
import ModalEscolherEmpresa from './ModalEscolherEmpresa';
import isEmpty from 'lodash/isEmpty';

const Card = styled(Stack)`
  background-color: #f5f5f5;
  border-color: #e3e3e3;
`;

Card.defaultProps = {
  align: 'start',
  boxShadow: 'inner',
  borderRadius: 'md',
  border: '1px',
  px: 6,
  py: 4,
  minH: 'xs'
};

const CardLavanderia = ({ value, permission }) => {
  const {
    id,
    item,
    item_referencia,
    empresa_nome,
    valor,
    data_inicio,
    data_fim,
    status,
    aluguel
  } = value;
  return (
    <Card>
      <Flex justify="space-between" w="100%">
        <Stack isInline align="center">
          <Heading as="h3" size="lg">
            Lavanderia
          </Heading>
        </Stack>
        {permission && <AcoesButton lavanderia={value} />}
      </Flex>
      <Divider w="100%" />
      <Control label="ID">
        <ControlText>{id}</ControlText>
      </Control>
      <Control label="Status">
        <StatusLavanderia value={status} />
      </Control>
      <Control label="Item">
        {!isEmpty(item_referencia) ? (
          <ControlLink to={`/itens/${item}`}>{item_referencia}</ControlLink>
        ) : (
          <ControlNull />
        )}
      </Control>
      <Control label="Empresa">
        <ControlText>{empresa_nome}</ControlText>
      </Control>
      <Control label="Valor Lavanderia">
        <ControlText>{formatCurrency(valor)}</ControlText>
      </Control>
      <Control label="Data de Ida">
        <ControlText>{formatDate(data_inicio)}</ControlText>
      </Control>
      <Control label="Data de Volta">
        <ControlText>{formatDate(data_fim)}</ControlText>
      </Control>
      {aluguel && <Control label="Aluguel"><ControlLink to={`/aluguel/${aluguel}`}>{aluguel}</ControlLink></Control>}
    </Card>
  );
};

const Lavanderia = () => {
  const { id } = useParams();
  useDocumentTitle(`Lavanderia ${id}`);
  const {isLoading, value} = usePermissao('lavanderia.change_lavanderia')
  const toastList = useToastList();
  const { data: lavanderia, status, error } = useGetLavanderia(id);
  const {mutateAsync: irParaLavanderia, status: statusIr } = usePostLavanderiaIr(id);
  const {
    mutateAsync: voltarDaLavanderia,
    status: statusVoltar
  } = usePostLavanderiaVoltar(id);

  const {
    isOpen: isOpenEscolherEmpresa,
    onOpen: onOpenEscolherEmpresa,
    onClose: onCloseEscolherEmpresa
  } = useDisclosure();

  if (status === 'loading' || isLoading) return <DisplayText>Carregando...</DisplayText>;

  if (status === 'error')
    return <DisplayText>{get(error, 'response.data.detail')}</DisplayText>;

  const isAgendado = lavanderia.data.status === 'AGENDADO';
  const isAndamento = lavanderia.data.status === 'ANDAMENTO';
  const { empresa } = lavanderia.data;

  const handleIr = async _empresa => {
    try {
      await irParaLavanderia({ empresa: _empresa });
      onCloseEscolherEmpresa();
    } catch (er) {
      toastList({ messages: er.errors, status: 'error' });
    }
  };

  const handleVoltar = async () => {
    try {
      await voltarDaLavanderia();
    } catch (er) {
      toastList({ messages: er.errors, status: 'error' });
    }
  };

  return (
    <Stack p={4} spacing={6}>
      <SimpleGrid columns={[1, 1, 2, 2]} spacing={6}>
        <CardLavanderia permission={value} value={lavanderia.data} />
      </SimpleGrid>
      {(isAgendado || isAndamento) && value && (
        <ButtonGroup
          display="flex"
          w="100%"
          size="sm"
          justifyContent="flex-end"
        >
          {isAgendado && (
            <Button
              isLoading={statusIr === 'loading'}
              loadingText="Indo para Lavanderia"
              colorScheme="green"
              onClick={() =>
                empresa ? handleIr(empresa) : onOpenEscolherEmpresa()
              }
            >
              Ir para Lavanderia
            </Button>
          )}
          {isAndamento && (
            <Button
              isLoading={statusVoltar === 'loading'}
              loadingText="Voltando da Lavanderia"
              colorScheme="green"
              onClick={handleVoltar}
            >
              Voltar da Lavanderia
            </Button>
          )}
        </ButtonGroup>
      )}
      <ModalEscolherEmpresa
        isOpen={isOpenEscolherEmpresa}
        onClose={onCloseEscolherEmpresa}
        onSubmit={handleIr}
        isSubmitting={statusIr === 'loading'}
      />
    </Stack>
  );
};

export default Lavanderia;
