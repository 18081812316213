import { useReducer, useCallback, useEffect } from 'react';

const Types = {
  STORE_VALUE: 'useLocalStorage/STORE_VALUE',
  STORE_VALUE_FROM_FUNCTION: 'useLocalStorage/STORE_VALUE_FROM_FUNCTION'
};

const initialState = {
  isFunctionValue: false,
  functionValue: null,
  storedValue: null
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case Types.STORE_VALUE: {
      return {
        ...state,
        storedValue:
          action.payload instanceof Function
            ? state.storedValue
            : action.payload,
        isFunctionValue: action.payload instanceof Function,
        functionValue:
          action.payload instanceof Function ? action.payload : null
      };
    }
    case Types.STORE_VALUE_FROM_FUNCTION: {
      return {
        ...state,
        storedValue: state.functionValue(state.storedValue),
        isFunctionValue: false,
        functionValue: null
      };
    }
    default:
      return state;
  }
};

const getInitialValue = (key, initialValue) => {
  try {
    const storedValue = JSON.parse(window.localStorage.getItem(key));
    return storedValue || initialValue;
  } catch (error) {
    return initialValue;
  }
};

const useLocalStorage = (key, initialStoredValue) => {
  const [state, dispatch] = useReducer(reducer, {
    ...initialState,
    storedValue: getInitialValue(key, initialStoredValue)
  });

  const setValue = useCallback(
    value => {
      window.localStorage.setItem(key, JSON.stringify(value));
      dispatch({ type: Types.STORE_VALUE, payload: value });
    },
    [key]
  );

  useEffect(() => {
    if (state.isFunctionValue && state.functionValue) {
      dispatch({ type: Types.STORE_VALUE_FROM_FUNCTION });
    }
  }, [key, state]);

  return [state.storedValue, setValue];
};

export default useLocalStorage;
