import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Box } from '@chakra-ui/react';
import { FaShoppingCart, FaArrowUp, FaArrowDown } from 'react-icons/fa';

import { useGetCaixaPrevisto } from 'actions/caixa';
import {
  DisplayText,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableHeader,
  TableData,
  StatusAluguel
} from 'components/ui';
import { useDocumentTitle } from 'customHooks';
import { formatCurrency } from 'utils/ui';

const TableHeaderIcon = ({ icon, ...props }) => (
  <Box display="inline-block" ml={1} as={icon} {...props} />
);

const TabContentPrevisto = ({ filtros }) => {
  const { data, status } = useGetCaixaPrevisto(filtros);
  const history = useNavigate();
  useDocumentTitle('Fluxo de Caixa (Previsto)');

  if (status === 'loading') return <DisplayText>Carregando...</DisplayText>;

  const { total, itens } = data.data;  

  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableHeader>#</TableHeader>
          <TableHeader>Status</TableHeader>
          <TableHeader>
            Cliente
            <TableHeaderIcon icon={FaArrowDown} />
          </TableHeader>
          <TableHeader>
            Vendedora
            <TableHeaderIcon icon={FaArrowUp} />
          </TableHeader>
          <TableHeader>
            Anunciantes
            <TableHeaderIcon icon={FaArrowUp} />
          </TableHeader>
          <TableHeader>
            Lavanderia
            <TableHeaderIcon icon={FaArrowUp} />
          </TableHeader>
          <TableHeader>
            My Closett
            <TableHeaderIcon icon={FaArrowDown} />
          </TableHeader>
        </TableRow>
      </TableHead>
      <TableBody>
        <TableRow fontWeight="bold">
          <TableData>
            <Box as={FaShoppingCart} display="inline-block" />
          </TableData>
          <TableData>Total</TableData>
          <TableData color="green.500">
            {formatCurrency(total.cliente)}
          </TableData>
          <TableData color="red.500">
            {formatCurrency(total.vendedora)}
          </TableData>
          <TableData color="red.500">
            {formatCurrency(total.anunciantes)}
          </TableData>
          <TableData color="red.500">
            {formatCurrency(total.lavanderia)}
          </TableData>
          <TableData color="green.500">
            {formatCurrency(total.myclosett)}
          </TableData>
        </TableRow>
        {itens.map(item => (
          <TableRow 
            key={item.id}
            onClick={() => history(`/aluguel/${item.id}`)}
          >
            <TableData>{item.id}</TableData>
            <TableData><StatusAluguel value={item.status} /></TableData>
            <TableData color="green.500">
              {formatCurrency(item.cliente)}
            </TableData>
            <TableData color="red.500">
              {formatCurrency(item.vendedora)}
            </TableData>
            <TableData color="red.500">
              {formatCurrency(item.anunciantes)}
            </TableData>
            <TableData color="red.500">
              {formatCurrency(item.lavanderia)}
            </TableData>
            <TableData color="green.500">
              {formatCurrency(item.myclosett)}
            </TableData>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
};

export default TabContentPrevisto;
