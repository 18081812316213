import React from 'react';
import { useParams } from 'react-router-dom';
import find from 'lodash/find';

import { useGetCliente } from 'actions/clientes';
import { useGetOptionsClientes } from 'actions/options';
import { FormControlSelect } from 'components/form';

const FormControlSelectCliente = ({ isEditing, defaultValue, ...rest }) => {
  const { idCliente } = useParams();
  const { data: cliente, status: statusCliente } = useGetCliente(idCliente);
  const { data: clientes, status: statusClientes } = useGetOptionsClientes(
    !idCliente
  );
  if (!defaultValue && idCliente){
    defaultValue = idCliente;
  }

  const isLoading = (idCliente && statusCliente === 'loading') || (!idCliente && statusClientes === 'loading');
  const options = React.useMemo(
    () =>
      idCliente
        ? cliente && [{ value: cliente.data.id, label: cliente.data.nome }]
        : clientes &&
          clientes.data.map(({ id, nome }) => ({
            value: id,
            label: nome
          })),
    [cliente, clientes, idCliente]
  );
  const initialValue = React.useMemo(
    () => find(options, ['value', parseInt(defaultValue)]),
    [defaultValue, options]
  );

  return (
    <FormControlSelect
      label="Cliente"
      name="cliente"
      isLoading={isLoading}
      isDisabled={idCliente || isEditing}
      options={options}
      maxW={300}
      initialValue={initialValue}
      selectFirst={false}
      {...rest}
    />
  );
};

export default FormControlSelectCliente;
