import React from 'react';
import {
  Box,
  Stack,
  ButtonGroup,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalFooter,
  ModalBody,
  IconButton,
  Divider
} from '@chakra-ui/react';
import { FaTimes, FaPlus } from 'react-icons/fa';
import * as yup from 'yup';
import { format } from 'date-fns';

import { usePostAluguelEfetuarRetirada } from 'actions/alugueis';
import { useGetTipoPagamentoAnunciante } from 'actions/enums';
import {
  Form,
  FormTableRow,
  FormTableCell,
  FormSelect,
  FormInputGroup,
  FormInputDate,
  FormInputCurrency,
  useFormFieldArray,
  useWatch
} from 'components/form';
import {
  prepareInputDateValue,
  prepareOutputCurrencyValue,
  prepareOutputDateValue
} from 'utils/form';
import { formatCurrency } from 'utils/ui';

const validationSchema = yup.object().shape({
  pagamentos_cliente: yup
    .array()
    .of(
      yup.object().shape({
        tipo: yup.object().nullable().required().label('Tipo'),
        valor: yup.string().required().label('Valor'),
        data: yup.string().required().label('Data')
      })
    )
    .required('Deve haver ao menos um pagamento')
});

const ModalBodyForm = ({ totalPagamentosCliente, valorAluguel }) => {
  const name = 'pagamentos_cliente';
  const { data: tipos, status: statusTipos } = useGetTipoPagamentoAnunciante();
  const { fields, append, remove } = useFormFieldArray({ name });
  const fieldArray = useWatch(name);

  const isLoadingTipos = statusTipos === 'loading';

  const total = fieldArray.reduce((result, field) => {
    const valor = parseFloat(prepareOutputCurrencyValue(field.valor)) || 0;
    return (result += valor);
  }, 0);

  const totalPendente = valorAluguel - totalPagamentosCliente - total;

  return (
    <ModalBody width="100%">
      <FormTableRow px={4} py={2}>
        <FormTableCell>Tipo</FormTableCell>
        <FormTableCell>Valor</FormTableCell>
        <FormTableCell>Data</FormTableCell>
      </FormTableRow>
      <Divider mt={0} />
      <Stack>
        <FormTableRow p={2}>
          <FormTableCell>Valor Reserva</FormTableCell>
          {/* TODO: Esse valor é sempre 0? */}
          <FormTableCell>{formatCurrency(0)}</FormTableCell>
        </FormTableRow>
        {fields.map((field, index) => {
          return (
            <FormTableRow key={field.id}>
              <FormSelect
                name={`${name}.${index}.tipo`}
                isLoading={isLoadingTipos}
                options={!isLoadingTipos && tipos.data}
                selectFirst={false}
              />
              <FormInputGroup
                name={`${name}.${index}.valor`}
                inputComponent={FormInputCurrency}
                leftAddon="R$"
                placeholder="Valor"
                width="100%"
                defaultValue={field.valor}
              />
              <FormInputDate
                name={`${name}.${index}.data`}
                width="100%"
                defaultValue={field.data}
              />
              <IconButton
                size="sm"
                aria-label="Sair"
                variant="ghost"
                icon={<FaTimes/>}
                onClick={() => remove(index)}
              />
            </FormTableRow>
          );
        })}
        <Box>
          <FormTableRow bg="yellow.100" p={2}>
            <FormTableCell>Total Pago</FormTableCell>
            <FormTableCell>{formatCurrency(total)}</FormTableCell>
          </FormTableRow>
          <FormTableRow bg="gray.100" p={2}>
            <FormTableCell>Total Pendente</FormTableCell>
            <FormTableCell>{formatCurrency(totalPendente)}</FormTableCell>
          </FormTableRow>
          <FormTableRow bg="gray.100" p={2}>
            <FormTableCell>Valor Aluguel</FormTableCell>
            <FormTableCell>{formatCurrency(valorAluguel)}</FormTableCell>
          </FormTableRow>
        </Box>
        <Button
          size="sm"
          colorScheme="green"
          leftIcon={<FaPlus/>}
          onClick={() =>
            append({
              valor: '0',
              data: prepareInputDateValue(format(new Date(), 'yyyy-MM-dd'))
            })
          }
        >
          Adicionar pagamento
        </Button>
      </Stack>
    </ModalBody>
  );
};

const ModalEfetuarRetirada = ({
  isOpen,
  onClose,
  aluguelId,
  totalPagamentosCliente = 0,
  valorAluguel = 0
}) => {
  
  const {mutateAsync:efetuarRetirada, status } = usePostAluguelEfetuarRetirada(
    aluguelId
  );

  const isSubmitting = status === 'loading';

  const onSubmit = async data => {
    //try {
      const pagamentos_cliente = data.pagamentos_cliente.map(pagamento => ({
        tipo: pagamento.tipo ? pagamento.tipo.value : null,
        valor: prepareOutputCurrencyValue(pagamento.valor),
        data: prepareOutputDateValue(pagamento.data)
      }));

      await efetuarRetirada({ pagamentos_cliente });

      onClose();
    //} catch (er) {
    //  toastList({ messages: er.errors, status: 'error' });
    //}
  };

  const defaultValues = {
    pagamentos_cliente: [
      {
        valor: 0,
        data: prepareInputDateValue(format(new Date(), 'yyyy-MM-dd'))
      }
    ]
  };

  return (
    <Modal
      closeOnOverlayClick={false}
      size="lg"
      isOpen={isOpen}
      onClose={onClose}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Pagamento</ModalHeader>
        <ModalCloseButton />
        <Form
          onSubmit={onSubmit}
          validationSchema={validationSchema}
          defaultValues={defaultValues}
          spacing={0}
          align="flex-end"
        >
          <ModalBodyForm
            totalPagamentosCliente={totalPagamentosCliente}
            valorAluguel={valorAluguel}
          />
          <ModalFooter>
            <ButtonGroup size="sm">
              <Button
                type="submit"
                loadingText="Efetuando Retirada"
                colorScheme="blue"
                variant="solid"
                isLoading={isSubmitting}
              >
                Efetuar Retirada
              </Button>
            </ButtonGroup>
          </ModalFooter>
        </Form>
      </ModalContent>
    </Modal>
  );
};

export default ModalEfetuarRetirada;
