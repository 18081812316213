import React from 'react';

import { useGetCaixaVendedoras } from 'actions/caixa';
import { useGetVendedoraComissao } from 'actions/vendedoras';
import { useNavigate } from 'react-router-dom';
import {
  DisplayText,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableHeader,
  TableData,
  StatusAluguel
} from 'components/ui';
import { FaShoppingCart } from 'react-icons/fa';
import get from 'lodash/get';
import find from 'lodash/find';
import {
  Flex,
  Stack,
  Heading,
  Box
} from '@chakra-ui/react';

import { formatCurrency, formatDate } from 'utils/ui';
import { Select } from 'components/form';
import { useSearchParams , useDocumentTitle} from 'customHooks';
import { stringify } from 'qs';


const TabelaVendedoras = ({itens}) => {
  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableHeader>Vendedora</TableHeader>
          <TableHeader>Valor Itens</TableHeader>
          <TableHeader>Comissão Prevista</TableHeader>
        </TableRow>
      </TableHead>
      <TableBody>
        {itens.map(item => (
          <TableRow key={item.id}>
            <TableData>{item.nome}</TableData>
            <TableData>{formatCurrency(item.valor_itens)}</TableData>
            <TableData>{formatCurrency(item.valor_previsto)}</TableData>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
}



const TabelaVendedora = ({ id, mes, ano }) => {
  const { data, status } = useGetVendedoraComissao({id, mes, ano});
  const history = useNavigate();

  if (status === 'loading') return <DisplayText>Carregando...</DisplayText>;

  const { total, itens } = data.data;  

  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableHeader>Status</TableHeader>
          <TableHeader>
            Data Devolução
          </TableHeader>
          <TableHeader>
            Itens
          </TableHeader>
          <TableHeader>
            Valor Itens
          </TableHeader>
          <TableHeader>
            Comissão Prevista
          </TableHeader>
        </TableRow>
      </TableHead>
      <TableBody>
        <TableRow fontWeight="bold">
          <TableData>
            <Box as={FaShoppingCart} display="inline-block" />
          </TableData>
          <TableData>{total.mes}</TableData>
          <TableData>Total</TableData>
          <TableData>
            {formatCurrency(total.valor_itens)}
          </TableData>
          <TableData>
            {formatCurrency(total.valor_previsto)}
          </TableData>
        </TableRow>
        {itens.map(item => (
          <TableRow 
            key={item.id}
            onClick={() => history(`/aluguel/${item.id}`)}
          >
            <TableData><StatusAluguel value={item.status} /></TableData>
            <TableData>{formatDate(item.data_devolucao)}</TableData>
            <TableData>
              {item.itens}
            </TableData>
            <TableData>
              {formatCurrency(item.valor_itens)}
            </TableData>
            <TableData>
              {formatCurrency(item.valor_previsto)}
            </TableData>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
};

const ListaOuDetalhe = ({vendedora, itens, ...rest}) => {
  if (vendedora && vendedora.value !== 0) {
    return <TabelaVendedora id={vendedora.value} {...rest}/>
  }
  else {
    return <TabelaVendedoras itens={itens}/>
  }
}



const TabContentVendedora = ({filtros}) => {
  const { data, status } = useGetCaixaVendedoras(filtros);
  const searchParams = useSearchParams({
    vendedora: 0
  });
  const [xfiltros, setFiltros] = React.useState(searchParams);
  const [vendedora, setVendedora] = React.useState({value: 0, label: "Todas"});

  const history = useNavigate();
  useDocumentTitle('Aluguéis da Vendedora');

  React.useEffect(() => {
    history(`?${stringify(xfiltros)}`, {replace: true});
  }, [xfiltros, history]);

  const changeVendedora = value => {
    const vendedora = get(value, 'value');
    setFiltros(prev => ({ ...prev, vendedora}))
    setVendedora(value || {value: 0, label: "Todas"})
  }

  React.useEffect(() => {
    const vendedoras = get(data, 'data.itens', []);
    const vendedoraId = parseInt(xfiltros.vendedora || 0)
    const vendedora = find(vendedoras, ['id', vendedoraId]);
    if (vendedora) {
      setVendedora({value: vendedora.id, label: vendedora.nome})
    }
  }, [data, xfiltros]);

  


  if (status === 'loading') return <DisplayText>Carregando...</DisplayText>;

  const { itens } = data.data;
  const { mes, ano } = filtros;

  // TODO: Inserir navegação para mostrar informações detalhados da vendedora

  return (
    <Stack p={4} spacing={4}>
    <Flex>
        <Heading size="lg">
          {vendedora.label}
        </Heading>
        <Stack as={Flex} isInline justify="center" ml="auto">
          <Select
          size="sm"
          width="200px"
          options={
              [{nome:'Todas', id:0}, ...itens].map(item => ({ value: item.id, label: item.nome }))
          }
          isClearable={false}
          value={vendedora}
          onChange={changeVendedora}
          />
        </Stack>
        
      </Flex>
      <ListaOuDetalhe mes={mes} ano={ano} itens={itens} vendedora={vendedora}/>
      
    </Stack>

  );
};

export default TabContentVendedora;
