import React from 'react';
import { Box, MenuItem } from '@chakra-ui/react';

const IconMenuItem = ({ icon, children, ...rest }) => {
  return (
    <MenuItem {...rest}>
      <Box as={icon} boxSize="12px" mr={2} />
      <span>{children}</span>
    </MenuItem>
  );
};

export default IconMenuItem;
