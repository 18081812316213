import React from 'react';
import ReactSelect from 'react-select';
import Creatable from 'react-select/creatable';
import { useFormControl } from '@chakra-ui/react';

import useSelectStyles from './useSelectStyles';

const Select = React.forwardRef (
  (props, ref) => {
  const inputProps = useFormControl(props);
  const isInvalid = inputProps['aria-invalid']
  const { styles, theme } = useSelectStyles({...props, isInvalid });

  if (props.isCreatable) {
    return (
      <Creatable
        placeholder="Selecione..."
        menuPlacement="auto"
        ref={ref}
        isClearable
        theme={theme}
        styles={styles}
        aria-invalid={isInvalid}
        formatCreateLabel={(name) => (`${name}`)}
        {...props}
      />
    );

  } else {
  return (
    <ReactSelect
      placeholder="Selecione... "
      menuPlacement="auto"
      isClearable
      ref={ref}
      theme={theme}
      styles={styles}
      aria-invalid={isInvalid}
      {...props}
    />
  );
}
});

export default Select;
