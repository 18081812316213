import React from 'react';
import {
  ButtonGroup,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  FormControl,
  FormLabel,
  Stack
} from '@chakra-ui/react';
import * as yup from 'yup';

import { useGetOptionsEmpresas } from 'actions/options';
import { Form, FormSelect } from 'components/form';

const validationSchema = yup.object().shape({
  empresa: yup.object().nullable().required().label('Empresa')
});

const ModalEscolherEmpresa = ({ isOpen, onClose, onSubmit, isSubmitting }) => {
  const initRef = React.useRef();
  const { data: empresas, status: statusEmpresas } = useGetOptionsEmpresas();

  const optionsEmpresas = empresas
    ? empresas.data.map(({ id, nome }) => ({
        value: id,
        label: nome
      }))
    : [];
  const isLoadingEmpresas = statusEmpresas === 'loading';

  return (
    <Modal
      closeOnOverlayClick={false}
      size="md"
      initialFocusRef={initRef}
      isOpen={isOpen}
      onClose={onClose}
    >
      <ModalOverlay />

      <ModalContent>
        <ModalHeader>Contrato</ModalHeader>
        <ModalCloseButton />
        <Form
          validationSchema={validationSchema}
          onSubmit={values => onSubmit(values.empresa.value)}
          align="flex-end"
          spacing={0}
        >
          <ModalBody as={Stack} spacing={4} width="100%">
            <FormControl>
              <FormLabel fontSize="sm" htmlFor="empresa">
                Empresa
              </FormLabel>
              <FormSelect
                name="empresa"
                isLoading={isLoadingEmpresas}
                options={optionsEmpresas}
              />
            </FormControl>
          </ModalBody>
          <ModalFooter>
            <ButtonGroup size="sm">
              <Button
                type="submit"
                isLoading={isSubmitting}
                loadingText="Indo para Lavanderia"
                colorScheme="blue"
                variant="solid"
                ref={initRef}
              >
                Ir para Lavanderia
              </Button>
            </ButtonGroup>
          </ModalFooter>
        </Form>
      </ModalContent>
    </Modal>
  );
};

export default ModalEscolherEmpresa;
