import React from 'react';
import { Text, useToast } from '@chakra-ui/react';

const useToastList = () => {
  const toast = useToast();

  return ({ messages = {}, ...rest }) => {
    const description = Object.keys(messages).length
      ? Object.keys(messages).map((error, i) => (
          <Text key={i} fontSize="sm" pt={1}>
            {error}: {messages[error]}
          </Text>
        ))
      : messages;

    toast({ description, isClosable: true, ...rest });
  };
};

export default useToastList;
