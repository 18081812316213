import React from 'react';
import { RadioGroup, Radio, useFormControl} from '@chakra-ui/react';

const RadioSelect = React.forwardRef(
  ({ size = 'sm', options, isDisabled, value, onChange, ...rest }, ref) => {
    const inputProps = useFormControl(rest);
    const isInvalid = inputProps['aria-invalid']

    const refCallback = React.useCallback((el) => {
      if (el){
        ref(el.querySelector("input"))
      }
    }, [ref])

    return (
      <RadioGroup ref={refCallback} value={value} onChange={onChange} size={size}>
        {options.map((option, i) => (
          <Radio
            key={option.value}
            value={option.value}
            isDisabled={isDisabled}
            isInvalid={isInvalid && i===0}
            isChecked={option.value === rest.value} // bug chakra
            width="100%"
            {...rest}
          >
            {option.label}
          </Radio>
        ))}
      </RadioGroup>
    );
  }
);

export default RadioSelect;
