import React from 'react';
import {
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription
} from '@chakra-ui/react';

import { usePermissao, useDocumentTitle } from 'customHooks';

import DisplayText from 'components/ui/DisplayText';

const PaginaSemPermissao = () => {
  useDocumentTitle('Sem permissão');
  return <Alert
    flexDirection="column"
    justifyContent="center"
    textAlign="center"
    display="flex"
    backgroundColor="transparent"
    height="100%"
    status="warning"
  >
    <AlertIcon boxSize="40px" mr={0} />
    <AlertTitle mt={4} mb={1} fontSize="lg">
      Ops
    </AlertTitle>
    <AlertDescription maxWidth="sm">
      Você não tem permissão para acessar esta página
    </AlertDescription>
  </Alert>
};

const CarreganoPermissao = () => <DisplayText>Carregando...</DisplayText>;

const PermissaoRoute = ({ permissao, children }) => {
  const { isLoading, value } = usePermissao(permissao);
  return (
      isLoading ? (
        <CarreganoPermissao />
      ) : value ? (
        children
      ) : (
        <PaginaSemPermissao />
      )
  );
};

export default PermissaoRoute;