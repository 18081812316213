import React from 'react';
import { Tag } from '@chakra-ui/react';

const StatusColor = {
  ANDAMENTO: 'orange',
  AGENDADO: 'blue',
  FINALIZADO: 'green'
};

const StatusLabel = {
  ANDAMENTO: 'Andamento',
  AGENDADO: 'Agendado',
  FINALIZADO: 'Finalizado'
};

const StatusLavanderia = ({ value, ...rest }) => {
  return <Tag colorScheme={StatusColor[value]} {...rest}>{StatusLabel[value] || value}</Tag>;
};

export default StatusLavanderia;
