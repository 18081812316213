import React from 'react';
import {
  useNavigate,
  Link as RouterLink
} from 'react-router-dom';
import {
  Button,
  Stack,
  Menu,
  IconButton,
  MenuList,
  MenuButton,
  MenuDivider,
  useDisclosure
} from '@chakra-ui/react';
import { FaPlus, FaChevronDown, FaPen, FaTrash, FaUser } from 'react-icons/fa';

import { usePostClienteAnunciante, useDeleteCliente } from 'actions/clientes';
import { ConfirmDialog, IconMenuItem } from 'components/ui';
import { useToastList } from 'customHooks';

const AcoesButton = ({ cliente }) => {
  const { id, nome, is_anunciante } = cliente;
  const history = useNavigate();
  const toastList = useToastList();

  const {
    isOpen: isOpenConfirmacaoVirarAnunciante,
    onClose: onCloseConfirmacaoVirarAnunciante,
    onOpen: onOpenConfirmacaoVirarAnunciante
  } = useDisclosure();
  const {
    isOpen: isOpenConfirmacaoRemover,
    onClose: onCloseConfirmacaoRemover,
    onOpen: onOpenConfirmacaoRemover
  } = useDisclosure();

  const {
    mutateAsync: virarAnunciante,
    status: statusVirarAnunciante
   } = usePostClienteAnunciante(id);
  const {mutateAsync: apagar, status: statusApagar } = useDeleteCliente(id);

  const tornarClienteUmAnunciante = async () => {
    try {
      await virarAnunciante();
      toastList({ messages: [`Agora ${nome} também é anunciante`] });
      onCloseConfirmacaoVirarAnunciante();
      history(`/clientes/${id}`);
    } catch (er) {
      toastList({ messages: er.errors, status: 'error' });
    }
  };

  const apagarCliente = async () => {
    try {
      await apagar();
      history(`/clientes`);
    } catch (er) {
      toastList({ messages: er.errors, status: 'error' });
    }
  };

  return (
    <Stack isInline spacing={0} ml="auto">
      <Button
        size="sm"
        variant="outline"
        leftIcon={<FaPlus/>}
        roundedRight={0}
        as={RouterLink}
        to={`/aluguel/cliente/${id}/form`}
      >
        Reserva
      </Button>
      <Menu placement="bottom-end">
        <MenuButton
          as={IconButton}
          size="sm"
          variant="outline"
          icon={<FaChevronDown/>}
          roundedLeft={0}
        />
        <MenuList minWidth="150px">
          <IconMenuItem icon={FaPen} as={RouterLink} to={`form`}>
            Editar
          </IconMenuItem>
          {is_anunciante ? (
            <IconMenuItem
              icon={FaUser}
              as={RouterLink}
              to={`/anunciantes/${id}`}
            >
              Anunciante
            </IconMenuItem>
          ) : (
            <IconMenuItem
              icon={FaPlus}
              onClick={onOpenConfirmacaoVirarAnunciante}
            >
              Anunciante
            </IconMenuItem>
          )}
          <MenuDivider />
          <IconMenuItem icon={FaTrash} onClick={onOpenConfirmacaoRemover}>
            Remover
          </IconMenuItem>
        </MenuList>
      </Menu>
      <ConfirmDialog
        isLoading={statusVirarAnunciante === 'loading'}
        isOpen={isOpenConfirmacaoVirarAnunciante}
        onClose={onCloseConfirmacaoVirarAnunciante}
        onConfirm={tornarClienteUmAnunciante}
        zIndexOverlay="modal"
        title="Virar Cliente"
        confirmButtonText="Sim"
        cancelButtonText="Não"
      >
        Deseja que a cliente <strong>{nome}</strong> se torne também uma
        anunciante?
      </ConfirmDialog>
      <ConfirmDialog
        isLoading={statusApagar === 'loading'}
        isOpen={isOpenConfirmacaoRemover}
        onClose={onCloseConfirmacaoRemover}
        onConfirm={apagarCliente}
        zIndexOverlay="modal"
        title="Remover Cliente"
        confirmButtonText="Sim"
        cancelButtonText="Não"
      >
        Deseja remover a cliente <strong>{nome}</strong>
      </ConfirmDialog>
    </Stack>
  );
};

export default AcoesButton;
