import React from 'react';
import {
  Link as RouterLink,
  useNavigate
} from 'react-router-dom';
import {
  Stack,
  Flex,
  IconButton,
  Button,
  useDisclosure
} from '@chakra-ui/react';
import get from 'lodash/get';
import { FaPlus, FaTrash, FaPen } from 'react-icons/fa';
import { stringify } from 'qs';

import { useGetEmpresas, useDeleteEmpresa } from 'actions/empresas';
import {
  ConfirmDialog,
  DisplayText,
  Paginator,
  Table,
  TableHead,
  TableBodyZebra,
  TableRow,
  TableHeader,
  TableData
} from 'components/ui';
import { useSearchParams, useToastList, useDocumentTitle } from 'customHooks';

const initialFiltros = {
  page: 1
};

const TableRowEmpresa = ({ value, filtros }) => {
  const { id, nome, is_myclosett } = value;
  const toastList = useToastList();
  const { mutateAsync: apagar, status: statusApagar } = useDeleteEmpresa(
    id,
    filtros
  );

  const {
    isOpen: isOpenConfirmacaoRemover,
    onClose: onCloseConfirmacaoRemover,
    onOpen: onOpenConfirmacaoRemover
  } = useDisclosure();

  const apagarEmpresa = async () => {
    try {
      await apagar();
      onCloseConfirmacaoRemover();
    } catch (er) {
      toastList({ messages: er.errors, status: 'error' });
    }
  };

  return (
    <TableRow key={id}>
      <TableData>{id}</TableData>
      <TableData>{nome || <DisplayText>nulo</DisplayText>}</TableData>
      <TableData>
        {!is_myclosett && (
          <Flex>
            <Button
              as={RouterLink}
              to={`${id}/form`}
              roundedRight={0}
              size="xs"
              leftIcon={<FaPen />}
            >
              Editar
            </Button>
            <IconButton
              roundedLeft={0}
              ml="auto"
              size="xs"
              icon={<FaTrash />}
              onClick={onOpenConfirmacaoRemover}
            />
          </Flex>
        )}
      </TableData>
      <ConfirmDialog
        isLoading={statusApagar === 'loading'}
        isOpen={isOpenConfirmacaoRemover}
        onClose={onCloseConfirmacaoRemover}
        onConfirm={apagarEmpresa}
        zIndexOverlay="modal"
        title="Remover Empresa"
        confirmButtonText="Sim"
        cancelButtonText="Não"
      >
        Deseja remover a empresa <strong>{nome}</strong>?
      </ConfirmDialog>
    </TableRow>
  );
};

const TableEmpresas = ({ filtros, onPaginate }) => {
  const { page } = filtros;
  const { status, data: empresas, error, hasNext } = useGetEmpresas(filtros);

  if (status === 'loading') return <DisplayText>Carregando...</DisplayText>;

  if (status === 'error') {
    return <DisplayText>{get(error, 'response.data.detail')}</DisplayText>;
  }

  const { results } = empresas.data;

  if (results.length === 0) {
    return <DisplayText>Nenhuma informação disponível</DisplayText>;
  }

  return (
    <Stack spacing={4}>
      <Table>
        <TableHead>
          <TableRow>
            <TableHeader>#</TableHeader>
            <TableHeader>Empresa</TableHeader>
            <TableHeader w={1} />
          </TableRow>
        </TableHead>
        <TableBodyZebra>
          {empresas.data.results.map(empresa => (
            <TableRowEmpresa
              key={empresa.id}
              value={empresa}
              filtros={filtros}
            />
          ))}
        </TableBodyZebra>
      </Table>
      <Paginator page={page} onPaginate={onPaginate} hasNext={hasNext} />
    </Stack>
  );
};

const Lista = () => {
  const searchParams = useSearchParams(initialFiltros);
  useDocumentTitle('Empresas de Lavanderia');
  const [filtros, setFiltrosState] = React.useState(searchParams);
  const history = useNavigate();

  const setFiltros = React.useCallback(
    newFiltros => {
      const filtros = { ...searchParams, ...newFiltros };
      history(`?${stringify(filtros)}`, { replace: true });
    },
    [setFiltrosState, searchParams]
  );

  return (
    <Stack spacing={4} p={4}>
      <Flex ml="auto">
        <IconButton
          as={RouterLink}
          to={`add`}
          size="sm"
          icon={<FaPlus />}
        />
      </Flex>
      <TableEmpresas
        filtros={filtros}
        onPaginate={page => setFiltros({ page })}
      />
    </Stack>
  );
};

export default Lista;
