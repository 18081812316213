import React from 'react';
import { Tabs, Tab, TabList, TabPanel, TabPanels } from '@chakra-ui/react';

import TabContentReservas from './TabContentReservas';
import TabContentHistorico from './TabContentHistorico';
import TabContentLavanderia from './TabContentLavanderia';

import { useLocalStorage } from 'customHooks';

const AnuncianteTabs = () => {
  const [index, setIndex] = useLocalStorage('myclosett:item_tab', 0);
  const onChange = (tabIndex) => setIndex(tabIndex);
  return (
    <Tabs variant="enclosed" size="sm" defaultIndex={index} onChange={onChange}>
      <TabList>
        <Tab>Reservas</Tab>
        <Tab>Histórico</Tab>
        <Tab>Lavanderia</Tab>
      </TabList>
      <TabPanels pt={6} outline="none">
        <TabPanel outline="none" p={0}>
          <TabContentReservas />
        </TabPanel>
        <TabPanel outline="none" p={0}>
          <TabContentHistorico />
        </TabPanel>
        <TabPanel outline="none" p={0}>
          <TabContentLavanderia />
        </TabPanel>
      </TabPanels>
    </Tabs>
  );
};

export default AnuncianteTabs;
