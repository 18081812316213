import React from 'react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useQuery } from 'customHooks';

import { useGet, usePost, usePut, useDelete } from 'customHooks/api';
import { usePaginatedPrefetchQuery } from 'customHooks';

export const QueryKeys = {
  EMPRESA: 'empresa',
  EMPRESAS: 'empresas'
};

export const useGetEmpresa = id => {
  const getEmpresa = useGet('empresas/{id}');
  return useQuery({
    queryKey: [QueryKeys.EMPRESA, { id }],
    queryFn:getEmpresa,
    enabled: !!id
  });
};

export const useGetEmpresas = filtros => {
  const queryKey = React.useMemo(() => [QueryKeys.EMPRESAS, filtros], [
    filtros
  ]);
  const getEmpresas = useGet('empresas');
  return usePaginatedPrefetchQuery(queryKey, getEmpresas);
};

export const usePostEmpresa = queryConfig => {
  const postEmpresa = usePost('empresas');
  return useMutation(postEmpresa, queryConfig);
};

export const usePutEmpresa = id => {
  const queryClient = useQueryClient();
  const putEmpresa = usePut(`empresas/${id}`);
  return useMutation(putEmpresa, {
    onSuccess: data =>
    queryClient.setQueryData([QueryKeys.EMPRESA, { id: `${id}` }], data)
  });
};

export const useDeleteEmpresa = (id, filtros) => {
  const queryClient = useQueryClient();
  const deleteEmpresa = useDelete(`empresas/${id}`);
  return useMutation(deleteEmpresa, {
    onSuccess: () => queryClient.refetchQueries([QueryKeys.EMPRESAS, filtros])
  });
};
