import React from 'react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useQuery } from 'customHooks';

import { useGet, usePost, usePut, useDelete } from 'customHooks/api';
import { usePaginatedPrefetchQuery } from 'customHooks';

export const QueryKeys = {
  ANUNCIANTE: 'anunciante',
  ANUNCIANTES: 'anunciantes',
  ANUNCIANTES_CONTRATO: 'anunciantes/contrato',
  ANUNCIANTES_ITENS: 'anunciantes/itens',
  ANUNCIANTES_PAGAMENTOS: 'anunciantes/pagamentos',
  ANUNCIANTES_PENDENCIAS: 'anunciantes/pendencias',
  ANUNCIANTES_TOTAL_PAGO: 'anunciantes/total_pago',
  ANUNCIANTES_TOTAL_PENDENTE: 'anunciantes/total_pendente'
};

export const useGetAnunciante = id => {
  const getAnunciante = useGet('anunciantes/{id}');
  return useQuery({
    queryKey: [QueryKeys.ANUNCIANTE, { id }],
    queryFn:getAnunciante,
    enabled: !!id
  });
};

export const useGetAnunciantes = filtros => {
  const queryKey = React.useMemo(() => [QueryKeys.ANUNCIANTES, filtros], [
    filtros
  ]);
  const getAnunciantes = useGet('anunciantes');
  return usePaginatedPrefetchQuery(queryKey, getAnunciantes);
};

export const useGetAnuncianteContrato = (id, queryConfig) => {
  const getContrato = useGet('anunciantes/{id}/contrato', {
    responseType: 'blob'
  });
  return useQuery(
    [QueryKeys.ANUNCIANTES_CONTRATO, { id: `${id}` }],
    getContrato,
    queryConfig
  );
};

export const useGetAnuncianteItens = ({ id, page = 1 }) => {
  const queryKey = React.useMemo(
    () => [QueryKeys.ANUNCIANTES_ITENS, { id: `${id}`, page }],
    [id, page]
  );
  const getAnuncianteItens = useGet('anunciantes/{id}/itens');
  return usePaginatedPrefetchQuery(queryKey, getAnuncianteItens);
};

export const useGetAnunciantePagamentos = ({ id, page = 1 }) => {
  const queryKey = React.useMemo(
    () => [QueryKeys.ANUNCIANTES_PAGAMENTOS, { id: `${id}`, page }],
    [id, page]
  );
  const getPagamentos = useGet('anunciantes/{id}/pagamentos');
  return usePaginatedPrefetchQuery(queryKey, getPagamentos);
};

export const useGetAnunciantePendencias = ({ id, page = 1 }) => {
  const queryKey = React.useMemo(
    () => [QueryKeys.ANUNCIANTES_PENDENCIAS, { id: `${id}`, page }],
    [id, page]
  );
  const getPendencias = useGet('anunciantes/{id}/pendencias');
  return usePaginatedPrefetchQuery(queryKey, getPendencias);
};

export const useGetAnuncianteTotalPago = ({ id }) => {
  const getTotalPago = useGet('anunciantes/{id}/total_pago');
  return useQuery([QueryKeys.ANUNCIANTES_TOTAL_PAGO, { id: `${id}` }], getTotalPago);
};

export const useGetAnuncianteTotalPendente = ({ id }) => {
  const getTotalPendente = useGet('anunciantes/{id}/total_pendente');
  return useQuery(
    [QueryKeys.ANUNCIANTES_TOTAL_PENDENTE, { id: `${id}` }],
    getTotalPendente
  );
};

export const usePostAnunciante = queryConfig => {
  const postAnunciante = usePost('anunciantes');
  return useMutation(postAnunciante, queryConfig);
};

export const usePostAnuncianteContrato = (id, queryConfig) => {
  const postContrato = usePost(`anunciantes/${id}/contrato`);
  return useMutation(postContrato, queryConfig);
};

export const usePostAnuncianteAtivar = (id, queryConfig) => {
  const queryClient = useQueryClient();
  const postAtivar = usePost(`anunciantes/${id}/ativar`);
  return useMutation(postAtivar, {
    onSuccess: data => {
      queryClient.setQueryData([QueryKeys.ANUNCIANTE, { id: `${id}` }], data);
    },
    ...queryConfig
  });
};

export const usePostAnuncianteDesativar = (id, queryConfig) => {
  const queryClient = useQueryClient();
  const postDesativar = usePost(`anunciantes/${id}/desativar`);
  return useMutation(postDesativar, {
    onSuccess: data => {
      queryClient.setQueryData([QueryKeys.ANUNCIANTE, { id: `${id}` }], data);
    },
    ...queryConfig
  });
};

export const usePostAnuncianteCliente = (id, queryConfig) => {
  const queryClient = useQueryClient();
  const postVirarCliente = usePost(`anunciantes/${id}/cliente`);
  return useMutation(postVirarCliente, {
    onSuccess: data => {
      queryClient.setQueryData([QueryKeys.ANUNCIANTE, { id: `${id}` }], data);
    },
    ...queryConfig
  });
};

export const usePutAnunciante = id => {
  const putAnunciante = usePut(`anunciantes/${id}`);
  const queryClient = useQueryClient();
  return useMutation(putAnunciante, {
    onSuccess: data =>
    queryClient.setQueryData([QueryKeys.ANUNCIANTE, { id: `${id}` }], data)
  });
};

export const useDeleteAnunciante = (id, queryConfig) => {
  const deleteAnunciante = useDelete(`anunciantes/${id}`);
  return useMutation(deleteAnunciante, queryConfig);
};

/////////////////////////////////////////////////////////////

export const useDownloadAnuncianteContrato = id => {
  const [isFetching, setFetching] = React.useState(false);
  const { refetch: recuperarContrato } = useGetAnuncianteContrato(id, {
    enabled: false
  });
  const { mutateAsync: criarContrato } = usePostAnuncianteContrato(id);

  const fetch = async values => {
    try {
      setFetching(true);
      if (values) await criarContrato(values);
      const res = await recuperarContrato();

      const url = URL.createObjectURL(res.data.data);
      const element = document.createElement('a');
      setFetching(false);

      element.setAttribute('href', url);
      element.setAttribute('download', `contrato-anunciante-${id}.pdf`);
      element.setAttribute('target', '_blank');
      element.style.display = 'none';
      document.body.appendChild(element);
      element.click();
      document.body.removeChild(element);
    } catch (er) {
      setFetching(false);
      throw er;
    }
  };

  return [fetch, { isFetching }];
};
