import React from 'react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useQuery } from 'customHooks';

import { useGet, usePost, usePut, useDelete } from 'customHooks/api';
import { usePaginatedPrefetchQuery } from 'customHooks';

export const QueryKeys = {
  CLIENTE: 'cliente',
  CLIENTES: 'clientes',
  CLIENTES_ALUGUEIS: 'clientes/alugueis'
};

export const useGetCliente = id => {
  const getCliente = useGet('clientes/{id}');
  return useQuery({
    queryKey: [QueryKeys.CLIENTE, { id }],
    queryFn:getCliente,
    enabled: !!id
  });
};

export const useGetClientes = filtros => {
  const queryKey = React.useMemo(() => [QueryKeys.CLIENTES, filtros], [
    filtros
  ]);
  const getClientes = useGet('clientes');
  return usePaginatedPrefetchQuery(queryKey, getClientes);
};

export const useGetClienteAlugueis = ({ id, page = 1 }) => {
  const queryKey = React.useMemo(
    () => [QueryKeys.CLIENTES_ALUGUEIS, { id, page }],
    [id, page]
  );
  const getClienteAlugueis = useGet('clientes/{id}/alugueis');
  return usePaginatedPrefetchQuery(queryKey, getClienteAlugueis);
};

export const usePostCliente = queryConfig => {
  const postCliente = usePost('clientes');
  return useMutation(postCliente, queryConfig);
};

export const usePostClienteAnunciante = (id, queryConfig) => {
  const queryClient = useQueryClient();
  const postVirarAnunciante = usePost(`clientes/${id}/anunciante`);
  return useMutation(postVirarAnunciante, {
    onSuccess: data => {
      queryClient.setQueryData([QueryKeys.CLIENTE, { id: `${id}` }], data);
    },
    ...queryConfig
  });
};

export const usePutCliente = id => {
  const queryClient = useQueryClient();
  const putCliente = usePut(`clientes/${id}`);
  return useMutation(putCliente, {
    onSuccess: data =>
    queryClient.setQueryData([QueryKeys.CLIENTE, { id: `${id}` }], data)
  });
};

export const useDeleteCliente = (id, queryConfig) => {
  const deleteCliente = useDelete(`clientes/${id}`);
  return useMutation(deleteCliente, queryConfig);
};
