import React from 'react';
import { components } from 'react-select';
import { Grid, Text, Image } from '@chakra-ui/react';

const SelectOptionItem = ({ data, ...rest }) => {
  return (
    <components.Option data={data} {...rest}>
      <Grid minH="50px" templateColumns="50px 1fr" alignItems="center">
        <Image src={data.thumbnail} maxH="50px" justifySelf="center" />
        <Text ml={2}>{data.label}</Text>
      </Grid>
    </components.Option>
  );
};

export default SelectOptionItem;
