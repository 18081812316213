import isEmpty from 'lodash/isEmpty';
import get from 'lodash/get';

import {
  prepareOutputValues,
  prepareOutputDateValue,
  prepareOutputCurrencyValue
} from 'utils/form';

export default (data, originalData) => {
  const outputData = prepareOutputValues(data);

  outputData.status = !isEmpty(outputData.status)
    ? outputData.status.value
    : null;

  outputData.cliente = !isEmpty(outputData.cliente)
    ? outputData.cliente.value
    : null;

  outputData.data_reserva = prepareOutputDateValue(outputData.data_reserva);

  outputData.data_retirada = prepareOutputDateValue(outputData.data_retirada);

  outputData.data_devolucao = prepareOutputDateValue(outputData.data_devolucao);

  outputData.vendedora = !isEmpty(outputData.vendedora)
    ? outputData.vendedora.value
    : null;

  outputData.tipo_evento = !isEmpty(outputData.tipo_evento)
    ? outputData.tipo_evento.value
    : null;

  outputData.papel_evento = !isEmpty(outputData.papel_evento)
    ? outputData.papel_evento.value
    : null;

  outputData.uf = !isEmpty(outputData.uf) ? outputData.uf.value : null;

  outputData.data_evento = prepareOutputDateValue(outputData.data_evento);

  outputData.aluguel_itens =
    outputData.aluguel_itens &&
    outputData.aluguel_itens.map((aluguelItem) => ({
      //...get(originalData, `aluguel_itens.${index}`),
      item: aluguelItem.item ? aluguelItem.item : null,
      valor_negociado: prepareOutputCurrencyValue(aluguelItem.valor_negociado),
      aluguel_item_lavanderia: {
        //...get(originalData, `aluguel_itens.${index}.aluguel_item_lavanderia`),
        id:aluguelItem.aluguel_item_lavanderia.id,
        valor: prepareOutputCurrencyValue(
          aluguelItem.aluguel_item_lavanderia.valor
        )
      }
    }));

  outputData.pagamentos_cliente =
    outputData.pagamentos_cliente &&
    outputData.pagamentos_cliente.map((pagamentoCliente, index) => ({
      ...get(originalData, `pagamentos_cliente.${index}`),
      tipo: pagamentoCliente.tipo ? pagamentoCliente.tipo.value : null,
      valor: prepareOutputCurrencyValue(pagamentoCliente.valor),
      data: prepareOutputDateValue(pagamentoCliente.data)
    }));

  return outputData;
};
