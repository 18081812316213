import React from 'react';
import { Tabs, Tab, TabList, TabPanel, TabPanels } from '@chakra-ui/react';

import TabContentItens from './TabContentItens';
import TabContentPagamentos from './TabContentPagamentos';
import TabContentPendencias from './TabContentPendencias';
import TabContentSobre from './TabContentSobre';

const AnuncianteTabs = ({ anunciante, permission }) => {
  const { id } = anunciante;

  return (
    <Tabs variant="enclosed" size="sm">
      <TabList>
        <Tab>Itens</Tab>
        <Tab>Pagamentos</Tab>
        {permission && <Tab>Pendências</Tab>}
        <Tab>Sobre</Tab>
      </TabList>
      <TabPanels pt={6} outline="none" >
        <TabPanel outline="none" px={0}>
          <TabContentItens id={id} />
        </TabPanel>
        <TabPanel outline="none" p={0}>
          <TabContentPagamentos id={id} />
        </TabPanel>
        {permission &&<TabPanel outline="none" p={0}>
          <TabContentPendencias id={id} />
        </TabPanel>}
        <TabPanel outline="none">
          <TabContentSobre anunciante={anunciante} />
        </TabPanel>
      </TabPanels>
    </Tabs>
  );
};

export default AnuncianteTabs;
