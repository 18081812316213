import React from 'react';
import {
  BrowserRouter as Router,
  Link as RouterLink,
  Routes,
  Route,
  useMatch
} from 'react-router-dom';
import {
  Flex,
  Box,
  List,
  ListItem,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  MenuDivider,
  Container,
  HStack
} from '@chakra-ui/react';
import { FaUser } from 'react-icons/fa';
import styled from '@emotion/styled';

import { useGetMe } from 'actions/me';
import { useDeleteToken } from 'actions/token';
import { usePermissao } from 'customHooks';
import { useLocalStorage } from 'customHooks';
import { GlobalContext } from 'globalContext';

import Alugueis from 'pages/Alugueis';
import Anunciantes from 'pages/Anunciantes';
import Caixa from 'pages/Caixa';
import Clientes from 'pages/Clientes';
import Configuracoes from 'pages/Configuracoes';
import Inicio from 'pages/Inicio';
import Itens from 'pages/Itens';
import Lavanderias from 'pages/Lavanderias';
import Vendedoras from 'pages/Vendedoras';

import ModalLogin from './ModalLogin';

import PermissaoRoute from 'components/ui/PermissaoRoute';

const NavbarButton = React.forwardRef(
  ({ leftIcon, children, ...rest }, ref) => (
    <Box
      ref={ref}
      as="button"
      display="flex"
      alignItems="center"
      cursor="pointer"
      px={4}
      color="#555"
      fontSize="14px"
      _hover={{ color: '#333', outline: 'none' }}
      _focus={{ outline: 'none' }}
      {...rest}
    >
      {leftIcon && <Box as={leftIcon} mr={1} />}
      {children}
    </Box>
  )
);

const NavbarLink = ({ to, children }) => {
  const isActive = useMatch({ path: to + "/*", exact: false });

  return (
    <NavbarButton
      as={RouterLink}
      to={to}
      height="100%"
      boxShadow={isActive && 'inset 0 3px 8px rgba(0,0,0,0.125)'}
      data-active={isActive ? 'true' : undefined}
      bg={isActive && '#e5e5e5'}
      _hover={!isActive && { color: '#333' }}
    >
      {children}
    </NavbarButton>
  );
};


const AccountMenuItem = ({ me }) => {
  if (me && me.data.vendedora) {
    return (
      <MenuItem as={RouterLink} to={`/vendedoras/${me.data.vendedora}`}>
        Conta
      </MenuItem>
    );
  } else {
    return <MenuItem>Conta</MenuItem>;
  }
};

const MainNavbar = () => {
  const { data: me } = useGetMe();
  const logout = useDeleteToken();
  const { value: podeVerVendedora } = usePermissao(
    'vendedora.change_vendedora'
  );
  const { value: podeVerCaixa } = usePermissao('common.change_configuracoes');
  const { value: podeMudarConfiguracoes } = usePermissao(
    'common.change_configuracoes'
  );

  return (
    <Flex
      as="nav"
      height="41px"
      direction="row"
      align="center"
      bg="gray.500"
      position="fixed"
      top={0}
      width="100%"
      backgroundImage="linear-gradient(to bottom,#fff,#f2f2f2)"
      borderBottom="1px solid #d4d4d4"
      boxShadow="0 1px 10px rgba(0,0,0,0.1)"
      zIndex="docked"
      px={4}
    >
      <Container
        direction="row"
        display="flex"
        align="center"
        height="41px"
        maxWidth="6xl"
      >
        <HStack spacing={0}>
          <Flex pr={4}
            as={RouterLink}
            to="/"
            height="100%"
            align="center"
            color="#777"
            textShadow="0 1px 0 #fff"
            fontSize="lg"
            whiteSpace={"nowrap"}
          >
            My Closett
          </Flex>
          <List height="100%" display={{ base: "none", md: "flex" }} >

            <ListItem>
              <NavbarLink to="/anunciantes">Anunciantes</NavbarLink>
            </ListItem>
            <ListItem>
              <NavbarLink to="/clientes">Cliente</NavbarLink>
            </ListItem>
            {podeVerVendedora && (
              <ListItem>
                <NavbarLink to="/vendedoras">Vendedora</NavbarLink>
              </ListItem>
            )}
            <ListItem>
              <NavbarLink to="/itens">Item</NavbarLink>
            </ListItem>
            <ListItem>
              <NavbarLink to="/aluguel">Aluguel</NavbarLink>
            </ListItem>
            <ListItem>
              <NavbarLink to="/lavanderia">Lavanderia</NavbarLink>
            </ListItem>

          </List>
        </HStack>
        <Menu>
          {({ isOpen }) => (
            <>
              <MenuButton
                as={NavbarButton}
                lefticon={<FaUser />}
                ml="auto"
                {...(isOpen && { color: '#333' })}
              >
                {me && me.data.nome}
              </MenuButton>
              <MenuList>
                <MenuItem as={RouterLink} to="/anunciantes" display={{ base: "flex", md: "none" }}>
                  Anunciantes
                </MenuItem>
                <MenuItem as={RouterLink} to="/clientes" display={{ base: "flex", md: "none" }}>
                  Cliente
                </MenuItem>
                {podeVerVendedora && (
                  <MenuItem as={RouterLink} to="/vendedoras" display={{ base: "flex", md: "none" }}>
                    Vendedora
                  </MenuItem>
                )}
                <MenuItem as={RouterLink} to="/itens" display={{ base: "flex", md: "none" }}>
                  Item
                </MenuItem>
                <MenuItem as={RouterLink} to="/aluguel" display={{ base: "flex", md: "none" }}>
                  Aluguel
                </MenuItem>
                <MenuItem as={RouterLink} to="/lavanderia" display={{ base: "flex", md: "none" }}>
                  Lavanderia
                </MenuItem>


                {me && me.data.vendedora && <AccountMenuItem me={me} />}
                {podeVerCaixa && (
                  <MenuItem as={RouterLink} to="/caixa">
                    Caixa
                  </MenuItem>
                )}
                {podeMudarConfiguracoes && (
                  <MenuItem as={RouterLink} to="/configuracoes">
                    Configurações
                  </MenuItem>
                )}
                <MenuDivider />
                <MenuItem onClick={logout}>Sair</MenuItem>
              </MenuList>
            </>
          )}
        </Menu>
      </Container>
    </Flex>
  );
};

const MainContent = styled(Box)``;

const AppLogged = () => {
  return (
    <Router>
      <MainNavbar />
      <MainContent mt="41px" h="calc(100vh - 41px)">
        <Container maxWidth="6xl">
          <Routes>
            <Route path="/anunciantes/*" element={<Anunciantes />} />
            <Route path="/clientes/*" element={<Clientes />} />
            <Route path="/vendedoras/*" element={<PermissaoRoute permissao="vendedora.change_vendedora"><Vendedoras /></PermissaoRoute>} />
            <Route path="/itens/*" element={<Itens />} />
            <Route path="/aluguel/*" element={<Alugueis />} />
            <Route path="/lavanderia/*" element={<Lavanderias />} />
            <Route path="/caixa/*" element={<PermissaoRoute permissao="common.change_configuracoes"><Caixa /></PermissaoRoute>} />
            <Route path="/configuracoes/*" element={<PermissaoRoute permissao="common.change_configuracoes"><Configuracoes /></PermissaoRoute>} />
            <Route index element={<Inicio />} />
          </Routes>
        </Container>
      </MainContent>
    </Router>
  );
};

const App = () => {
  const [token, setToken] = useLocalStorage('myclosett:token');

  return (
    <GlobalContext.Provider value={{ setToken }}>
      {token ? <AppLogged /> : <ModalLogin />}
    </GlobalContext.Provider>
  );
};

export default App;
