import React from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Stack,
  Box,
  Text,
  Button,
  Tag,
  useDisclosure
} from '@chakra-ui/react';

import {
  useGetAnuncianteTotalPendente,
  useGetAnunciantePendencias
} from 'actions/anunciantes';
import { usePostAluguelItensEmail } from 'actions/aluguelItens';
import {
  DisplayText,
  Paginator,
  Table,
  TableHead,
  TableBodyZebra,
  TableRow,
  TableHeader,
  TableData
} from 'components/ui';
import { ConfirmDialog } from 'components/ui';
import { useToastList } from 'customHooks';
import { formatCurrency, formatDate } from 'utils/ui';

import ModalPagamento from './ModalPagamento';

const StatusMap = {
  DELIVERED: {
    color: 'green',
    label: 'Entregue'
  },
  FAILED: {
    color: 'red',
    label: 'Erro'
  },
};

const Status = ({ value }) => {
  const status = StatusMap[value]
  if (!status) return '—';
  return <Tag colorScheme={status.color}>{status.label || value}</Tag>;
};

const TableRowPendencia = ({ item, anuncianteId }) => {
  const {
    isOpen: isOpenEmail,
    onOpen: onOpenEmail,
    onClose: onCloseEmail
  } = useDisclosure();

  const {
    isOpen: isOpenPagamento,
    onOpen: onOpenPagamento,
    onClose: onClosePagamento
  } = useDisclosure();

  const {mutateAsync: enviarEmail, status } = usePostAluguelItensEmail(item.id);
  const toastList = useToastList();
  const history = useNavigate();

  const isSubmitting = status === 'loading';

  const enviarEmailParaAnunciante = async () => {
    try {
      await enviarEmail();
      onCloseEmail();
    } catch (er) {
      toastList({ messages: er.errors, status: 'error' });
    }
  };

  const dataRetirada = item.aluguel_data_retirada
    ? formatDate(item.aluguel_data_retirada)
    : '';

  const dataEvento = item.aluguel_data_evento
    ? formatDate(item.aluguel_data_evento)
    : '';

  const dataDevolucao = item.aluguel_data_devolucao
    ? formatDate(item.aluguel_data_devolucao)
    : '';

  const valorPrevistoAnunciante = item.total_pagamento_anunciante
    ? formatCurrency(item.total_pagamento_anunciante)
    : '—';

  return (
    <>
      <TableRow key={item.id} onClick={() => history(`/aluguel/${item.aluguel}`)}>
        <TableData>{item.item_referencia}</TableData>
        <TableData>{dataEvento}</TableData>
        <TableData>
          {item.valor_previsto_cliente
            ? formatCurrency(item.valor_previsto_cliente)
            : '—'}
        </TableData>
        <TableData>{valorPrevistoAnunciante}</TableData>
        <TableData>
          <Status value={item.email_status} />
        </TableData>
        <TableData>
          <Button size="xs" variant="outline" onClick={onOpenEmail}>
            Enviar
          </Button>
        </TableData>
        <TableData>
          <Button size="xs" variant="outline" onClick={onOpenPagamento}>
            Pagar
          </Button>
        </TableData>
      </TableRow>
      <ConfirmDialog
        isLoading={isSubmitting}
        isOpen={isOpenEmail}
        onClose={onCloseEmail}
        onConfirm={enviarEmailParaAnunciante}
        zIndexOverlay="modal"
        title="Notificação de Aluguel"
        confirmButtonText="Enviar"
        cancelButtonText="Cancelar"
      >
        <Text fontSize="sm" mb={2}>
          Deseja enviar um email para a anunciante com as seguintes informações?
        </Text>
        <Box as="ul" mx={4}>
          <Text as="li" fontSize="sm">
            Data de Retirada: {dataRetirada}
          </Text>
          <Text as="li" fontSize="sm">
            Data do Evento: {dataEvento}
          </Text>
          <Text as="li" fontSize="sm">
            Data de Devolução: {dataDevolucao}
          </Text>
          <Text as="li" fontSize="sm">
            Valor Previsto a Receber: {valorPrevistoAnunciante}
          </Text>
        </Box>
      </ConfirmDialog>
      <ModalPagamento
        isOpen={isOpenPagamento}
        onClose={onClosePagamento}
        item={item}
        anuncianteId={anuncianteId}
      />
    </>
  );
};

const TabContentPendencias = ({ id }) => {
  const [page, setPage] = React.useState(1);
  const {
    data: totalPendente,
    status: statusTotalPendente
  } = useGetAnuncianteTotalPendente({ id });
  const {
    data: pendencias,
    status,
    hasNext
  } = useGetAnunciantePendencias({
    id,
    page
  });

  if (status === 'loading' || statusTotalPendente === 'loading')
    return <DisplayText>Carregando...</DisplayText>;

  return (
    <Stack>
      <Stack as="dl" isInline spacing={4} fontSize="xl" justify="center">
        <Box as="dt">Total Pendente</Box>
        <Box as="dd">
          <Text as="abbr" cursor="help" textDecor="underline dotted">
            {formatCurrency(totalPendente.data.valor_total)}
          </Text>
        </Box>
      </Stack>
      <Table>
        <TableHead>
          <TableRow>
            <TableHeader>Id</TableHeader>
            <TableHeader>Data Evento</TableHeader>
            <TableHeader>Valor Total</TableHeader>
            <TableHeader>Valor Anunciante</TableHeader>
            <TableHeader>Status Email</TableHeader>
            <TableHeader>Email</TableHeader>
            <TableHeader>Pagamento</TableHeader>
          </TableRow>
        </TableHead>
        <TableBodyZebra>
          {pendencias.data.results.length === 0 ? (
            <TableRow>
              <TableData colSpan="7">Nenhuma informação disponível</TableData>
            </TableRow>
          ) : (
            pendencias.data.results.map(item => (
              <TableRowPendencia key={item.id} item={item} anuncianteId={id} />
            ))
          )}
        </TableBodyZebra>
      </Table>
      <Paginator page={page} onPaginate={setPage} hasNext={hasNext} />
    </Stack>
  );
};

export default TabContentPendencias;
