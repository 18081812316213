import React from 'react';
import { Stack, Flex, IconButton } from '@chakra-ui/react';
import { FaArrowLeft, FaArrowRight } from 'react-icons/fa';
import { setMonth, setYear, getMonth, getYear, addMonths, subMonths } from 'date-fns';

const DatePaginator = ({ mes, ano, onPaginate, isDisabled, ...rest }) => {
  const date = setYear(setMonth(new Date(), mes-1), ano)

  return (
    <Stack as={Flex} isInline justify="center" {...rest}>
      <IconButton
        aria-label="Ir para página anterior"
        variant="outline"
        size="sm"
        isRound
        onClick={() => {
          if (onPaginate) {
            const datePrevious = subMonths(date, 1);
            onPaginate({ mes: getMonth(datePrevious) + 1, ano: getYear(datePrevious) });
          }
        }}
        isDisabled={isDisabled}
        icon={<FaArrowLeft/>}
      />
      <IconButton
        aria-label="Ir para página posterior"
        variant="outline"
        size="sm"
        isRound
        onClick={() => {
          if (onPaginate) {
            const dateNext = addMonths(date, 1);
            onPaginate({ mes: getMonth(dateNext) + 1, ano: getYear(dateNext) });
          }
        }}
        isDisabled={isDisabled}
        icon={<FaArrowRight/>}
      />
    </Stack>
  );
};

export default DatePaginator;
