import React from 'react';
import { Routes, Route } from 'react-router-dom';

import PermissaoRoute from 'components/ui/PermissaoRoute';

import Aluguel from './Aluguel';
import Formulario from './Formulario';
import FormularioLavanderia from './FormularioLavanderia';
import Lista from './Lista';

const Alugueis = () => {

  return (
    <Routes>
      <Route path={`:id`} element={<PermissaoRoute permissao="aluguel.view_aluguel"><Aluguel/></PermissaoRoute>} />
      <Route path={`form`} element={<PermissaoRoute permissao="aluguel.add_aluguel"><Formulario/></PermissaoRoute>} />
      <Route path={`:id/form`} element={<PermissaoRoute permissao="aluguel.change_aluguel"><Formulario/></PermissaoRoute>} />
      <Route path={`cliente/:idCliente/form`} element={<PermissaoRoute permissao="aluguel.change_aluguel"><Formulario/></PermissaoRoute>} />
      <Route path={`:id/lavanderia/form`} element={<PermissaoRoute permissao="aluguel.change_aluguel"><FormularioLavanderia/></PermissaoRoute>} />
      <Route index element={<PermissaoRoute permissao="aluguel.view_aluguel"><Lista/></PermissaoRoute>} />
    </Routes>
  );
};

export default Alugueis;
