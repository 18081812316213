import { useGetPermissoes } from 'actions/permissoes';

const usePermissao = permissao => {
  const { status, data: permissoes } = useGetPermissoes();
  const isLoading = status === 'loading';
  const value = permissoes && permissoes.data && permissoes.data.includes(permissao);
  return {isLoading, value};
};

export default usePermissao;
