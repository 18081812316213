import mapValues from 'lodash/mapValues';
import isEmpty from 'lodash/isEmpty';
import isBoolean from 'lodash/isBoolean';
import get from 'lodash/get';
import { format, parseISO, isValid } from 'date-fns';

export const prepareInputValues = data =>
  mapValues(data, fieldValue =>
    !fieldValue && !isBoolean(fieldValue) ? '' : fieldValue
  );

export const prepareInputCurrencyValue = value =>
  value ? value.replace('.', ',') : '';

export const prepareInputPercentValue = value =>
  value ? `${(parseFloat(value) * 100).toFixed(2)}`.replace('.', ',') : '';

export const prepareInputDateValue = (value, path) => {
  const _value = path ? get(value, path) : value;
  const data = parseISO(_value);
  return isValid(data) ? format(data, 'yyy-MM-dd') : '';
};

export const prepareInputBooleanValue = value => {
  return isBoolean(value) ? `${value}` : '';
};

export const prepareInputFileValue = async urlValue => {
  if (!urlValue) return urlValue;
  if (urlValue === '/static/img/item/default.png') return null;
  //const paths = urlValue.split('/');
  //const fileName = paths[paths.length - 1];
  const resp = await fetch(urlValue, {
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json'
    }
  });
  const blob = await resp.blob();
  return new File([blob], '');
};

export const prepareOutputValues = (data, defaultValue=null) =>
  mapValues(data, fieldValue => (isEmpty(fieldValue) ? defaultValue : fieldValue));

export const prepareOutputCurrencyValue = value =>
  value ? value.replace('.', '').replace(',', '.') : null;

export const prepareOutputPercentValue = value =>
  value ? (parseFloat(value.replace(',', '.')) / 100).toFixed(4) : null;

export const prepareOutputDateValue = value => {
  const data = parseISO(value);
  return isValid(data) ? format(data, 'yyy-MM-dd') : null;
};
