import React from 'react';
import {
  useNavigate,
  Link as RouterLink
} from 'react-router-dom';
import {
  Stack,
  Menu,
  IconButton,
  MenuList,
  MenuButton,
  MenuDivider,
  useDisclosure
} from '@chakra-ui/react';
import { FaChevronDown, FaPen, FaTrash } from 'react-icons/fa';

import { useDeleteVendedora } from 'actions/vendedoras';
import { ConfirmDialog, IconMenuItem } from 'components/ui';
import { useToastList } from 'customHooks';

const AcoesButton = ({ vendedora }) => {
  const { id, nome, is_current_user } = vendedora;
  const history = useNavigate();
  const toastList = useToastList();

  const {
    isOpen: isOpenConfirmacaoRemover,
    onClose: onCloseConfirmacaoRemover,
    onOpen: onOpenConfirmacaoRemover
  } = useDisclosure();

  const {mutateAsync: apagar, status: statusApagar } = useDeleteVendedora(id);

  const apagarVendedora = async () => {
    try {
      await apagar();
      history(`/vendedoras`);
    } catch (er) {
      toastList({ messages: er.errors, status: 'error' });
    }
  };

  return (
    <Stack isInline spacing={0} ml="auto">
      <Menu placement="bottom-end">
        <MenuButton
          as={IconButton}
          size="sm"
          variant="outline"
          icon={<FaChevronDown/>}
        />
        <MenuList minWidth="150px" >
          <IconMenuItem icon={FaPen} as={RouterLink} to={`form`}>
            Editar
          </IconMenuItem>
          {is_current_user && (
            <IconMenuItem
              icon={FaPen}
              as={RouterLink}
              to={`senha/form`}
            >
              Senha
            </IconMenuItem>
          )}
          <MenuDivider />
          <IconMenuItem icon={FaTrash} onClick={onOpenConfirmacaoRemover}>
            Remover
          </IconMenuItem>
        </MenuList>
      </Menu>
      <ConfirmDialog
        isLoading={statusApagar === 'loading'}
        isOpen={isOpenConfirmacaoRemover}
        onClose={onCloseConfirmacaoRemover}
        onConfirm={apagarVendedora}
        zIndexOverlay="modal"
        title="Remover Vendedora"
        confirmButtonText="Sim"
        cancelButtonText="Não"
      >
        Deseja remover a vendedora <strong>{nome}</strong>
      </ConfirmDialog>
    </Stack>
  );
};

export default AcoesButton;
