import { useTheme } from '@chakra-ui/react';

export default function useSelectStyles({
  isInvalid,
  size = 'sm',
  getColorValue,
  width,
  maxW,
  mr
}) {
  const { sizes, radii, colors, fontSizes, space } = useTheme();

  const theme = _theme => {
    return {
      ..._theme,
      borderRadius: sizes[1],
      colors: {
        ..._theme.colors,
        primary: colors.blue['500'],
        primary75: colors.gray['200'],
        primary50: colors.gray['100'],
        primary25: colors.gray['50'],
        danger: colors.red['500'],
        dangerLight: colors.red['300']
      }
    };
  };

  const styles = {
    container: provided => {
      const _styles = {
        width: (width && sizes[width]) || width,
        maxWidth: (maxW && sizes[maxW]) || maxW,
        marginRight: (mr && space[mr]) || mr
      };

      return { ...provided, ..._styles };
    },
    control: (provided, state) => {
      const _styles = {
        borderColor: colors.gray[200]
      };

      const hoverStyles = {
        borderColor: colors.gray[300]
      };

      if (state.isDisabled) {
        _styles.opacity = 0.4;
        _styles.cursor = 'not-allowed';
        _styles.pointerEvents = 'initial';
        _styles.backgroundColor = 'transparent';
      }

      if (state.isFocused) {
        _styles.boxShadow = `0 0 0 1px ${colors.blue['500']}`;
        _styles.borderColor = colors.blue['500'];
        hoverStyles.borderColor = colors.blue['500'];
      }

      if (isInvalid) {
        _styles.boxShadow = `0 0 0 1px ${colors.red['500']}`;
        _styles.borderColor = colors.red['500'];
        hoverStyles.borderColor = colors.red['500'];
      }

      if (size === 'sm') {
        _styles.height = sizes['8'];
        _styles.minHeight = sizes['8'];
        _styles.borderRadius = radii.sm;
        _styles.fontSize = fontSizes.sm;
      }

      return {
        ...provided,
        ..._styles,
        '&:hover': {
          ...hoverStyles
        }
      };
    },
    indicatorsContainer: provided => {
      const _styles = {};

      if (size === 'sm') {
        _styles.height = '1.9rem';
        _styles.minHeight = '1.9rem';
      }

      return {
        ...provided,
        ..._styles
      };
    },
    input: provided => {
      const _styles = {};

      if (size === 'sm') {
        _styles.margin = '0rem';
      }

      return {
        ...provided,
        ..._styles
      };
    },
    multiValueLabel: _styles => ({
      ..._styles,
      color: getColorValue ? 'white' : _styles.color
    }),
    multiValue: (_styles, { data }) => {
      return {
        ..._styles,
        color: getColorValue ? 'white' : _styles.color,
        backgroundColor: getColorValue
          ? getColorValue(data)
          : _styles.backgroundColor
      };
    }
  };

  return { theme, styles };
}
