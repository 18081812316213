import axios from 'axios';
import { useMutation, useQueryClient } from '@tanstack/react-query';

import { useGlobalContext } from 'globalContext';

import { QueryKeys as PermissoesQueryKeys } from './permissoes';
import { QueryKeys as MeQueryKeys } from './me';

const useAxiosPost = (endpoint, config) => {
  return async payload => {
    const { data } = await axios.post(`/api/${endpoint}`, payload, config);
    return data;
  };
};

export const usePostToken = () => {
  const post = useAxiosPost('token');
  return useMutation(post);
};

export const useDeleteToken = () => {
  const queryClient = useQueryClient();
  const { setToken } = useGlobalContext();

  return () => {
    setToken(null);
    queryClient.removeQueries([MeQueryKeys.ME]);
    queryClient.removeQueries([PermissoesQueryKeys.PERMISSOES]);
  };
};
