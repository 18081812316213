import React from 'react';
import { Box } from '@chakra-ui/react';
import ReactMasonry from 'react-masonry-css';
import { Global, css } from '@emotion/react';

const GUTTER_SPACE = '15px';

const stylesMasonry = css`
  .my-masonry-grid {
    display: flex;
    margin-left: -${GUTTER_SPACE};
    width: auto;
  }
  .my-masonry-grid_column {
    padding-left: ${GUTTER_SPACE};
    background-clip: padding-box;
  }
`;

export const MasonryItem = props => {
  return (
    <Box
      display="flex"
      justifyContent="center"
      mb={GUTTER_SPACE}
      verticalAlign="top"
      borderRadius="md"
      border="1px"
      borderColor="gray.300"
      _hover={{ borderColor: 'blue.500' }}
      transition="all 0.5s"
      boxShadow="sm"
      {...props}
    />
  );
};

export const Masonry = ({ children }) => {
  const breakpointColumnsObj = {
    default: 4,
    1100: 4,
    700: 2,
    500: 1
  };

  return (
    <>
      <Global styles={stylesMasonry} />
      <ReactMasonry
        breakpointCols={breakpointColumnsObj}
        className="my-masonry-grid"
        columnClassName="my-masonry-grid_column"
      >
        {children}
      </ReactMasonry>
    </>
  );
};

/* Testes
<Brick>
<Image src={require('mockImgs/cherry-plant.jpg')} />
</Brick>
<Brick>
<Image src={require('mockImgs/oranges-pomegranates.jpg')} />
</Brick>
<Brick>
<Image src={require('mockImgs/strawberry.jpg')} />
</Brick>
<Brick>
<Image src={require('mockImgs/blueberries.jpg')} />
</Brick>
<Brick>
<Image src={require('mockImgs/pears.jpg')} />
</Brick>
<Brick>
<Image src={require('mockImgs/easter-eggs.jpg')} />
</Brick>
<Brick>
<Image src={require('mockImgs/lemons.jpg')} />
</Brick>
<Brick>
<Image src={require('mockImgs/cherries.jpg')} />
</Brick>
<Brick>
<Image src={require('mockImgs/grapes.jpg')} />
</Brick> 
*/
