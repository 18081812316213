import React from 'react';
import find from 'lodash/find';

import { useGetOptionsVendedoras } from 'actions/options';
import { FormControlSelect } from 'components/form';

const FormControlSelectVendedora = ({ isEditing, defaultValue, ...rest }) => {
  const { data, status } = useGetOptionsVendedoras();
  const options = React.useMemo(
    () =>
      data &&
      data.data.map(({ id, nome }) => ({
        value: id,
        label: nome
      })),
    [data]
  );
  const initialValue = React.useMemo(
    () => find(options, ['value', defaultValue]),
    [defaultValue, options]
  );

  return (
    <FormControlSelect
      label="Vendedora"
      name="vendedora"
      isLoading={status === 'loading'}
      isDisabled={isEditing}
      options={options}
      maxW={300}
      initialValue={initialValue}
      selectFirst={false}
      {...rest}
    />
  );
};

export default FormControlSelectVendedora;
