import React from 'react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useQuery } from 'customHooks';

import { useGet, usePost, usePut, useDelete } from 'customHooks/api';
import { usePaginatedPrefetchQuery } from 'customHooks';

export const QueryKeys = {
  ALUGUEL: 'aluguel',
  ALUGUEL_LAVANDERIAS: 'aluguel/lavanderias',
  ALUGUEIS: 'alugueis',
  ALUGUEL_CONTRATO: 'aluguel/retiradas',
  ALUGUEIS_RETIRADAS: 'aluguel/retiradas',
  ALUGUEIS_DEVOLUCOES: 'aluguel/devolucoes'
};

export const useGetAluguel = id => {
  const getAluguel = useGet('alugueis/{id}');
  return useQuery({
    queryKey: [QueryKeys.ALUGUEL, { id: `${id}` }],
    queryFn:getAluguel,
    enabled: !!id
  });
};

export const useGetAluguelLavanderias = id => {
  const getLavanderias = useGet('alugueis/{id}/lavanderias');
  return useQuery({
    queryKey: [QueryKeys.ALUGUEL_LAVANDERIAS, { id: `${id}` }],
    queryFn:getLavanderias,
    enabled: !!id
  });
};

export const useGetAlugueis = filtros => {
  const queryKey = React.useMemo(() => [QueryKeys.ALUGUEIS, filtros], [
    filtros
  ]);
  const getAlugueis = useGet('alugueis');
  return usePaginatedPrefetchQuery(queryKey, getAlugueis);
};

export const useGetAluguelContrato = (id, queryConfig) => {
  const getContrato = useGet('alugueis/{id}/contrato', {
    responseType: 'blob'
  });
  return useQuery(
    [QueryKeys.ALUGUEL_CONTRATO, { id: `${id}` }],
    getContrato,
    queryConfig
  );
};

export const useGetAlugueisRetiradas = filtros => {
  const queryKey = React.useMemo(
    () => [QueryKeys.ALUGUEIS_RETIRADAS, filtros],
    [filtros]
  );
  const getAlugueis = useGet('alugueis/retiradas');
  return usePaginatedPrefetchQuery(queryKey, getAlugueis);
};

export const useGetAlugueisDevolucoes = filtros => {
  const queryKey = React.useMemo(
    () => [QueryKeys.ALUGUEIS_DEVOLUCOES, filtros],
    [filtros]
  );
  const getAlugueis = useGet('alugueis/devolucoes');
  return usePaginatedPrefetchQuery(queryKey, getAlugueis);
};

export const usePostAluguel = queryConfig => {
  const postAluguel = usePost('alugueis');
  return useMutation(postAluguel, queryConfig);
};

export const usePostAluguelContrato = id => {
  const queryClient = useQueryClient();
  const postContrato = usePost(`alugueis/${id}/contrato`);
  return useMutation(postContrato, {
    onSuccess: data =>
    queryClient.setQueryData([QueryKeys.ALUGUEL, { id: `${id}` }], data)
  });
};

export const usePostAluguelLavanderias = id => {
  const queryClient = useQueryClient();
  const postLavanderias = usePost('alugueis/{id}/lavanderias');
  return useMutation(postLavanderias, {
    onSuccess: data =>
      queryClient.setQueryData(
        [QueryKeys.ALUGUEL_LAVANDERIAS, { id: `${id}` }],
        data
      )
  });
};

export const usePostAluguelEfetuarRetirada = id => {
  const queryClient = useQueryClient();
  const postEfetuarRetirada = usePost(`alugueis/${id}/efetuar_retirada`);
  return useMutation(postEfetuarRetirada, {
    onSuccess: data =>
      queryClient.setQueryData([QueryKeys.ALUGUEL, { id: `${id}` }], data)
  });
};

export const usePostAluguelEfetuarDevolucao = id => {
  const queryClient = useQueryClient();
  const postEfetuarDevolucao = usePost(`alugueis/${id}/efetuar_devolucao`);
  return useMutation(postEfetuarDevolucao, {
    onSuccess: data =>
      queryClient.setQueryData([QueryKeys.ALUGUEL, { id: `${id}` }], data)
  });
};

export const usePostAluguelCancelar = id => {
  const queryClient = useQueryClient();
  const postCancelar = usePost(`alugueis/${id}/cancelar`);
  return useMutation(postCancelar, {
    onSuccess: data =>
      queryClient.setQueryData([QueryKeys.ALUGUEL, { id: `${id}` }], data)
  });
};

export const usePutAluguel = id => {
  const queryClient = useQueryClient();
  const putAluguel = usePut(`alugueis/${id}`);
  return useMutation(putAluguel, {
    onSuccess: data =>
      queryClient.setQueryData([QueryKeys.ALUGUEL, { id: `${id}` }], data)
  });
};

export const useDeleteAluguel = id => {
  const deleteAluguel = useDelete(`alugueis/${id}`);
  return useMutation(deleteAluguel);
};

export const useDownloadAluguelContrato = id => {
  const [isFetching, setFetching] = React.useState(false);
  const { refetch: recuperarContrato } = useGetAluguelContrato(id, {
    enabled: false
  });
  const { mutateAsync: criarContrato } = usePostAluguelContrato(id);

  const fetch = async values => {
    try {
      setFetching(true);
      if (values) await criarContrato(values);
      const res = await recuperarContrato();

      const url = URL.createObjectURL(res.data.data);
      const element = document.createElement('a');
      setFetching(false);

      element.setAttribute('href', url);
      element.setAttribute('download', `contrato-aluguel-${id}.pdf`);
      element.setAttribute('target', '_blank');
      element.style.display = 'none';
      document.body.appendChild(element);
      element.click();
      document.body.removeChild(element);
    } catch (er) {
      setFetching(false);
      throw er;
    }
  };

  return [fetch, { isFetching }];
};