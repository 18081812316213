import React from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Button, Stack, Heading } from '@chakra-ui/react';
import * as yup from 'yup';

import { usePostVendedoraSenha, useGetVendedora } from 'actions/vendedoras';
import {
  Form,
  FormFieldset,
  FormControlInput,
  FormControlSelect,
  FormGroupButton
} from 'components/form';
import { DisplayText } from 'components/ui';
import { useDocumentTitle } from 'customHooks';
import {
  prepareInputValues,
} from 'utils/form';

const validationSchema = yup.object().shape({
  senha_atual: yup.string().required().label('Senha atual'),
  senha_nova: yup.string().min(8).required().label('Senha nova'),
  senha_confirma: yup
    .string()
    .oneOf([yup.ref('senha_nova'), null], 'As senhas não conferem')
    .required()
    .label('Confirme senha nova')
});

const FormularioTrocarSenha = () => {
  const { id } = useParams();
  const history = useNavigate();
  useDocumentTitle('Trocar Senha');
  const { data: vendedora, status: statusVendedora } = useGetVendedora(id);
  let defaultValues = prepareInputValues(vendedora ? vendedora.data : {});
  const user_group = defaultValues['user_group'] || "Vendedora";
  defaultValues['user_group'] = {value: user_group, label: user_group}

  const { mutateAsync: trocarSenha, status } = usePostVendedoraSenha();

  const onSubmit = async data => {
    await trocarSenha({ id, ...data });
    history(`/vendedoras/${id}`);

  };
  const isLoadingVendedora = statusVendedora === 'loading';
  const options = React.useMemo(
    () => [
      { value: "Vendedora", label:  "Vendedora" },
      { value: "Leitura", label:  "Leitura" },
      { value: "Gerente", label:  "Gerente" },
    ],
    []
  );

  if (isLoadingVendedora) return <DisplayText>Carregando...</DisplayText>;

  return (
    <Stack spacing={4} p={4}>
      <Heading size="lg">Trocar senha</Heading>
      <Form onSubmit={onSubmit} validationSchema={validationSchema} defaultValues={defaultValues}>
        <FormFieldset>
          <FormControlInput
            label="Login"
            name="user_username"
            maxW={400}
            isDisabled={true}
          />
          <FormControlSelect
            label="Grupo"
            name="user_group"
            isDisabled={true}
            options={options}
            maxW={400}
            selectFirst={false}
          />
          <FormControlInput
            label="Senha atual"
            name="senha_atual"
            maxW={400}
            type="password"
          />
          <FormControlInput
            label="Senha nova"
            name="senha_nova"
            maxW={400}
            type="password"
          />
          <FormControlInput
            label="Confirme senha nova"
            name="senha_confirma"
            maxW={400}
            type="password"
          />
        </FormFieldset>
        <FormGroupButton>
          <Button
            size="sm"
            colorScheme="blue"
            type="submit"
            loadingText="Salvando"
            isLoading={status === 'loading'}
          >
            Salvar
          </Button>
        </FormGroupButton>
      </Form>
    </Stack>
  );
};

export default FormularioTrocarSenha;
