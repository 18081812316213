import { useQuery } from 'customHooks';

import { useGet } from 'customHooks/api';

export const QueryKeys = {
  TIPO_PAGAMENTO_ANUNCIANTE: 'tipo_pagamento_anunciante',
  TIPO_PAGAMENTO_CLIENTE: 'tipo_pagamento_cliente',
  STATUS_ALUGUEL: 'status_aluguel',
  STATUS_LAVANDERIA: 'status_lavanderia',
  TIPO_EVENTO: 'tipo_evento',
  PAPEL_EVENTO: 'papel_evento',
  ESTADO_CIVIL: 'estado_civil'
};

export const useGetTipoPagamentoAnunciante = (condition = true) => {
  const getOptions = () =>
    new Promise(resolve =>
      resolve({
        data: [
          { value: 'DINHEIRO', label: 'Dinheiro' },
          { value: 'DEPOSITO', label: 'Depósito' },
          { value: 'CHEQUE', label: 'Cheque' },
          { value: 'CARTAO', label: 'Cartão' },
          { value: 'VALE', label: 'Vale' },
          { value: 'OUTRO', label: 'Outro' }
        ]
      })
    );
  return useQuery({
    queryKey: [QueryKeys.TIPO_PAGAMENTO_ANUNCIANTE],
    queryFn:getOptions,
    enabled: Boolean(condition)
  });
};

export const useGetTipoPagamentoClientes = (condition = true) => {
  const getOptions = () =>
    new Promise(resolve =>
      resolve({
        data: [
          { value: 'DINHEIRO', label: 'Dinheiro' },
          { value: 'DEPOSITO', label: 'Depósito' },
          { value: 'CHEQUE', label: 'Cheque' },
          { value: 'CARTAO', label: 'Cartão' },
          { value: 'CARTAO_DEBITO', label: 'Cartão de Débito' },
          { value: 'CARTAO_CREDITO_1X', label: 'Cartão de Crédito 1x' },
          { value: 'CARTAO_CREDITO_2X', label: 'Cartão de Crédito 2x' },
          { value: 'CARTAO_CREDITO_3X', label: 'Cartão de Crédito 3x' },
          { value: 'VALE', label: 'Vale' },
          { value: 'OUTRO', label: 'Outro' }
        ]
      })
    );
  return useQuery({
    queryKey: [QueryKeys.TIPO_PAGAMENTO_CLIENTE],
    queryFn:getOptions,
    enabled: Boolean(condition)
  });
};


export const useGetStatusAluguel = (condition = true) => {
  const getOptions = () =>
    new Promise(resolve =>
      resolve({
        data: [
          { value: 'RESERVA', label: 'Reserva' },
          { value: 'ANDAMENTO', label: 'Andamento' },
          { value: 'FINALIZADO', label: 'Finalizado' },
          { value: 'CANCELADO', label: 'Cancelado' }
        ]
      })
    );
  return useQuery({
    queryKey: [QueryKeys.STATUS_ALUGUEL],
    queryFn:getOptions,
    enabled: Boolean(condition)
  });
};

export const useGetStatusLavanderia = (condition = true) => {
  const getOptions = () =>
    new Promise(resolve =>
      resolve({
        data: [
          { value: 'ANDAMENTO', label: 'Andamento' },
          { value: 'AGENDADO', label: 'Agendado' },
          { value: 'FINALIZADO', label: 'Finalizado' },
        ]
      })
    );
  return useQuery({
    queryKey: [QueryKeys.STATUS_LAVANDERIA],
    queryFn:getOptions,
    enabled: Boolean(condition)
  });
};

export const useGetTipoEvento = () => {
  const getTipoEvento = useGet('enums/tipo_evento');
  return useQuery([QueryKeys.TIPO_EVENTO], getTipoEvento);
};

export const useGetPapelEvento = () => {
  const getPapelEvento = useGet('enums/papel_evento');
  return useQuery([QueryKeys.PAPEL_EVENTO], getPapelEvento);
};

export const useGetEstadoCivil = () => {
  const getEstadoCivil = useGet('enums/estado_civil');
  return useQuery([QueryKeys.ESTADO_CIVIL], getEstadoCivil);
};
