import React from 'react';
import { Routes, Route } from 'react-router-dom';

import PermissaoRoute from 'components/ui/PermissaoRoute';

import Formulario from './Formulario';
import Lista from './Lista';

const Anunciantes = () => {
  
  return (
    <Routes>
      <Route path={`add`} exact element={<PermissaoRoute permissao="lavanderia.add_empresalavanderia" ><Formulario/></PermissaoRoute>} />
      <Route path={`:id/form`} element={<PermissaoRoute permissao="lavanderia.change_empresalavanderia"><Formulario/></PermissaoRoute>} />
      <Route index element={<PermissaoRoute permissao="lavanderia.view_empresalavanderia"><Lista/></PermissaoRoute>} />
    </Routes>
  );
};

export default Anunciantes;
