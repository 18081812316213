import React from 'react';
import {
  Stack,
  ButtonGroup,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalFooter,
  ModalBody,
  IconButton,
  Divider
} from '@chakra-ui/react';
import { FaTimes, FaPlus } from 'react-icons/fa';
import * as yup from 'yup';
import { format } from 'date-fns';
import reduce from 'lodash/reduce';

import { usePostAluguelItensPagamentosAnunciante } from 'actions/aluguelItens';
import { useGetTipoPagamentoAnunciante } from 'actions/enums';
import {
  Form,
  FormTableRow,
  FormTableCell,
  FormSelect,
  FormInputGroup,
  FormInputDate,
  FormInputCurrency,
  useFormFieldArray,
  useWatch
} from 'components/form';
import {
  prepareInputCurrencyValue,
  prepareInputDateValue,
  prepareOutputCurrencyValue,
  prepareOutputDateValue
} from 'utils/form';
import { formatCurrency } from 'utils/ui';

const validationSchema = yup.object().shape({
  pagamentos_anunciante: yup
    .array()
    .of(
      yup.object().shape({
        tipo: yup.object().nullable().required().label('Tipo'),
        valor: yup.string().required().label('Valor'),
        data: yup.string().required().label('Data')
      })
    )
    .required('Deve haver ao menos um pagamento')
});

const ModalBodyContent = () => {
  const fieldArrayName = 'pagamentos_anunciante';
  const { data: tipos, status: statusTipos } = useGetTipoPagamentoAnunciante();
  const { fields, append, remove } = useFormFieldArray({
    name: fieldArrayName
  });
  const allFieldsValues = useWatch();

  const isLoadingTipos = statusTipos === 'loading';

  const valores = allFieldsValues[fieldArrayName].map(x => x.valor);

  const totalPago = reduce(
    valores,
    (result, valor) => {
      const valorNumero = parseFloat(prepareOutputCurrencyValue(valor));
      return valorNumero ? (result += valorNumero) : result;
    },
    0
  );

  return (
    <>
      <FormTableRow px={4} py={2}>
        <FormTableCell>Tipo</FormTableCell>
        <FormTableCell>Valor</FormTableCell>
        <FormTableCell>Data</FormTableCell>
      </FormTableRow>
      <Divider mt={0} />
      <Stack>
        {fields.map((field, index) => {
          return (
            <FormTableRow key={field.id}>
              <FormSelect
                name={`${fieldArrayName}.${index}.tipo`}
                isLoading={isLoadingTipos}
                options={!isLoadingTipos && tipos.data}
                selectFirst={false}
              />
              <FormInputGroup
                name={`${fieldArrayName}.${index}.valor`}
                inputComponent={FormInputCurrency}
                leftAddon="R$"
                placeholder="Valor"
                width="100%"
                defaultValue={field.valor}
              />
              <FormInputDate
                name={`${fieldArrayName}.${index}.data`}
                width="100%"
                defaultValue={field.data}
              />
              <IconButton
                size="sm"
                aria-label="Sair"
                variant="ghost"
                icon={<FaTimes />}
                onClick={() => remove(index)}
              />
            </FormTableRow>
          );
        })}
        <FormTableRow bg="yellow.100" px={4} py={2}>
          <FormTableCell>Total Pago</FormTableCell>
          <FormTableCell>{formatCurrency(totalPago)}</FormTableCell>
        </FormTableRow>
        <Button
          size="sm"
          colorScheme="green"
          leftIcon={<FaPlus />}
          onClick={() =>
            append({
              valor: '0',
              data: prepareInputDateValue(format(new Date(), 'yyyy-MM-dd'))
            })
          }
        >
          Adicionar pagamento
        </Button>
      </Stack>
    </>
  );
};

const ModalPagamento = ({ isOpen, onClose, item, anuncianteId }) => {
  const initRef = React.useRef();
  const {
    mutateAsync: realizarPagamentos,
    status
  } = usePostAluguelItensPagamentosAnunciante(item.id, anuncianteId);

  const isSubmitting = status === 'loading';

  const onSubmit = async data => {

    const pagamentos_anunciante = data.pagamentos_anunciante.map(
      pagamento => ({
        tipo: pagamento.tipo ? pagamento.tipo.value : null,
        valor: prepareOutputCurrencyValue(pagamento.valor),
        data: prepareOutputDateValue(pagamento.data)
      })
    );

    await realizarPagamentos({ pagamentos_anunciante });

    onClose();

  };

  const defaultValues = {
    pagamentos_anunciante: [
      {
        valor: prepareInputCurrencyValue(item.valor_previsto_anunciante),
        data: prepareInputDateValue(format(new Date(), 'yyyy-MM-dd'))
      }
    ]
  };

  return (
    <Modal
      closeOnOverlayClick={false}
      size="xl"
      initialFocusRef={initRef}
      isOpen={isOpen}
      onClose={onClose}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Pagamento</ModalHeader>
        <ModalCloseButton />
        <Form
          onSubmit={onSubmit}
          validationSchema={validationSchema}
          defaultValues={defaultValues}
          spacing={0}
          align="flex-end"
        >
          <ModalBody width="100%">
            <ModalBodyContent />
          </ModalBody>
          <ModalFooter>
            <ButtonGroup size="sm">
              <Button
                type="submit"
                loadingText="Pagando"
                colorScheme="blue"
                variant="solid"
                isLoading={isSubmitting}
              >
                Pagar
              </Button>
            </ButtonGroup>
          </ModalFooter>
        </Form>
      </ModalContent>
    </Modal>
  );
};

export default ModalPagamento;
