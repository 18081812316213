import React from 'react';
import {
  Stack,
  ButtonGroup,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalFooter,
  ModalBody,
  FormLabel,
  Switch
} from '@chakra-ui/react';

import { usePostAluguelEfetuarDevolucao } from 'actions/alugueis';

import { useToastList } from 'customHooks';

const ModalEfetuarDevolucao = ({ isOpen, onClose, aluguelId }) => {
  const toastList = useToastList();
  const {mutateAsync: efetuarDevolucao, status } = usePostAluguelEfetuarDevolucao(
    aluguelId
  );
  const [enviarEmail, setEnviarEmail] = React.useState(false);

  const isSubmitting = status === 'loading';

  const onSubmit = async e => {
    try {
      e.preventDefault();

      await efetuarDevolucao({ enviar_email: enviarEmail });

      onClose();
    } catch (er) {
      toastList({ messages: er.errors, status: 'error' });
    }
  };

  return (
    <Modal
      closeOnOverlayClick={false}
      size="lg"
      isOpen={isOpen}
      onClose={onClose}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Devolução</ModalHeader>
        <ModalCloseButton />
        <Stack
          as="form"
          onSubmit={onSubmit}
          spacing={0}
          align="flex-end"
          noValidate
        >
          <ModalBody width="100%">
            <FormLabel htmlFor="enviar-email">
              Enviar Email para Anunciantes
            </FormLabel>
            <Switch
              id="enviar-email"
              isChecked={enviarEmail}
              onChange={e => setEnviarEmail(e.target.checked)}
            />
          </ModalBody>
          <ModalFooter>
            <ButtonGroup size="sm">
              <Button
                type="submit"
                loadingText="Efetuando Devolução"
                colorScheme="blue"
                variant="solid"
                isLoading={isSubmitting}
              >
                Efetuar Devolução
              </Button>
            </ButtonGroup>
          </ModalFooter>
        </Stack>
      </ModalContent>
    </Modal>
  );
};

export default ModalEfetuarDevolucao;
