import axios from 'axios';
import get from 'lodash/get';
import isArray from 'lodash/isArray';
import isEmpty from 'lodash/isEmpty';
import isString from 'lodash/isString';
import isNumber from 'lodash/isNumber';
import isPlainObject from 'lodash/isPlainObject';



const baseURL = '/api/';
const storedTokenKey = 'myclosett:token';
const instance = axios.create({ baseURL });

const getToken = () => JSON.parse(window.localStorage.getItem(storedTokenKey));

const setToken = token =>
  window.localStorage.setItem(storedTokenKey, JSON.stringify(token));

function flattenObject(o, l=0, prefix = '', result = {}, keepNull = true) {
  if (isString(o) || isNumber(o) 
  //|| isBoolean(o) || (keepNull && isNull(o))
  || (isArray(o) && isString(o[0])
  || l >= 3
  )) {
    if (isArray(o)) {
      result[prefix] = o.join(", ");
    } else {
      result[prefix] = o;
    }
    
    return result;
  }

  if (isArray(o) || isPlainObject(o)) {
    for (let i in o) {
      let pref = prefix;
      if (isArray(o)) {
        pref = pref + `.${i}`;
      } else {
        if (isEmpty(prefix)) {
          pref = i;
        } else {
          pref = prefix + '.' + i;
        }
      }
      flattenObject(o[i], l+1, pref, result, keepNull);
    }
    return result;
  }
  return result;
}

export const treatErrorResponse = error => {
  const responseData = get(error, 'response.data', null) || null;
  let errors = {};

  if (responseData) {
    errors = flattenObject(responseData, 0);
  }
  return {
    ...error,
    errors: isEmpty(errors) ? {'all':'Não é possível realizar a operação'} : errors
  };
};

const refreshToken = async () => {
  const storedToken = getToken();
  const { refresh } = storedToken;
  const {
    data: { access }
  } = await axios.post(`${baseURL}token/refresh`, { refresh });
  setToken({ access, refresh });
  return { access, refresh };
};

instance.interceptors.request.use(
  config => {
    const storedToken = getToken();
    config.headers.Authorization = `Bearer ${storedToken.access}`;
    return config;
  },
  error => Promise.reject(error)
);

instance.interceptors.response.use(
  response => response,
  async error => {
    if (error.response.status === 401) {
      try {
        const { access } = await refreshToken();
        error.config.headers.Authorization = `Bearer ${access}`;
        return instance.request(error.config);
      } catch (refreshTokenEr) {
        return Promise.reject(treatErrorResponse(refreshTokenEr));
      }
    } else {
      return Promise.reject(treatErrorResponse(error));
    }
  }
);

export default instance;
