import React from 'react';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import get from 'lodash/get';

const usePaginatedPrefetchQuery = (queryKey, queryFn) => {
  const queryClient = useQueryClient();
  const paginatedQueryProps = useQuery(queryKey, queryFn, {
    keepPreviousData: true,
  });

  const hasNext = React.useMemo(
    () => get(paginatedQueryProps.data, 'data.next'),
    [paginatedQueryProps.data]
  );

  React.useEffect(() => {
    if (hasNext) {
      const { page, ...rest } = queryKey[1];
      queryClient.prefetchQuery(
        [queryKey[0], { ...rest, page: parseInt(page) + 1 }],
        queryFn
      );
    }
  }, [hasNext, queryFn, queryKey, queryClient]);

  return { hasNext, ...paginatedQueryProps };
};

export default usePaginatedPrefetchQuery;
