import React from 'react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useQuery } from 'customHooks';

import { useGet, usePost, usePut, useDelete } from 'customHooks/api';
import { usePaginatedPrefetchQuery } from 'customHooks';

export const QueryKeys = {
  VENDEDORA: 'vendedora',
  VENDEDORAS: 'vendedoras',
  VENDEDORAS_ALUGUEIS: 'vendedoras/alugueis',
  VENDEDORAS_COMISSAO: 'vendedoras/comissao'
};

export const useGetVendedora = id => {
  const getVendedora = useGet('vendedoras/{id}');
  return useQuery({
    queryKey: [QueryKeys.VENDEDORA, { id }],
    queryFn:getVendedora,
    enabled: !!id
  });
};

export const useGetVendedoras = filtros => {
  const queryKey = React.useMemo(() => [QueryKeys.VENDEDORAS, filtros], [
    filtros
  ]);
  const getVendedoras = useGet('vendedoras');
  return usePaginatedPrefetchQuery(queryKey, getVendedoras);
};

export const useGetVendedoraAlugueis = ({ id, page = 1 }) => {
  const queryKey = React.useMemo(
    () => [QueryKeys.VENDEDORAS_ALUGUEIS, { id, page }],
    [id, page]
  );
  const getVendedoraAlugueis = useGet('vendedoras/{id}/alugueis');
  return usePaginatedPrefetchQuery(queryKey, getVendedoraAlugueis);
};

export const useGetVendedoraComissao = ({ id, mes, ano }) => {
  const queryKey = React.useMemo(
    () => [QueryKeys.VENDEDORAS_COMISSAO, { id, mes, ano }],
    [id, mes, ano]
  );
  const getVendedoraComissao = useGet('vendedoras/{id}/comissao');
  return useQuery(queryKey, getVendedoraComissao);
};

export const usePostVendedora = queryConfig => {
  const postVendedora = usePost('vendedoras');
  return useMutation(postVendedora, queryConfig);
};

export const usePostVendedoraSenha = () => {
  const postVendedoraSenha = usePost('vendedoras/{id}/senha');
  return useMutation(postVendedoraSenha);
};

export const usePutVendedora = id => {
  const queryClient = useQueryClient()
  
  const putVendedora = usePut(`vendedoras/${id}`);
  return useMutation(putVendedora, {
    onSuccess: data =>
    queryClient.setQueryData([QueryKeys.VENDEDORA, { id: `${id}` }], data)
  });
};

export const useDeleteVendedora = (id, queryConfig) => {
  const deleteVendedora = useDelete(`vendedoras/${id}`);
  return useMutation(deleteVendedora, queryConfig);
};
