import React from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Stack } from '@chakra-ui/react';

import { useGetItensLavanderias } from 'actions/itens';
import {
  DisplayText,
  DisplayErrors,
  Paginator,
  Table,
  TableHead,
  TableBodyZebra,
  TableRow,
  TableHeader,
  TableData,
  StatusLavanderia
} from 'components/ui';
import { formatCurrency, formatDate } from 'utils/ui';

const TableRowLavanderia = ({ value }) => {
  const { id, data_inicio, data_fim, valor, status } = value;
  const history = useNavigate();

  return (
    <TableRow key={id} onClick={() => history(`/lavanderia/${id}`)}>
      <TableData>{id}</TableData>
      <TableData>{data_inicio ? formatDate(data_inicio) : ''}</TableData>
      <TableData>{data_fim ? formatDate(data_fim) : ''}</TableData>
      <TableData>{valor ? formatCurrency(valor) : '—'}</TableData>
      <TableData>
        <StatusLavanderia value={status} />
      </TableData>
    </TableRow>
  );
};

const TabContentLavanderia = () => {
  const { id } = useParams();
  const [page, setPage] = React.useState(1);
  const {
    data: lavanderias,
    hasNext,
    status,
    error
  } = useGetItensLavanderias({ id, page });

  if (status === 'loading') return <DisplayText>Carregando...</DisplayText>;

  if (status === 'error') return <DisplayErrors errors={error.errors} />;

  return (
    <Stack>
      <Table>
        <TableHead>
          <TableRow>
            <TableHeader>#</TableHeader>
            <TableHeader>Data de Ida</TableHeader>
            <TableHeader>Data de Volta</TableHeader>
            <TableHeader>Valor</TableHeader>
            <TableHeader>Status</TableHeader>
          </TableRow>
        </TableHead>
        <TableBodyZebra>
          {lavanderias.data.results.length === 0 ? (
            <TableRow>
              <TableData colSpan="5">Nenhuma informação disponível</TableData>
            </TableRow>
          ) : (
            lavanderias.data.results.map(lavanderia => (
              <TableRowLavanderia key={lavanderia.id} value={lavanderia} />
            ))
          )}
        </TableBodyZebra>
      </Table>
      <Paginator page={page} onPaginate={setPage} hasNext={hasNext} />
    </Stack>
  );
};

export default TabContentLavanderia;
