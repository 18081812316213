import React from 'react';
import { useParams } from 'react-router-dom';
import {
  Alert,
  AlertIcon,
  Flex,
  Stack,
  Image,
  Text,
  Box,
  Divider,
  Grid
} from '@chakra-ui/react';
import styled from '@emotion/styled';

import { useGetItem } from 'actions/itens';
import {
  DisplayText,
  Control,
  ControlText,
  ControlLink,
  ControlNull
} from 'components/ui';
import { useDocumentTitle, usePermissao } from 'customHooks';
import { formatCurrency, formatDate } from 'utils/ui';

import AcoesButton from './AcoesButton';
import Tabs from './Tabs';

const Banner = styled(Flex)``;

Banner.defaultProps = {
  direction: 'row',
  align: 'start'
  // padding: 4
};

const Fieldset = ({
  label,
  children,
  mb,
  mr,
  rightComponent,
  rightComponentProps
}) => {
  const RightComponent = rightComponent;

  return (
    <Box width="100%" as="fieldset" mb={mb} mr={mr}>
      <Flex>
        {label && (
          <Text fontSize="2xl" marginBottom={0} fontWeight="bold">
            {label}
          </Text>
        )}
        {rightComponent && (
          <Box ml="auto">
            <RightComponent {...rightComponentProps} />
          </Box>
        )}
      </Flex>
      <Divider />
      <Stack spacing={4} mt={6}>
        {children}
      </Stack>
    </Box>
  );
};

const Item = () => {
  const { id } = useParams();
  const { data: item, status } = useGetItem(id);
  useDocumentTitle(status === 'success' ? item.data.referencia : 'Item');
  const {isLoading, value} = usePermissao('item.change_item');

  if (status === 'loading' || isLoading ) return <DisplayText>Carregando...</DisplayText>;

  const {
    ativo,
    is_alugado,
    is_lavanderia,
    url,
    referencia,
    anunciante,
    anunciante_nome,
    valor_aluguel,
    valor_caucao,
    data_entrada,
    tamanho,
    marca,
    descricao,
    size
  } = item.data;

  return (
    <Stack spacing={4} p={4}>
      {!ativo && (
        <Alert status="info">
          <AlertIcon />
          Esse item está desativado.
        </Alert>
      )}
      {is_alugado && (
        <Alert status="warning">
          <AlertIcon />
          Esse item está alugado.
        </Alert>
      )}
      {is_lavanderia && (
        <Alert status="warning">
          <AlertIcon />
          Esse item está na lavanderia.
        </Alert>
      )}
      <Grid templateColumns="60% 40%" gap={6}>
        <Box maxH="400px" display="flex">
          <Image
          fit="scale-down" htmlWidth={size.width} htmlHeight={size.height} src={url}
          ignoreFallback
          />
        </Box>
        <Fieldset
          label={referencia}
          labelFontWeight="bold"
          rightComponent={value?AcoesButton:null}
          rightComponentProps={{ data: item.data }}
        >
          <Control label="Anunciante">
            <ControlLink to={`/anunciantes/${anunciante}`}>
              {anunciante_nome}
            </ControlLink>
          </Control>
          <Control label="Aluguel">
            {valor_aluguel ? (
              <ControlText>{formatCurrency(valor_aluguel)}</ControlText>
            ) : (
              <ControlNull />
            )}
          </Control>
          <Control label="Caução">
            {valor_caucao ? (
              <ControlText>{formatCurrency(valor_caucao)}</ControlText>
            ) : (
              <ControlNull />
            )}
          </Control>
          <Control label="Entrada">
            {data_entrada ? (
              <ControlText>{formatDate(data_entrada)}</ControlText>
            ) : (
              <ControlNull />
            )}
          </Control>
          <Control label="Tamanho">
            <ControlText>{tamanho}</ControlText>
          </Control>
          <Control label="Marca">
            <ControlText>{marca}</ControlText>
          </Control>
          <Control label="Descrição">
            <ControlText>{descricao}</ControlText>
          </Control>
        </Fieldset>
      </Grid>
      <Tabs />
    </Stack>
  );
};

export default Item;
