import { useQuery } from 'customHooks';

import { useGet } from 'customHooks/api';

export const QueryKeys = {
  PERMISSOES: 'permissoes'
};

export const useGetPermissoes = () => {
  const getPermissoes = useGet('permissoes');
  return useQuery([QueryKeys.PERMISSOES], getPermissoes, { staleTime: Infinity });
};
