import React from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useFormContext, useFieldArray } from 'react-hook-form';
import {
  Button,
  ButtonGroup,
  Stack,
  SimpleGrid,
  Grid,
  Text,
  Heading,
  FormControl as ChakraFormControl
} from '@chakra-ui/react';
import get from 'lodash/get';
import find from 'lodash/find';
import isEmpty from 'lodash/isEmpty';

import {
  useGetAluguelLavanderias,
  usePostAluguelLavanderias
} from 'actions/alugueis';
import { useGetOptionsEmpresas } from 'actions/options';
import { useGetStatusLavanderia } from 'actions/enums';
import { DisplayText } from 'components/ui';
import {
  Form,
  FormTableCell,
  FormInputGroup,
  FormSelect,
  FormInputCurrency,
  FormInputDate,
  FormInput
} from 'components/form';
import { useDocumentTitle } from 'customHooks';
import {
  prepareInputDateValue,
  prepareInputCurrencyValue,
  prepareOutputDateValue,
  prepareOutputCurrencyValue
} from 'utils/form';
import { formatDate } from 'utils/ui';

const FormContent = ({ aluguel, isSubmitting }) => {
  const name = 'lavanderias';
  const { control, formState: { errors } } = useFormContext();
  const { fields } = useFieldArray({ control, name });
  const { data: empresas, status: statusEmpresas } = useGetOptionsEmpresas();
  const { data: tipos, status: statusTipos } = useGetStatusLavanderia();

  const optionsEmpresas = empresas
    ? empresas.data.map(({ id, nome }) => ({
      value: id,
      label: nome
    }))
    : [];
  const optionsTipos = tipos ? tipos.data : [];

  const isLoadingEmpresas = statusEmpresas === 'loading';
  const isLoadingTipos = statusTipos === 'loading';

  return (
    <Stack align="start">
      <SimpleGrid w="100%" columns={6} gap={4}>
        <FormTableCell>Vestido</FormTableCell>
        <FormTableCell>Empresa</FormTableCell>
        <FormTableCell>Data de Ida</FormTableCell>
        <FormTableCell>Data de Volta</FormTableCell>
        <FormTableCell>Valor</FormTableCell>
        <FormTableCell>Status</FormTableCell>
      </SimpleGrid>
      {fields.map((field, index) => {
        const lavanderia = get(aluguel, `${name}.${index}`);
        return (
          <SimpleGrid columns={6} gap={4} key={field.id}>
            <FormInput name={`${name}.${index}.item_referencia`} isDisabled />
            <FormSelect
              name={`${name}.${index}.empresa`}
              isLoading={isLoadingEmpresas}
              options={optionsEmpresas}
              selectFirst={false}
              initialValue={find(optionsEmpresas, [
                'value',
                lavanderia.empresa
              ])}
            />
            <ChakraFormControl isInvalid={!isEmpty(get(errors, [`${name}`,`${index}`,`data_inicio`]))}>
            <FormInputDate
              name={`${name}.${index}.data_inicio`}
              width="100%"
              defaultValue={field.data_inicio}
            /></ChakraFormControl>
            <ChakraFormControl isInvalid={!isEmpty(get(errors, [`${name}`,`${index}`,`data_fim`]))}>
            <FormInputDate
              name={`${name}.${index}.data_fim`}
              width="100%"
              defaultValue={field.data_fim}
            /></ChakraFormControl>
            <ChakraFormControl isInvalid={!isEmpty(get(errors, [`${name}`,`${index}`,`valor`]))}>
              <FormInputGroup
                name={`${name}.${index}.valor`}
                inputComponent={FormInputCurrency}
                leftAddon="R$"
                placeholder="Valor"
                width="100%"
                defaultValue={field.valor}
              /></ChakraFormControl>
            <ChakraFormControl isInvalid={!isEmpty(get(errors, [`${name}`,`${index}`,`status`]))}>
            <FormSelect
              name={`${name}.${index}.status`}
              isLoading={isLoadingTipos}
              options={optionsTipos}
              selectFirst={false}
              initialValue={find(optionsTipos, ['value', lavanderia.status])}
            /></ChakraFormControl>
          </SimpleGrid>
        );
      })}
      <ButtonGroup mt={4}>
        <Button
          size="sm"
          colorScheme="blue"
          type="submit"
          loadingText="Salvando"
          isLoading={isSubmitting}
        >
          Salvar
        </Button>
      </ButtonGroup>
    </Stack>
  );
};

const FormularioLavanderia = () => {
  const { id: idUrl } = useParams();
  const history = useNavigate();
  const { data: aluguel, status: statusAluguel } = useGetAluguelLavanderias(
    idUrl
  );
  const { mutateAsync: editar, status: statusEditar } = usePostAluguelLavanderias(idUrl);


  useDocumentTitle(`Aluguel ${idUrl}: Lavanderias`)

  if (statusAluguel === 'loading')
    return <DisplayText>Carregando...</DisplayText>;

  const {
    id,
    cliente,
    cliente_nome,
    data_retirada,
    data_devolucao,
    lavanderias
  } = aluguel.data;

  const onSubmit = async data => {
    const outputData = {
      id,
      cliente,
      data_retirada,
      data_devolucao
    };
    outputData.lavanderias = data.lavanderias.map((lavanderia, index) => ({
      id: lavanderias[index].id,
      item: lavanderias[index].item,
      item_referencia: lavanderia.item_referencia,
      empresa: lavanderia.empresa ? lavanderia.empresa.value : null,
      data_inicio: prepareOutputDateValue(lavanderia.data_inicio),
      data_fim: prepareOutputDateValue(lavanderia.data_fim),
      valor: prepareOutputCurrencyValue(lavanderia.valor),
      status: lavanderia.status ? lavanderia.status.value : null
    }));
    await editar(outputData);
    history(`/aluguel/${id}`);
  };

  const inputData = {
    lavanderias:
      lavanderias &&
      lavanderias.map(lavanderia => ({
        item_referencia: lavanderia.item_referencia,
        data_inicio: prepareInputDateValue(lavanderia.data_inicio),
        data_fim: prepareInputDateValue(lavanderia.data_fim),
        valor: prepareInputCurrencyValue(lavanderia.valor)
      }))
  };

  return (
    <Stack spacing={4} p={4}>
      <Heading size="lg">Editar Lavanderias</Heading>
      <Grid templateColumns="100px 1fr" columnGap={3} w="fit-content" fontSize="sm">
        <Text justifySelf="end">Aluguel</Text>
        <Text>{id}</Text>
        <Text justifySelf="end">Cliente</Text>
        <Text>{cliente_nome}</Text>
        <Text justifySelf="end">Retirada</Text>
        <Text>{formatDate(data_retirada)}</Text>
        <Text justifySelf="end">Devolução</Text>
        <Text>{formatDate(data_devolucao)}</Text>
      </Grid>
      <Form onSubmit={onSubmit} defaultValues={inputData}>
        <FormContent
          aluguel={aluguel.data}
          isSubmitting={statusEditar === 'loading'}
        />
      </Form>
    </Stack>
  );
};

export default FormularioLavanderia;
