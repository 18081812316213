import React from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Flex,
  Stack,
  Heading,
  Tabs,
  Tab,
  TabList,
  TabPanel,
  TabPanels
} from '@chakra-ui/react';
import { setMonth, setYear, getMonth, getYear } from 'date-fns';
import { stringify } from 'qs';

import { useSearchParams } from 'customHooks';

import DatePaginator from './DatePaginator';
import TabContentReal from './TabContentReal';
import TabContentPrevisto from './TabContentPrevisto';
import TabContentVendedora from './TabContentVendedora';
import TabContentPendente from './TabContentPendente';

const formatDate = (date) => {
  const year = getYear(date);
  const month = getMonth(date) + 1;
  const monthName = {
    1: 'Janeiro',
    2: 'Fevereiro',
    3: 'Março',
    4: 'Abril',
    5: 'Maio',
    6: 'Junho',
    7: 'Julho',
    8: 'Agosto',
    9: 'Setembro',
    10: 'Outubro',
    11: 'Novembro',
    12: 'Dezembro'
  }[month];
  return `${monthName} ${year}`;
  }


const Caixa = () => {
  const now = new Date();
  const history = useNavigate();
  const searchParams = useSearchParams({
    mes: getMonth(now) + 1,
    ano: getYear(now),
    tabIndex: 0
  });
  const [filtros, setFiltros] = React.useState(searchParams);

  const { mes, ano, tabIndex } = filtros;
  const intTabIndex = parseInt(tabIndex || 0);
  const isTabPendente = intTabIndex === 3;
  const dataReferencia = setYear(setMonth(new Date(), mes-1), ano)
  const handleTabsChange = tabIndex =>
    setFiltros(prev => ({ ...prev, tabIndex, vendedora:undefined }));

  React.useEffect(() => {
    history(`?${stringify(filtros)}`, {replace:true});
  }, [filtros, history]);

  return (
    <Stack p={4} spacing={4}>
      <Flex>
        <Heading size="lg">
          {isTabPendente ? '-' : formatDate(dataReferencia)}
        </Heading>
        <DatePaginator
          isDisabled={isTabPendente}
          mes={mes}
          ano={ano}
          onPaginate={date =>
            setFiltros(prev => ({ ...prev, mes: date.mes, ano: date.ano }))
          }
          ml="auto"
        />
      </Flex>

      <Tabs
        variant="enclosed"
        size="sm"
        defaultIndex={intTabIndex}
        value={intTabIndex}
        onChange={handleTabsChange}
      >
        <TabList>
          <Tab>Real</Tab>
          <Tab>Previsto</Tab>
          <Tab>Vendedora</Tab>
          <Tab>Pendente</Tab>
        </TabList>
        <TabPanels pt={6} outline="none" >
          <TabPanel outline="none" p={0}>
            {intTabIndex === 0 && <TabContentReal filtros={{mes, ano}} />}
          </TabPanel>
          <TabPanel outline="none" p={0}>
          {intTabIndex === 1 && <TabContentPrevisto filtros={{mes, ano}} />}
          </TabPanel>
          <TabPanel outline="none" p={0}>
            {intTabIndex === 2 && <TabContentVendedora filtros={{mes, ano}} />}
          </TabPanel>
          <TabPanel outline="none" p={0}>
            {intTabIndex === 3 && <TabContentPendente filtros={{mes, ano}} />}
          </TabPanel>
        </TabPanels>
      </Tabs>
    </Stack>
  );
};

export default Caixa;
