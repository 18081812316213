import React from 'react';
import {
  Link as RouterLink,
  useNavigate
} from 'react-router-dom';
import {
  Grid,
  Stack,
  Text,
  Flex,
  Image,
  Select,
  Input,
  IconButton
} from '@chakra-ui/react';
import get from 'lodash/get';
import { FaSearch, FaPlus } from 'react-icons/fa';
import { stringify } from 'qs';

import { useGetAnunciantes } from 'actions/anunciantes';
import { DisplayText, Paginator } from 'components/ui';
import { useSearchParams, useDocumentTitle } from 'customHooks';

const initialFiltros = {
  ativo: '',
  page: 1
};

const Card = ({ value }) => {
  return (
    <Flex
      as={RouterLink}
      to={`/anunciantes/${value.id}`}
      min-width="200px"
      border="1px"
      borderColor="#DDD"
      borderRadius="md"
      boxShadow="sm"
      align="center"
      p={1}
    >
      <Image
        src={value.foto_url}
        alt={value.nome}
        boxSize="100px"
        fallbackSrc="/static/img/pessoa/default.jpg"
      />
      <Stack spacing={1} px={4}>
        <Text color="blue.500" fontWeight="semibold" fontSize="sm">
          {value.nome}
        </Text>
        <Text
          color="gray.400"
          fontSize="sm"
        >{`Anunciante nº${value.numero}`}</Text>
      </Stack>
    </Flex>
  );
};

const GridAnunciantes = ({ filtros, onPaginate }) => {
  const { page } = filtros;
  const {
    status,
    data: anunciantes,
    error,
    hasNext
  } = useGetAnunciantes(filtros);

  if (status === 'loading') return <DisplayText>Carregando...</DisplayText>;

  if (status === 'error')
    return <DisplayText>{get(error, 'response.data.detail')}</DisplayText>;

  const { results } = anunciantes.data;

  if (results.length === 0)
    return <DisplayText>Nenhuma informação disponível</DisplayText>;

  return (
    <Stack spacing={4}>
      <Grid
        templateColumns={[
          'repeat(1, 1fr)',
          'repeat(2, 1fr)',
          'repeat(3, 1fr)',
          'repeat(3, 1fr)'
        ]}
        gap={4}
      >
        {results.map(anunciante => (
          <Card key={anunciante.id} value={anunciante} />
        ))}
      </Grid>
      <Paginator page={page} onPaginate={onPaginate} hasNext={hasNext} />
    </Stack>
  );
};

const Lista = () => {
  const searchParams = useSearchParams(initialFiltros);
  const [filtros, setFiltrosState] = React.useState(searchParams);
  const [termoPesquisa, setTermoPesquisa] = React.useState('');
  const history = useNavigate();

  useDocumentTitle('Anunciantes')

  const setFiltros = React.useCallback(
    newFiltros => {
      const filtros = { ...searchParams, ...newFiltros };
      history(`?${stringify(filtros)}`, { replace: true });
    },
    [setFiltrosState, searchParams]
  );

  React.useEffect(() => {
    setTermoPesquisa(filtros.search || '');
  }, [filtros]);

  React.useEffect(() => {
    setFiltrosState(searchParams);
  }, [searchParams]);

  return (
    <Stack spacing={4} p={4}>
      <Flex>
        <Select
          data-testid="filtro-ativo"
          size="sm"
          width="150px"
          value={filtros.ativo}
          onChange={e => {
            //setFiltros(prev => ({ ...prev, ativo: e.target.value }))}
            setFiltros({ativo: e.target.value, page:1 })
          }
          }
        >
          <option value="all">Todas</option>
          <option value="">Ativas</option>
          <option value="false">Inativas</option>
        </Select>
        <Flex
          as="form"
          ml="auto"
          noValidate
          onSubmit={e => {
            e.preventDefault();
            setFiltros({search: termoPesquisa, page:1 });
          }}
        >
          <Input
            data-testid="filtro-search"
            size="sm"
            placeholder="Pesquisar"
            roundedRight={0}
            value={termoPesquisa}
            onChange={e => setTermoPesquisa(e.target.value)}
          />
          <IconButton type="submit" size="sm" rounded={0} icon={<FaSearch/>} />
          <IconButton
            as={RouterLink}
            to={`form`}
            size="sm"
            roundedLeft={0}
            icon={<FaPlus/>}
          />
        </Flex>
      </Flex>
      <GridAnunciantes
        filtros={filtros}
        onPaginate={page => setFiltros({page })}
      />
    </Stack>
  );
};

export default Lista;
