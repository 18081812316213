import React, { Fragment } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Text,
  Flex,
  Stack,
  Heading,
  IconButton,
  Menu,
  MenuList,
  MenuButton,
  Box
} from '@chakra-ui/react';
import {
  FaChevronDown,
  FaDownload,
  FaArrowDown,
  FaShoppingCart,
  FaArrowUp,
  FaRetweet
} from 'react-icons/fa';
import { formatCurrency, formatDate } from 'utils/ui';

import { useGetCaixaReal, useDownloadCaixaRealCSV} from 'actions/caixa';
import {
  DisplayText,
  IconMenuItem,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableHeader,
  TableData,
  CurrencyText
} from 'components/ui';
import { useDocumentTitle } from 'customHooks';

const TableFluxoTotal = ({ value, filtros, ...rest }) => {

  const [fetch] = useDownloadCaixaRealCSV(filtros);
  fetch
  // onClick={()=>fetch()}
  
  return (
    <Stack {...rest}>
      <Flex>
        <Heading size="md">Fluxo Total</Heading>
        <Menu placement="bottom-end">
          <MenuButton
            as={IconButton}
            size="sm"
            icon={<FaChevronDown/>}
            ml="auto"
          />
          <MenuList minWidth="150px" >
            <IconMenuItem icon={FaDownload} onClick={() => {}}>Baixar CSV</IconMenuItem>
          </MenuList>
        </Menu>
      </Flex>
      <Table>
        <TableHead>
          <TableRow>
            <TableHeader colSpan={3}>Fluxo</TableHeader>
            <TableHeader>Valor</TableHeader>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableData colSpan={3}>Clientes</TableData>
            <TableData color="green.500">
              <CurrencyText value={value.clientes}/>
            </TableData>
          </TableRow>
          <TableRow>
            <TableData colSpan={3}>Anunciantes</TableData>
            <TableData color="red.500">{formatCurrency(value.anunciantes)}</TableData>
          </TableRow>
          <TableRow>
            <TableData colSpan={3}>Lavanderia - Outras</TableData>
            <TableData color="red.500">{formatCurrency(value.lavanderias)}</TableData>
          </TableRow>
          <TableRow fontWeight="bold">
            <TableData colSpan={3}>Valor Líquido</TableData>
            <TableData>
              <CurrencyText value={value.valor_liquido}/>
            </TableData>
          </TableRow>
        </TableBody>
      </Table>
    </Stack>
  );
};

const TableEntradasClientes = ({ value, ...rest }) => {
  const history = useNavigate();
  return (
    <Stack {...rest}>
      <Heading size="md">
        Entradas{' '}
        <Text as="small" color="gray.400">
          (Clientes)
        </Text>
      </Heading>
      <Table>
        <TableHead>
          <TableRow>
            <TableHeader>#</TableHeader>
            <TableHeader>Data do Pagamento</TableHeader>
            <TableHeader>Cliente</TableHeader>
            <TableHeader>
              Valor <Box as={FaArrowDown} display="inline-block" />
            </TableHeader>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow fontWeight="bold">
            <TableData>
              <Box as={FaShoppingCart} display="inline-block" />
            </TableData>
            <TableData>{value.total.mes}</TableData>
            <TableData>Total</TableData>
            <TableData color="green.500">
              {formatCurrency(value.total.valor)}
            </TableData>
          </TableRow>
          {value.itens.map((item, index) => (
            <TableRow
              key={index}
              onClick={() => history(`/aluguel/${item.id}`)}
            >
              <TableData>{item.id}</TableData>
              <TableData>{formatDate(item.data)}</TableData>
              <TableData>{item.nome}</TableData>
              <TableData color="green.500">
                {formatCurrency(item.valor)}
              </TableData>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </Stack>
  );
};

const TableSaidasAnunciantes = ({ value, ...rest }) => {
  const history = useNavigate();
  return (
    <Stack {...rest}>
      <Heading size="md">
        Saídas{' '}
        <Text as="small" color="gray.400">
          (Anunciantes)
        </Text>
      </Heading>
      <Table>
        <TableHead>
          <TableRow>
            <TableHeader>#</TableHeader>
            <TableHeader>Data</TableHeader>
            <TableHeader>Anunciante</TableHeader>
            <TableHeader>
              Valor <Box as={FaArrowUp} display="inline-block" />
            </TableHeader>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow fontWeight="bold">
            <TableData>
              <Box as={FaShoppingCart} display="inline-block" />
            </TableData>
            <TableData>{value.total.mes}</TableData>
            <TableData>Total</TableData>
            <TableData color="red.500">
              {formatCurrency(value.total.valor)}
            </TableData>
          </TableRow>
          {value.itens.map((item, index) => (
            <TableRow
              key={index}
              onClick={() => history(`/aluguel/${item.id}`)}
            >
              <TableData>{item.id}</TableData>
              <TableData>{formatDate(item.data)}</TableData>
              <TableData>{item.nome}</TableData>
              <TableData color="red.500">
                {formatCurrency(item.valor)}
              </TableData>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </Stack>
  );
};

const TableSaidasLavanderias = ({ value, ...rest }) => {
  const history = useNavigate();
  return (
    <Stack {...rest}>
      <Heading size="md">
        Saídas{' '}
        <Text as="small" color="gray.400">
          (Lavanderias)
        </Text>
      </Heading>
      <Table>
        <TableHead>
          <TableRow>
            <TableHeader>#</TableHeader>
            <TableHeader>Data</TableHeader>
            <TableHeader>Empresa</TableHeader>
            <TableHeader>
              Valor <Box as={FaArrowUp} display="inline-block" />
            </TableHeader>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow fontWeight="bold">
            <TableData>
              <Box as={FaShoppingCart} display="inline-block" />
            </TableData>
            <TableData>{value.total.mes}</TableData>
            <TableData>Total</TableData>
            <TableData color="red.500">
              {formatCurrency(value.total.valor)}
            </TableData>
          </TableRow>
          {value.itens.map((empresa, index) => (
            <Fragment key={index}>
            {empresa.itens.map((item, indexemp) => (
              <TableRow
                key={indexemp}
                onClick={() => history(`/lavanderia/${item.id}`)}
              >
                <TableData>{item.id}</TableData>
                <TableData>{formatDate(item.data)}</TableData>
                <TableData>{item.nome}</TableData>
                <TableData color="red.500">
                  {formatCurrency(item.valor)}
                </TableData>
              </TableRow>
            ))}
              <TableRow fontWeight="bold">
              <TableData>
                <Box as={FaShoppingCart} display="inline-block" />
              </TableData>
              <TableData>{empresa.total.mes}</TableData>
              <TableData>{empresa.total.nome}</TableData>
              <TableData color="red.500">
                {formatCurrency(empresa.total.valor)}
                </TableData>
              </TableRow>
            </Fragment>
          ))}
        </TableBody>
      </Table>
    </Stack>
  );
};

const TableEconomiaLavanderias = ({ value, ...rest }) => {
  const history = useNavigate();
  return (
    <Stack {...rest}>
      <Heading size="md">
        Economia{' '}
        <Text as="small" color="gray.400">
          (Lavanderias)
        </Text>
      </Heading>
      <Table>
        <TableHead>
          <TableRow>
            <TableHeader>#</TableHeader>
            <TableHeader>Data</TableHeader>
            <TableHeader>Empresa</TableHeader>
            <TableHeader>
              Valor <Box as={FaRetweet} display="inline-block" />
            </TableHeader>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow fontWeight="bold">
            <TableData>
              <Box as={FaShoppingCart} display="inline-block" />
            </TableData>
            <TableData>{value.total.mes}</TableData>
            <TableData>Total</TableData>
            <TableData>{formatCurrency(value.total.valor)}</TableData>
          </TableRow>
          {value.itens.map((item, index) => (
            <TableRow
              key={index}
              onClick={() => history(`/lavanderia/${item.id}`)}
            >
              <TableData>{item.id}</TableData>
              <TableData>{formatDate(item.data)}</TableData>
              <TableData>{item.nome}</TableData>
              <TableData>{formatCurrency(item.valor)}</TableData>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </Stack>
  );
};

const TabContentReal = ({ filtros }) => {
  const { data, status } = useGetCaixaReal(filtros);
  useDocumentTitle('Fluxo de Caixa (Real)');

  if (status === 'loading') return <DisplayText>Carregando...</DisplayText>;

  return (
    <Stack spacing={6}>
      <TableFluxoTotal value={data.data.total} filtros={filtros} />
      <TableEntradasClientes value={data.data.entradas_clientes} />
      <TableSaidasAnunciantes value={data.data.saidas_anunciantes} />
      <TableSaidasLavanderias value={data.data.saidas_lavanderias} />
      <TableEconomiaLavanderias value={data.data.economia_lavanderias} />
    </Stack>
  );
};

export default TabContentReal;
