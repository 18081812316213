import React from 'react';
import { Stack } from '@chakra-ui/react';
import isArray from 'lodash/isArray';

import DisplayText from './DisplayText';

const DisplayErrors = ({ errors }) => {
  const errorArray = isArray(errors) ? errors : Object.values(errors);
  return (
    <Stack>
      {errorArray.map(error => (
        <DisplayText key={error}>{error}</DisplayText>
      ))}
    </Stack>
  );
};

export default DisplayErrors;
